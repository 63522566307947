import { HttpClient } from "@angular/common/http";
import { Component, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Config } from "src/app/config/config";
import { ApiResponse } from "src/app/typings/response";

@Injectable({
  providedIn: "root",
})
export class AuditManagerService {
  baseUrl: string;

  constructor(private http: HttpClient, private dialog: MatDialog) {
    this.baseUrl = Config.api.getBaseUrlV2();
  }

  // Get all audit specifications
  getAllAuditSpecifications() {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(`${this.baseUrl}/audit-specifications`)
        .subscribe(
          (data) => {
            resolve(data.data);
          },
          (err) => reject(err)
        );
    });
  }

  // Get an audit specification
  getAuditSpecification(auditSpecId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(`${this.baseUrl}/audit-specifications/${auditSpecId}`)
        .subscribe(
          (data) => {
            resolve(data.data);
          },
          (err) => reject(err)
        );
    });
  }

  // Update an audit specification
  updateAuditSpecification(auditSpecId: string, changes: any) {
    return new Promise((resolve, reject) => {
      this.http
        .patch<ApiResponse>(
          `${this.baseUrl}/audit-specifications/${auditSpecId}`,
          changes
        )
        .subscribe(
          (data) => {
            resolve(data.data);
          },
          (err) => reject(err)
        );
    });
  }

  // Create an audit specification
  createAuditSpecification(customerId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(`${this.baseUrl}/audit-specifications`, {
          customer: customerId,
          products: [],
        })
        .subscribe(
          (data) => {
            resolve(data.data);
          },
          (err) => reject(err)
        );
    });
  }

  // Update product on audit specification
  updateProduct(auditSpecId: string, productId: string, ragRiskRating: any) {
    return new Promise((resolve, reject) => {
      this.http
        .patch<ApiResponse>(
          `${this.baseUrl}/audit-specifications/${auditSpecId}/products/${productId}`,
          { ragRiskRating }
        )
        .subscribe(
          (data) => {
            resolve(data.data);
          },
          (err) => reject(err)
        );
    });
  }

  // Add a product to the audit spec
  addProduct(auditSpecId: string, productId: string, ragRiskRating: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(
          `${this.baseUrl}/audit-specifications/${auditSpecId}/products`,
          {
            productId: productId,
            ragRiskRating,
          }
        )
        .subscribe(
          (data) => {
            resolve(data.data);
          },
          (err) => reject(err)
        );
    });
  }

  // remove product from audit spec
  removeProduct(auditSpecId: string, productId: string) {
    return new Promise((resolve, reject) => {
      console.log("test");
      this.http
        .delete<ApiResponse>(
          `${this.baseUrl}/audit-specifications/${auditSpecId}/products/${productId}`
        )
        .subscribe(
          (data) => {
            resolve(data.data);
          },
          (err) => reject(err)
        );
    });
  }

  // REmove audit spec
  removeAuditSpecification(auditSpecId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .delete<ApiResponse>(
          `${this.baseUrl}/audit-specifications/${auditSpecId}`
        )
        .subscribe(
          (data) => {
            resolve(data.data);
          },
          (err) => reject(err)
        );
    });
  }

  // Update Max Area for non compliant cords
  updateMaxCordsArea(auditSpecId: string, nonCompCordsMaxArea: string) {
    return new Promise((resolve, reject) => {
      this.http
        .patch<ApiResponse>(
          `${this.baseUrl}/audit-specifications/${auditSpecId}`,
          {
            nonCompCordsMaxArea,
          }
        )
        .subscribe(
          (data) => resolve(data.data),
          (err) => reject(err)
        );
    });
  }

  // Update Max Area for non compliant battens
  updateMaxBattenArea(auditSpecId: string, nonCompBattenMaxArea: string) {
    return new Promise((resolve, reject) => {
      this.http
        .patch<ApiResponse>(
          `${this.baseUrl}/audit-specifications/${auditSpecId}`,
          {
            nonCompBattenMaxArea,
          }
        )
        .subscribe(
          (data) => resolve(data.data),
          (err) => reject(err)
        );
    });
  }
}
