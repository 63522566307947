import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sys-error-dialog',
  templateUrl: './sys-error-dialog.component.html',
  styleUrls: ['./sys-error-dialog.component.scss']
})
export class SysErrorDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  refresh() {
    location.reload();
  }

}
