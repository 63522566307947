<div class="relative p-10 h-auto overflow-y-scroll">
    <!-- Close Button -->
    <!-- <button type="button" class="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-900" aria-label="Close"
        (click)="dialogRef.close()">
        <svg class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
            <path d="M6 18L18 6M6 6l12 12" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
    </button> -->

    <div class="flex justify-between">
        <h5 class="text-lg font-semibold mb-2 text-gray-900">Manage Customer Contacts</h5>
        <!-- Close Button -->
        <button type="button" class="action-button text-gray-800 mr-2" aria-label="Close" (click)="dialogRef.close()">
            <ng-icon name="heroXMark" size="18"></ng-icon>
        </button>
    </div>
    <!-- <h5 class="text-lg font-semibold mb-2 text-gray-900">Manage Customer Contacts</h5> -->
    <p class="text-sm text-gray-500 mb-2">To add contacts to this customer, please use the contact management pages.</p>

    <ul role="list" class="divide-y divide-gray-100">
        <li class="flex items-center justify-between gap-x-6 py-3" *ngFor="let contact of contacts">
            <div class="min-w-0">
                <div class="flex items-start gap-x-3">
                    <p class="text-sm font-semibold leading-6 text-gray-900">{{ contact.name_first }}
                        {{contact.name_last}}</p>
                </div>
                <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                    <p class="whitespace-nowrap">{{contact.email}}</p>
                </div>
            </div>
            <div class="flex flex-none items-center gap-x-4">
                <button [matMenuTriggerFor]="menu"
                    class="action-button hover:bg-gray-200 rounded-full p-2 h-8 w-8 flex items-center justify-center">
                    <span class="sr-only">Open options</span>
                    <ng-icon name="heroEllipsisVertical" size="18" class="text-gray-500"></ng-icon>
                </button>

                <mat-menu #menu="matMenu" @menuAnimation class="inline-flex flex-col min-w-40 bg-white rounded-md">
                    <button cdk-menu-item (click)="makePrimary(contact._id)"
                        class="action-button px-3 py-3 gap-2 text-sm leading-6 text-gray-900 hover:bg-gray-100 flex flex-row items-center justify-center">
                        <ng-icon name="heroUserCircle" size="18"></ng-icon>
                        <span>Make primary</span>
                    </button>

                    <button cdk-menu-item (click)="removeContact(contact._id)"
                        class="action-button px-3 py-3 gap-2 text-sm leading-6 text-gray-900 hover:bg-gray-100 flex flex-row items-center justify-center">
                        <ng-icon name="heroTrash" size="18"></ng-icon>
                        <span>Remove Contact</span>
                    </button>
                </mat-menu>
            </div>
        </li>
    </ul>
</div>