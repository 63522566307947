<div class="pl-4 sm:pl-6 lg:pl-8">
  <h2 class="py-3  text-4xl font-bold">Welcome to
    <span class="animate-text bg-gradient-to-r from-cg-primary to-blue-900 bg-clip-text text-transparent font-black">Safer Track
    </span>
  </h2>
</div>

<div class="py-3 sm:py-2 pl-4 sm:pl-6 lg:pl-8">
  <div class="mx-auto max-w-full">
    <div class="mx-auto max-w-full lg:max-w-none">
      <dl class="mt-3 grid grid-cols-1 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
        <app-metric-container [value]="customerCount" [metricTitle]="'Customers'"></app-metric-container>
        <app-metric-container [value]="openInspectionsCount"
          [metricTitle]="'Pending Inspections'"></app-metric-container>
        <app-metric-container [value]="contractsCount" [metricTitle]="'Contracts'"></app-metric-container>
        <app-metric-container [value]="openInspectionsCount"
          [metricTitle]="'Pending Inspections'"></app-metric-container>
      </dl>
    </div>
  </div>
</div>

<div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 pl-4 sm:pl-6 lg:pl-8">
  <div>
    <app-graph-container [graphData]="contractsPlotData" class="w-full h-full"></app-graph-container>
  </div>
  <div>
    <app-graph-container [graphData]="totalAuditsPlotData" class="w-full h-full"></app-graph-container>
  </div>
  <div>
    <app-graph-container [graphData]="pendingInspectionsPlotData" class="w-full h-full"></app-graph-container>
  </div>
</div>
