import { ContractsService } from "./../../services/contracts/contracts.service";
import { CustomersService } from "./../../services/customers/customers.service";
import { InspectionService } from "./../../services/inspection/inspection.service";
import { PageParams } from "./../../typings/PageParams";
import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { DashboardService } from "src/app/services/dashboard/dashboard.service";
import { DataService } from "../../services/data/data.service";
import { IGraphData } from "./partials/graph-container/graph-container.component";
import moment from "moment";
import { DateTime } from "luxon";
import { Router } from "@angular/router";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  customerCount: Subject<number> = new Subject();
  contractsCount: Subject<number> = new Subject();
  openInspectionsCount: Subject<number> = new Subject();

  public contractsPlotData: Subject<IGraphData> = new Subject();
  public pendingInspectionsPlotData: Subject<IGraphData> = new Subject();
  public totalAuditsPlotData: Subject<IGraphData> = new Subject();
  public contractStatus: Subject<IGraphData> = new Subject();

  constructor(
    private inspectionService: InspectionService,
    private customersService: CustomersService,
    private contracts: ContractsService,
    private dataService: DataService,
    private router: Router
  ) {}

  ngOnInit() {
    this.customersService.getCustomerCount().then((data: any) => {
      console.log(data);
      this.customerCount.next(data.value);
    });

    this.contracts.getContractsCount().then((data: any) => {
      this.contractsCount.next(data.value);
    });

    this.inspectionService.openInspectionCount().then((data: any) => {
      this.openInspectionsCount.next(data.value);
    });

    this.dataService.getTotalContracts().then((data: any) => {
      this.contractsPlotData.next({
        title: "Contracts",
        type: "scatter",
        mode: "lines+points",
        series: [
          {
            x: data.map((item) => new Date(item.date)),
            y: data.map((item) => item.meanValue),
            name: "Total Audits",
          },
        ],
      });
    });

    this.dataService.getOpenAudits().then(async (data: any[]) => {
      console.log(data);

      this.pendingInspectionsPlotData.next({
        title: "Open Audits",
        type: "scatter",
        mode: "lines+points+name",
        series: [
          {
            x: data.map((item) => new Date(item.date)),
            y: data.map((item) => item.count),
            name: "Created Audits",
          },
          {
            x: data.map((item) => new Date(item.date)),
            y: data.map((item) => item.meanValue),
            name: "Open Audits",
          },
        ],
      });
    });

    this.dataService.getTotalAudits().then(async (data: any[]) => {
      this.totalAuditsPlotData.next({
        title: "Total Audits",
        type: "scatter",
        mode: "lines+points",
        series: [
          {
            x: data.map((item) => new Date(item.date)),
            y: data.map((item) => item.meanValue),
            name: "Total Contracts",
          },
        ],
      });
    });

    this.dataService.getContractStatus().then((data: any) => {
      const graph: IGraphData = {
        series: [
          {
            name: "any",
            y: [data.contractsValid, data.contractsExpired],
            x: ["Valid", "Expired"],
          },
        ],
        mode: "",
        title: "Contract Status Overview",
        type: "bar",
      };

      this.contractStatus.next(graph);
    });
  }

  generateContractReport() {
    this.contracts.generateContractReport().then((res) => {});
  }

  navigateTo(path: string) {
    this.router.navigate([path]);
  }
}
