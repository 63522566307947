{
  "name": "cgadmin",
  "version": "3.2.6",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build --output-hashing=all --configuration=production",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.2",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "^18.0.2",
    "@angular/compiler": "^18.0.2",
    "@angular/core": "^18.0.2",
    "@angular/forms": "^18.0.2",
    "@angular/material": "^17.3.10",
    "@angular/material-moment-adapter": "^17.3.10",
    "@angular/platform-browser": "^18.0.2",
    "@angular/platform-browser-dynamic": "^18.0.2",
    "@angular/router": "^18.0.2",
    "@auth0/angular-jwt": "^5.1.1",
    "@azure/msal-angular": "3.0.0-alpha.2",
    "@azure/msal-browser": "3.0.0-alpha.2",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@ng-icons/core": "^28.0.0",
    "@ng-icons/heroicons": "^28.0.0",
    "@swimlane/ngx-charts": "^20.5.0",
    "@types/file-saver": "^2.0.5",
    "animejs": "^3.2.1",
    "ansi-colors": "^4.1.1",
    "bcrypt": "^5.0.1",
    "chart.js": "^2.9.4",
    "file-saver": "^2.0.5",
    "jsonwebtoken": "^8.5.1",
    "luxon": "^2.3.2",
    "moment": "^2.29.1",
    "msal": "^1.4.16",
    "ngx-pagination": "^6.0.3",
    "ngx-skeleton-loader": "^6.0.0",
    "querystring": "^0.2.1",
    "rimraf": "^3.0.2",
    "rword": "^3.2.1",
    "rxjs": "^7.8.1",
    "tslib": "^2.3.1",
    "zone.js": "~0.14.7"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.3",
    "@angular/cli": "^18.0.3",
    "@angular/compiler-cli": "^18.0.2",
    "@angular/language-service": "^18.0.2",
    "@tailwindcss/forms": "^0.5.7",
    "@types/chart.js": "^2.9.35",
    "@types/jasmine": "^3.10.3",
    "@types/jasminewd2": "^2.0.10",
    "@types/luxon": "^2.3.1",
    "@types/node": "^12.20.47",
    "ajv": "^8.11.2",
    "autoprefixer": "^10.4.19",
    "codelyzer": "^6.0.2",
    "jasmine-core": "~5.0.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "^6.3.17",
    "karma-chrome-launcher": "^3.1.1",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^1.7.0",
    "postcss": "^8.4.38",
    "protractor": "~7.0.0",
    "tailwindcss": "^3.4.4",
    "ts-node": "~7.0.0",
    "tslint": "~6.1.0",
    "typescript": "5.4.5"
  }
}
