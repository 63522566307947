import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { IAuditSpec } from "src/app/models/auditSpec.model";
import { Product } from "src/app/models/product.model";
import { AuditManagerService } from "src/app/services/audit-manager/audit-manager.service";
import { NotificationsService } from "src/app/services/notifications/notifications.service";
import { ProductService } from "src/app/services/product/product.service";

interface ProductSpec {
  _id: string;
  product: Product;
  ragRiskRating: string;
}

@Component({
  selector: "app-audit-spec-editor",
  templateUrl: "./audit-spec-editor.component.html",
  styleUrls: ["./audit-spec-editor.component.scss"],
})
export class AuditSpecEditorComponent implements OnInit, OnDestroy {
  // The Object Id of the audit spec
  public auditSpecId: string = null;

  // The audit spec
  public auditSpec: IAuditSpec = null;

  // THe data table for the products
  public productDataSource = new MatTableDataSource([]);

  // The columns to be displayed in the table.
  public displayedColumns = [
    // "warning",
    "productName",
    "ragRiskRating",
    "cgdRagRiskRating",
    "actions",
  ];

  // List of products to add.
  public products: Product[] = [];
  public productsLoading = false;

  // Form controlls for add product.

  // Hold all observer subscriptions here and unsubscribe on destroy
  private subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auditService: AuditManagerService,
    private ns: NotificationsService,
    private productsService: ProductService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getAuditSpec();
    this.getProducts();


  }

  ngOnDestroy(): void {
    // Unsubscribe all subscriptions
    this.subscriptions.unsubscribe();
  }

  // displayFn(product: Product): string {
  //   return product.product;
  // }



  /**
   * Subscribe to the route paramater for the audit spec id
   * and get the audit spec from the service.
   */
  getAuditSpec() {
    const $d = this.route.params.subscribe((params) => {
      if (params.specId == null) {
        return this.router.navigate(["/"]);
      }

      // Set audit spec on class in case needed.
      this.auditSpecId = params.specId;

      // Pull audit spec from server
      this.auditService
        .getAuditSpecification(params.specId)
        .then((spec: IAuditSpec) => {
          console.log(spec);
          console.log(spec.customer)
          this.auditSpec = spec;
          this.productDataSource.data = spec.products;
        })
        .catch((err) => {
          this.ns.error(err.message, 4000);
        });
    });

    this.subscriptions.add($d);
  }

  /**
   * Get Products.
   */
  getProducts() {
    if (this.products.length > 0) {
      return;
    }

    this.productsLoading = true;

    this.productsService
      .getAllProducts({}, {}, {})
      .then((products: Product[]) => {
        this.products = products;
        this.productsLoading = false;
      })
      .catch((err) => {
        this.ns.error(err.message, 4000);
        this.productsLoading = false;
      });
  }

  calcRiskDifferential(productSpec: ProductSpec) {
    // Helper to compare numerically.
    const strToNum = (str: string) => {
      str = str.toUpperCase();

      if (str == undefined) {
        return 0;
      }

      switch (str) {
        case "RED":
          return 3;
        case "AMBER":
          return 2;
        case "GREEN":
          return 1;
        case "FAIL":
          return 0;
      }
    };

    const defaultRiskRating = strToNum(productSpec.product.ragRiskRating);
    const productSpecRiskRating = strToNum(productSpec.ragRiskRating);

    const riskDifferential = productSpecRiskRating - defaultRiskRating;

    if (riskDifferential > 0) {
      return true;
    } else if (riskDifferential == 0) {
      return false;
    } else {
      return false;
    }
  }

  parseCords(riskRating: string): string {
    switch (riskRating) {
      case "RED":
        return "Red";
      case "AMBER":
        return "Amber";
      case "GREEN":
        return "Green";
      case "FAIL":
        return "Fail";
      default:
        return "Not Set";
    }
  }
  // Update the max area for non compliant cords.
  updateNonCompCordsMaxArea(ragRiskRating: string) {
    this.auditService
      .updateMaxCordsArea(this.auditSpecId, ragRiskRating)
      .then((data) => {
        this.auditSpec.nonCompCordsMaxArea = ragRiskRating;
      })
      .catch((err) => {
        this.ns.error(err.error.message, 4000);
      });
  }

  // Update the max area for non compliant battens.
  updateNonCompBattensMaxArea(ragRiskRating: string) {
    this.auditService
      .updateMaxBattenArea(this.auditSpecId, ragRiskRating)
      .then((data) => {
        this.auditSpec.nonCompBattenMaxArea = ragRiskRating;
      })
      .catch((err) => {
        this.ns.error(err.error.message, 4000);
      });
  }


  openAddProductDialog() {
    const dialogRef = this.dialog.open(AddProductToSpecDialogComponent, {
      width: "500px",
      minHeight: "300px",
      maxHeight: "700px",
      data: {
        auditId: this.auditSpecId
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getAuditSpec();
      this.getProducts();
    });
  }


  // Change
  changeRagRisk(ragRiskRating: string, productId: string) {
    this.auditService
      .updateProduct(this.auditSpecId, productId, ragRiskRating)
      .then(() => {
        const product = this.auditSpec.products.find((p) => p._id === productId);
        product.ragRiskRating = ragRiskRating;
        this.auditSpec.products = [...this.auditSpec.products];
        this.productDataSource.data = [...this.auditSpec.products];
      })
      .catch((err) => { });
  }

  // remove Product
  removeProduct(productId: string) {
    this.auditService
      .removeProduct(this.auditSpecId, productId)
      .then(() => {
        this.getAuditSpec();
        this.ns.success('Product successfully removed.', 5000);
      })
      .catch((err) => {
        this.ns.error(err.message, 4000);
      });
  }

  // parse risk rating
  parseRiskRating(riskRating: string): string {
    if (riskRating === "RED") {
      return "Red";
    }
    if (riskRating === "AMBER") {
      return "Amber";
    }
    if (riskRating === "GREEN") {
      return "Green";
    }

    if (riskRating === "FAIL") {
      return "Fail";
    }

    return "Not Set";
  }

}


interface Person {
  name: string;
}

@Component({
  templateUrl: './dialogs/add-product-dialog.html',
  styleUrls: ["./audit-spec-editor.component.scss"],
})
export class AddProductToSpecDialogComponent implements OnInit {
  public addProductToSpecForm = this.fb.group({
    productId: ["", Validators.required],
    ragRiskRating: ["FAIL", Validators.required],
  })

  // public filteredProducts: Observable<any[]>;
  public products: Product[] = [];

  // Filter the products
  // private _filter(value: any): string[] {
  //   const filterValue = value.toLowerCase();

  //   return (this.products as any).filter((option: Product) =>
  //     option.product.toLowerCase().includes(filterValue)
  //   );
  // }

  ragRiskRatingList = ['Fail', 'Green', 'Amber', 'Red'];
  selectedRagRiskRating = this.ragRiskRatingList[0];
  public dropdownOpen = false;

  ngOnInit() {
    this.getProducts();
  }

  toggleDropdown() {  // Added toggleDropdown method
    this.dropdownOpen = !this.dropdownOpen;
  }

  setSelected(ragRiskRating: string) {
    console.log(ragRiskRating);
    this.dropdownOpen = false;
    this.selectedRagRiskRating = ragRiskRating;
    this.addProductToSpecForm.get('ragRiskRating').setValue(ragRiskRating.toUpperCase());
    console.log(this.addProductToSpecForm.get('ragRiskRating').value);
  }

  constructor(
    private dialogRef: MatDialogRef<AddProductToSpecDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { auditId: string },
    private auditService: AuditManagerService,
    private ns: NotificationsService,
    private productsService: ProductService,
    private fb: UntypedFormBuilder
  ) { }

  /**
   * Get Products.
   */
  getProducts() {
    if (this.products.length > 0) {
      return;
    }
    this.productsService
      .getAllProducts({}, {}, {})
      .then((products: any) => {
        this.products = products.data;
      })
      .catch((err) => {
        this.ns.error(err.message, 4000);
      });
  }


  // Add new product to audit spec
  addProduct() {
    if (this.addProductToSpecForm.invalid) {
      console.log(this.addProductToSpecForm)
      this.ns.error('The form has to be filled in.', 5000);
      return;
    }

    this.auditService
      .addProduct(
        this.data.auditId,
        this.addProductToSpecForm.get('productId').value,
        this.addProductToSpecForm.get('ragRiskRating').value
      )
      .then(() => {
        this.dialogRef.close(true);
        this.ns.success("Product added.", 5000);
      })
      .catch((err) => {
        this.ns.error(err.error.message, 4000);
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

} 