<div class="flex h-screen bg-gray-200" *ngIf="(sessionService.loggedInSubject | async)">
  <!-- Sidebar -->
  <div class="bg-sky-950 w-64 sm:w-64 lg:w-64 shadow-md flex flex-col" [class.hidden]="!(isHandset$ | async) === false">
    <div class="flex items-center justify-center py-4 border-b">
      <img class="w-32" src="/assets/images/logo.png" alt="Logo">
    </div>
    <nav class="flex-1 px-2 py-4 space-y-1">
      <ng-container *ngFor="let nav of navigationOptions">
        <a [routerLink]="nav.link" routerLinkActive="bg-sky-500"
          class="flex items-center px-4 py-2 text-slate-100 rounded hover:bg-gray-200">
          <ng-icon class="mr-2 !text-slate-100" [name]="nav.icon"></ng-icon>
          {{nav.name}}
        </a>
      </ng-container>
    </nav>
  </div>

  <!-- Main content -->
  <div class="flex flex-col flex-1 overflow-hidden">
    <!-- Toolbar -->
    <header class="flex items-center justify-between bg-white text-sky-950 px-4 py-4 shadow-lg shadow-gray-500/50">
      <!-- <div class="flex items-center space-x-2">
        <button *ngIf="navigation.canNavigateBack()" (click)="navigateBack()" class="text-sky-950">
          <mat-icon>arrow_back_ios</mat-icon>
          <span>Back</span>
        </button>
      </div> -->

      <div class="px-4 sm:px-6 lg:px-8">
        <app-search></app-search>
      </div>

      <div class="flex-1 text-right">
        <button class="ml-2 text-white" (click)="showChangesModal()">
          <mat-icon class="relative" [matBadgeHidden]="noOfChanges == 0" [matBadge]="noOfChanges" matBadgeColor="warn"
            matBadgeSize="small">new_releases</mat-icon>
        </button>
        <button class="ml-2 text-white" [matMenuTriggerFor]="appsMenu">
          <mat-icon>apps</mat-icon>
        </button>

        <mat-menu #appsMenu="matMenu" class="inline-flex flex-col min-w-40 bg-white rounded-md">
          <button cdk-menu-item (click)="routeToAudit('https://inspect.safer-track.com')"
            class="action-button px-3 py-2 text-sm leading-6 text-gray-900 hover:bg-gray-100 flex flex-row items-center justify-center">
            <ng-icon name="heroArrowTopRightOnSquare" class="text-gray-500 mr-2" size="18"></ng-icon>

            Safer Track Audit
          </button>
          <button cdk-menu-item (click)="logout()"
            class="action-button px-3 py-2 text-sm leading-6 text-gray-900 hover:bg-gray-100 flex flex-row items-center justify-center">
            <ng-icon name="heroArrowRightOnRectangle" class="text-gray-500 mr-2" size="18"></ng-icon>

            Log Out
          </button>
        </mat-menu>


        <!-- <mat-menu #appsMenu="matMenu">
          <a mat-menu-item href="https://inspect.safer-track.com" target="_blank">
            <mat-icon>open_in_new</mat-icon>
            Safer Track Audit
          </a>
          <button mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>
            Log Out
          </button>
        </mat-menu> -->
      </div>
    </header>

    <!-- Main Content -->
    <main class="flex-1 overflow-auto p-4 bg-white">
      <router-outlet #outlet="outlet" *ngIf="!isIframe"></router-outlet>
    </main>
  </div>
</div>

<!-- Show login screen if not logged in -->
<!-- <app-signin *ngIf="!(sessionService.loggedInSubject | async)"></app-signin> -->