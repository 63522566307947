<mat-toolbar color="primary">
  <span>What's New</span>
  <span class="spacer"></span>
  <button mat-icon-button (click)="close()" class="ml-4">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>

<mat-dialog-content>
  <ul>
    <li *ngFor="let change of releasedChanges" class="flex py-2 items-center">

      <div class="mr-5">
        <ng-icon name="heroChatBubbleBottomCenterText" size="18" class="text-gray-500"></ng-icon>
      </div>

      <div>
        <h6 class="text-sm font-semibold text-gray-700">{{ change.title }}</h6>
        <span class="text-sm font-medium text-gray-500 leading-normal">{{ change.message }}</span>
      </div>
    </li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions align='end'>
  <p class="text-sm text-gray-500 mb-2 self-end">v{{version}}</p>
</mat-dialog-actions>