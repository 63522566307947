import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Observable, Subscription } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { Contact } from "src/app/models/contact.model";
import { Customer } from "src/app/models/customer.model";
import { ContactsService } from "src/app/services/contacts/contacts.service";
import { CustomersService } from "src/app/services/customers/customers.service";
import { NotificationsService } from "src/app/services/notifications/notifications.service";
import { DeleteConfirmationComponent } from "../../sysDialogs/delete-confirmation/delete-confirmation.component";
import { BaseTableComponent } from "../../directives/base-table.directive";

@Component({
  selector: "app-contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.scss"],
})
export class ContactsComponent extends BaseTableComponent<Contact> implements OnInit, AfterViewInit, OnDestroy {
  contacts: Contact[];

  displayedColumns = [
    "name",
    "email",
    "phone",
    "customers",
    "lastLogin",
  ];

  private subs = new Subscription();

  constructor(
    private contactsService: ContactsService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator.length = this.paginationLength;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loadData();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  loadData(): void {
    const params = this.getRequestParams();
    const filters = this.buildFilterQuery(["name_first", "name_last", "email", "phone"]);

    this.contactsService
      .getAllContacts(params, filters, {})
      .then((contactsData: any) => {
        const contacts = contactsData.data;
        const contactsCount = contactsData.total;
        this.contacts = contacts.map(contact => ({
          ...contact,
          name: `${contact.name_first} ${contact.name_last}`
        }));
        this.dataSource.data = this.contacts;
        this.paginationLength = contactsCount;
      })
      .catch((err) => { });
  }

  daysAgo(date: Date) {
    const today = new Date();
    const diff = Math.abs(today.getTime() - date.getTime());
    const days = Math.ceil(diff / (1000 * 3600 * 24));
    return String(days);
  }

  async openCreateContact() {
    const dialogRef = this.dialog.open(CreateNewContactDialogComponent, {
      width: "500px",
      minHeight: "500px",
      maxHeight: "700px",
    });

    const d$ = dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        this.loadData();
      }
    });

    this.subs.add(d$);
  }
}

@Component({
  templateUrl: "./dialogs/CreateNewContact.component.html",
  styleUrls: ["./contacts.component.scss"],
})
export class CreateNewContactDialogComponent implements OnInit {
  // Form model for new contact

  newContact = this.fb.group({
    first_name: ["", Validators.required],
    last_name: ["", Validators.required],
    email: ["", Validators.required],
    phone: ["", Validators.required],
  });

  constructor(
    private contactsService: ContactsService,
    private ns: NotificationsService,
    public dialogRef: MatDialogRef<CreateNewContactDialogComponent>,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void { }

  createContact() {
    if (!this.newContact.valid) {
      this.ns.error('All forms have to be filled in.', 5000);
      return;
    }

    this.contactsService
      .createContact(this.newContact.value)
      .then(() => {
        this.ns.success(
          `Added new contact ${this.newContact.value.first_name} ${this.newContact.value.last_name}.`,
          4000
        );
        this.dialogRef.close(true);
      })
      .catch((err) => {
        if (err.status === 409) {
          return this.ns.error(
            `Contact with email ${this.newContact.value.email} already exists.`,
            5000
          );
        }

        this.ns.error("Something went wrong.", 4000);
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
