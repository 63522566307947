<div class="p-10 rounded-xl overflow-y-scroll" (keydown.enter)="submit()">
  <!-- Dialog title -->
  <h5 class="text-lg font-semibold mb-4 text-gray-900">Raise Inspection Request</h5>
  <div>

    <!-- Dialog Form -->
    <form [formGroup]='inspectionForm'>
      <h5 class="text-base font-medium mb-4 text-gray-700">Inspection Information</h5>

      <div class="w-full mt-1">
        <!-- Auditor Name -->
        <div class="mb-4">
          <label class="block text-sm font-medium leading-6 text-gray-900">Auditor Name</label>
          <input type="text" id="auditor" formControlName="auditor" required
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
        </div>

        <!-- Inspection Date -->
        <div class="mb-2">
          <label class="block text-sm font-medium leading-6 text-gray-900" for="inspectionDate">Inspection Date</label>
          <mat-form-field appearance="outline" class="w-full no-border">
            <input matInput [matDatepicker]="picker" id="inspectionDate" formControlName="inspectionDate" required
              class="block w-full rounded-md p-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:border-0 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- Internal Reference -->
        <div class="mb-4">
          <label class="block text-sm font-medium leading-6 text-gray-900">Internal Reference</label>
          <input type="text" id="product" formControlName="internalReference" required
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
        </div>

        <!-- useBlueprint -->
        <div class="mb-2">
          <hr>
          <mat-checkbox class="example-margin" color="primary" formControlName="useBlueprint" value="true">Use
            Blueprint</mat-checkbox>
          <label class="block text-xs font-medium leading-4 text-gray-500">Use the contract's blueprint and
            automatically import the areas, rooms and tracks.</label>
        </div>

        <!-- enforceAssetIDOnTracks -->
        <div class="mb-2">
          <hr>
          <mat-checkbox class="example-margin" color="primary" formControlName="enforceAssetIDOnTracks">Enforce Asset
            Tracking</mat-checkbox>
          <label class="block text-xs font-medium leading-4 text-gray-500">Select this option to enforce collection of
            asset IDs on tracks.</label>

        </div>

        <hr>

        <!-- Audit Type -->
        <div class="mb-4 mt-4">
          <label for="auditType" class="block text-sm font-medium leading-6 text-gray-900">Inspection Type</label>
          <select id="auditType" formControlName="auditType"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6"
            required>
            <option value="AUDIT">Annual Audit</option>
            <option value="INSTALLATION">Installation</option>
            <option value="PRODUCT_TESTING">Product Testing</option>
          </select>
        </div>

        <!-- Inspection Parameters -->
        <hr>
        <h5 class="mt-4 text-base font-semibold mb-4 text-gray-900">Inspection Parameters</h5>

        <!-- Logging Rate -->
        <div class="mb-4">
          <label for="loggingRate" class="block text-sm font-medium leading-6 text-gray-900">Logging Rate</label>
          <div class="relative rounded-md shadow-sm">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span class="text-gray-500 sm:text-sm">£</span>
            </div>
            <input type="text" name="loggingRate" id="loggingRate"
              class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="0.00" aria-describedby="loggingRate-currency" formControlName="loggingRate" required>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span class="text-gray-500 sm:text-sm" id="loggingRate-currency">GBP</span>
            </div>
          </div>
        </div>

        <!-- loadTestRate -->
        <div class="mb-4">
          <label for="loadTestRate" class="block text-sm font-medium leading-6 text-gray-900">Load Test Rate</label>
          <div class="relative rounded-md shadow-sm">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span class="text-gray-500 sm:text-sm">£</span>
            </div>
            <input type="text" name="loadTestRate" id="loadTestRate"
              class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="0.00" aria-describedby="loadTestRate-currency" formControlName="loadTestRate" required>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span class="text-gray-500 sm:text-sm" id="loadTestRate-currency">GBP</span>
            </div>
          </div>
        </div>

        <!-- gliderCountRate -->
        <div class="mb-4">
          <label for="gliderCountRate" class="block text-sm font-medium leading-6 text-gray-900">Glider Count
            Rate</label>
          <div class="relative rounded-md shadow-sm">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span class="text-gray-500 sm:text-sm">£</span>
            </div>
            <input type="text" name="gliderCountRate" id="gliderCountRate"
              class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="0.00" aria-describedby="gliderCountRate-currency" formControlName="gliderCountRate" required>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span class="text-gray-500 sm:text-sm" id="loggingRate-currency">GBP</span>
            </div>
          </div>
        </div>

        <!-- additionalLabourRate -->
        <div class="mb-4">
          <label for="additionalLabourRate" class="block text-sm font-medium leading-6 text-gray-900">Labour
            Rate</label>
          <div class="relative rounded-md shadow-sm">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span class="text-gray-500 sm:text-sm">£</span>
            </div>
            <input type="text" name="additionalLabourRate" id="additionalLabourRate"
              class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="0.00" aria-describedby="additionalLabourRate-currency" formControlName="additionalLabourRate"
              required>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span class="text-gray-500 sm:text-sm" id="additionalLabourRate-currency">GBP</span>
            </div>
          </div>
        </div>

        <!-- fixedFee -->
        <div class="mb-8">
          <label for="fixedFee" class="block text-sm font-medium leading-6 text-gray-900">Fixed Fee</label>
          <div class="relative rounded-md shadow-sm">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span class="text-gray-500 sm:text-sm">£</span>
            </div>
            <input type="text" name="fixedFee" id="fixedFee"
              class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="0.00" aria-describedby="fixedFee-currency" formControlName="fixedFee" required>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span class="text-gray-500 sm:text-sm" id="fixedFee-currency">GBP</span>
            </div>
          </div>
        </div>

      </div>
    </form>
  </div>

  <!-- Dialog Actions -->
  <div class="flex justify-end space-x-2 mt-4">
    <button type="button"
      class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
      (click)="onNoClick()">Cancel</button>
    <button type="button"
      class="bg-blue-600 text-white rounded-md px-4 py-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      (click)="submit()">Submit</button>
  </div>

</div>