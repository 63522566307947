<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12">
      <div class="bg-white container-card mat-elevation-z1">
        <mat-toolbar color="primary">
          <h5>Inspection Requests</h5>
        </mat-toolbar>
        <div class="p-1">
          <app-requests-table [requests]="requests" (refresh)="getRequests()"></app-requests-table>
        </div>
      </div>
    </div>
  </div>
</div>
