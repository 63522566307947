import { ApiResponse } from "src/app/typings/response";
import { Contact } from "./../../models/contact.model";
import { Config } from "./../../config/config";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ContactsService {
  baseUrlV2: string;

  constructor(private http: HttpClient) {
    this.baseUrlV2 = Config.api.getBaseUrlV2();
  }

  /**
   * Create a new contact.
   * If a contact with the same email address already exists, it will be returned.
   *
   * If provided with a customerId, the contact will be added to the customer.
   *
   * @param contact
   * @param customerId
   * @returns
   */
  createContactAndAddToCustomer(contact: Contact, customerId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(this.baseUrlV2 + "/contacts", {
          contact,
          customerId,
        })
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  createContact(contact: Contact) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(this.baseUrlV2 + "/contacts", {
          contact,
        })
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  createContactAndAddToContract(contact: Contact, contractId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(this.baseUrlV2 + "/contacts/contact/contract", {
          contact,
          contractId,
        })
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  getAllContacts(paginationParams, filterParams, sortParams) {
    return new Promise((resolve, reject) => {
      this.http.get<ApiResponse>(this.baseUrlV2 + "/contacts", {params: {...paginationParams, ...filterParams, ...sortParams}}).subscribe(
        (r) => {
          resolve(r.data);
        },
        (e) => reject(e)
      );
    });
  }

  createCustomerUser(contactId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(this.baseUrlV2 + `/contacts/customer-user/invite`, {
          contactId,
        })
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  getAllContactsForCustomer(customerId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.baseUrlV2 + `/contacts/customer/${customerId}`)
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  addExistingContactToContract(contactId: string, contractId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(
          this.baseUrlV2 + "/contacts/contact/existing/contract",
          {
            contractId,
            contactId,
          }
        )
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  makeContactPrimary(customerId: string, contactId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .patch<ApiResponse>(
          this.baseUrlV2 +
            `/contacts/${contactId}/customer/${customerId}/primary`,
          {}
        )
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  deleteContact(contactId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .delete<ApiResponse>(this.baseUrlV2 + `/contacts/${contactId}`)
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  getContact(contactId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.baseUrlV2 + `/contacts/${contactId}`)
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  addCustomerToContact(contactId: string, customerId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .patch<ApiResponse>(
          this.baseUrlV2 + `/contacts/${contactId}/customers/${customerId}`,
          {}
        )
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  removeContactFromCustomer(contactId: string, customerId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .delete<ApiResponse>(
          this.baseUrlV2 + `/contacts/${contactId}/customers/${customerId}`
        )
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  resendInvite(contactId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(
          this.baseUrlV2 + `/contacts/customer-user/resend-invite`,
          {
            contactId,
          }
        )
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }
}
