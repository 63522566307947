import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  constructor(private http: HttpClient) { }

  getUsers() {
    return new Promise((resolve, reject) => {
      this.http.get<any>('https://graph.microsoft.com/v1.0/users')
        .subscribe(r => resolve(r.value), e => reject(e));
    });
  }

  getUser() {
    return new Promise((resolve, reject) => {
      this.http.get<any>('https://graph.microsoft.com/v1.0/me')
        .subscribe(r => resolve(r), e => reject(e));
    });

  }
}
