<div class="p-10 rounded-xl overflow-y-scroll" (keydown.enter)="changeType()">
    <!-- Dialog title -->
    <h5 class="text-lg font-semibold mb-4 text-gray-900">Change Audit Type</h5>
    <div>

        <!-- Dialog Form -->
        <div class="w-full mt-1">
            <!-- Audit Type -->
            <div class="mb-4">
                <div [formGroup]="auditTypeForm">
                    <div class="mb-2">
                        <label class="block text-sm font-medium leading-6 text-gray-900">
                            <input type="radio" formControlName="options" value="AUDIT"
                                [checked]="auditTypeForm.controls.options.value === 'AUDIT'">
                            <span class="ml-2">Audit</span>
                        </label>
                    </div>

                    <div class="mb-2">
                        <label class="block text-sm font-medium leading-6 text-gray-900">
                            <input type="radio" formControlName="options" value="PRODUCT_TESTING"
                                [checked]="auditTypeForm.controls.options.value === 'PRODUCT_TESTING'">
                            <span class="ml-2">Product testing</span>
                        </label>
                    </div>

                    <div class="mb-2">
                        <label class="block text-sm font-medium leading-6 text-gray-900">
                            <input type="radio" formControlName="options" value="INSTALLATION"
                                [checked]="auditTypeForm.controls.options.value === 'INSTALLATION'">
                            <span class="ml-2">Installation</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <!-- Dialog Actions -->
        <div class="flex justify-end space-x-2 mt-8">
            <button type="button"
                class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                (click)="onNoClick()">Cancel</button>
            <button type="button"
                class="bg-blue-600 text-white rounded-md px-4 py-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                (click)="changeType()">Change Audit Type</button>
        </div>
    </div>
</div>