import { environment } from '@env/environment';

let ENV = "DEV";

if (environment.production) {
  ENV = "PROD";
}

export const Config = {
  version: "3.0.1",
  api: {
    dev: "http://localhost:3000/v1",
    devv2: "http://localhost:3000/v2",
    devOpen: "http://localhost:3000",
    //prod: 'https://api.custom-group.com/safetrak',
    prod: "https://api.safer-track.com/v2",

    stagingv1: "https://v2.api.safer-track.com/v1",
    stagingv2: "https://v2.api.safer-track.com/v2",

    getBaseUrl() {
      switch (ENV) {
        case "DEV":
          return Config.api.dev;
          break;
        case "DEVOPEN":
          return Config.api.devOpen;
        case "STAGING":
          return Config.api.stagingv1;
        case "PROD":
          return Config.api.prod;
      }
    },
    getBaseUrlV2() {
      switch (ENV) {
        case "DEV":
          return Config.api.devv2;
          break;
        case "DEVOPEN":
          return Config.api.devOpen;
        case "STAGING":
          return Config.api.stagingv2;
        case "PROD":
          return Config.api.prod;
      }
    },
  },
  msal: {
    redirect_uri: {
      dev: "http://localhost:4200",
      devOpen: "http://192.168.16.70:4200",
      staging: "https://st-admin-stg.azurewebsites.net",
      prod: "https://admin.safer-track.com",
      getRedirectURI: () => {
        switch (ENV) {
          case "DEV":
            return Config.msal.redirect_uri.dev;
            break;
          case "DEVOPEN":
            return Config.msal.redirect_uri.devOpen;
            break;
          case "STAGING":
            return Config.msal.redirect_uri.staging;
          case "PROD":
            return Config.msal.redirect_uri.prod;
        }
      },
    },
  },
};
