<table mat-table [dataSource]="tableData" class="w-100">

    <ng-container matColumnDef="customer">
        <th mat-header-cell *matHeaderCellDef> Customer </th>
        <td mat-cell *matCellDef="let request"> {{request.contract.customer.organisationName}}</td>
    </ng-container>
    <ng-container matColumnDef="contract">
        <th mat-header-cell *matHeaderCellDef> Contract Name</th>
        <td mat-cell *matCellDef="let request"> {{request.contract.name}}</td>
    </ng-container>
    <ng-container matColumnDef="requestedBy">
        <th mat-header-cell *matHeaderCellDef> Requested By</th>
        <td mat-cell *matCellDef="let request"> {{request.requestedBy}}</td>
    </ng-container>
    <ng-container matColumnDef="requestedOn">
        <th mat-header-cell *matHeaderCellDef> Requested On</th>
        <td mat-cell *matCellDef="let request"> {{request.createdAt|date}}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let request">
            <button mat-raised-button color="primary" class="float-right" (click)="confirmRequest(request)">Go to contract</button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>

</table>

<div class="p-3" *ngIf="tableData.data.length === 0">
    <small>There are no pending inspection requests.</small>
</div>
