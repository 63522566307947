import { Injectable } from "@angular/core";
import { Config } from "../../config/config";
import { HttpClient } from "@angular/common/http";
import { ApiResponse } from "../../typings/response";

export interface IReportNote {
  _id?: string;
  title: string;
  note: string;
}

@Injectable({
  providedIn: "root",
})
export class ReportNotesService {
  public baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = Config.api.getBaseUrlV2();
  }

  /**
   * Get all generic report notes
   */
  async getReportNotes() {
    return new Promise((resolve, reject) => {
      this.http.get<ApiResponse>(this.baseUrl + "/report-notes").subscribe(
        (r) => resolve(r.data),
        (e) => reject(e)
      );
    });
  }

  /**
   * Create generic report note
   */
  async createReportNote(reportNote: IReportNote) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(this.baseUrl + "/report-notes", reportNote)
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  /**
   * Delete generic report note
   * @param id
   */
  async deleteReportNote(id: string) {
    return new Promise((resolve, reject) => {
      this.http
        .delete<ApiResponse>(this.baseUrl + `/report-notes/${id}`)
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }
}
