import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { IReport } from "./../../models/report";
import { NotificationsService } from "./../../services/notifications/notifications.service";
import { BehaviorSubject, Subscription } from "rxjs";
import { Track } from "./../../models/track.model";
import { SafetyInspection } from "./../../models/safetyInspection";
import { InspectionService } from "./../../services/inspection/inspection.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
  Output,
  EventEmitter,
  OnDestroy,
  HostListener,
  ChangeDetectorRef
} from "@angular/core";
import { Location } from "@angular/common";
import { FlatTreeControl, NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { Area } from "src/app/models/area";
import { Room } from "src/app/models/room";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
  FormControl
} from "@angular/forms";
import anime from "animejs";
import { IReportNote, ReportNotesService } from "@app/services/report-notes/report-notes.service";
import { ContactsService } from "@app/services/contacts/contacts.service";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";

interface TreeNode {
  name: string;
  passed?: boolean;
  children?: any[];
}

@Component({
  selector: "app-inspection",
  templateUrl: "./inspection.component.html",
  styleUrls: ["./inspection.component.scss"],
})
export class InspectionComponent implements OnInit {
  @ViewChild("extrasContainer") extrasContainer: ElementRef;
  @Output() updated = new EventEmitter();

  public inspection: SafetyInspection;

  public notesExpanded = false;
  public pricingExpanded = false;
  public extrasExpanded = false;

  public generateReportLoading = false;

  treeControl = new NestedTreeControl<TreeNode>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<TreeNode>();

  public editing = false;
  public auditor = new UntypedFormControl("");
  public inspectionDate = new UntypedFormControl("");
  public internalReference = new UntypedFormControl("");

  public subs = new Subscription();

  constructor(
    private dialog: MatDialog,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private inspectionService: InspectionService,
    private ns: NotificationsService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.getInspection();
  }

  hasChild = (_: number, node: TreeNode) =>
    !!node.children && node.children.length > 0;

  /**
   * Fetch the inspection from the API.
   */
  async getInspection() {
    this.route.params.subscribe((params) => {
      this.inspectionService
        .getInspection(params.id)
        .then((inspection: SafetyInspection) => {
          this.inspection = inspection;
          // this.reports = inspection.reports;
          // this.reports.next(inspection.reports);
          this.dataSource.data = this.convertToTreeData(inspection);
          console.log(this.inspection)
          anime(
            {
              targets: [".container-card"],
              opacity: [0, 1],
              duration: 1500,
              delay: (el, i, l) => {
                return i * 150;
              },
            },
            2000
          );
        })
        .catch((err) => {
          this.ns.error(err.error.display, 5000);
          this.location.back();
        });
    });
  }

  /**
   * Generate the audit report.
   */
  regenerateReport() {
    this.generateReportLoading = true;
    this.inspectionService
      .generateReport(this.inspection._id)
      .then((result: IReport) => {
        setTimeout(() => {
          this.ns.success(
            `Successfully created report version ${result.version}`,
            3000
          );
          this.generateReportLoading = false;
          this.getInspection();
        }, 5000);
      })
      .catch(() => {
        this.generateReportLoading = false;
      });
  }

  /**
   * Generate the downloadable measurement report.
   */
  generateMeasurementReport() {
    this.inspectionService
      .generateMeasurementReport(
        this.inspection._id,
        this.inspection.contract.name,
        this.inspection.contract.customer.organisationName,
        this.inspection.internalReference
      )
      .then(() => { })
      .catch(() => { this.ns.error('Generate Measurement Error', 5000); });
  }

  back() {
    this.location.back();
  }

  /**
   * Set the inspection as complete.
   */
  markComplete() {
    this.inspectionService
      .markComplete(this.inspection._id)
      .then((res) => {
        this.inspection.complete = true;
      })
      .catch((err) => {
        this.inspection.complete = false;
      });
  }

  /**
   * Set the report as pending
   */
  markPending() {
    this.inspectionService
      .markPending(this.inspection._id)
      .then((res) => {
        this.inspection.complete = false;
      })
      .catch((err) => {
        this.inspection.complete = true;
      });
  }

  convertToTreeData(inspection: SafetyInspection) {
    const areas = inspection.areas.map((area: Area) => {
      return {
        name: area.name,
        type: "area",
        children: area.rooms.map((room: Room) => {
          return {
            name: room.name,
            type: "room",
            children: room.tracks.map((track: Track) => {
              return {
                type: "track",
                name: track.product.product,
                passed: track.passed,
                children: track.measurements.map((e: string) => {
                  return { name: e };
                }),
              };
            }),
          };
        }),
      };
    });

    return areas;
  }

  expandExtras() {
    if (this.extrasExpanded) {
      this.extrasContainer.nativeElement.style.display = "none";
      this.extrasExpanded = !this.extrasExpanded;
    } else {
      this.extrasContainer.nativeElement.style.display = "flex";
      this.extrasExpanded = !this.extrasExpanded;
    }
  }

  parseInspectionType(type: String) {
    switch (type) {
      case "AUDIT":
        return "Audit";
      case "INSTALLATION":
        return "Installation";
      case "PRODUCT_TESTING":
        return "Product Testing";
    }
  }

  async openEditDetailsDialog(section: string) {
    const dialogRef = this.dialog.open(ManageEditDetailsDialogComponent, {
      width: "500px",
      minHeight: "300px",
      maxHeight: "700px",
      data: {
        section: section,
        inspection: this.inspection,
      }
    })

    dialogRef.afterClosed().subscribe(async (result) => {
      await this.getInspection();
      this.changeDetectorRef.detectChanges();
    });
  }

  goToInvoiceEditor() {
    this.router.navigate(['/invoice-manager', this.inspection._id]);
  }

  async openNewInternalNoteDialog(section) {
    const dialogRef = this.dialog.open(NewNoteDialogComponent, {
      width: "500px",
      minHeight: "300px",
      maxHeight: "700px",
      data: {
        inspectionId: this.inspection._id,
        section: section,
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getInspection();
    });
  }

  deleteNote(note: string, section: string) {
    if (section == 'internal') {
      this.inspectionService.deleteInternalNote(this.inspection._id, note)
        .then(result => {
          this.inspection.internalNotes = this.inspection.internalNotes.filter(note1 => note1 !== note);
        })
        .catch(err => {
          this.ns.error(err.error.display, 3000);
        });
    }
    else if (section == 'report') {
      this.inspectionService
        .deleteReportNote(this.inspection._id, note)
        .then((result) => {
          this.inspection.notes = this.inspection.notes.filter((note1) => note1 !== note);
        })
        .catch((err) => {
          this.ns.error(err.error.display, 3000);
        });
    }
  }

  addGenericNoteDialog() {
    const dialogRef = this.dialog.open(AddGenericNoteToReportDialogComponent, {
      width: "500px",
      minHeight: "200px",
      maxHeight: "700px",
      data: {
        inspection: this.inspection
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getInspection();
    });
  }

  showSendConfirmationDialog(report) {
    const dialogRef = this.dialog.open(SendConfirmationDialogComponent, {
      width: "500px",
      minHeight: "300px",
      maxHeight: "700px",
      data: {
        report,
        inspectionId: this.inspection._id
      }
    })

    dialogRef.afterClosed().subscribe((result) => {
      this.getInspection();
    });
  }

  openChangeAuditTypeDialog() {
    const dialogRef = this.dialog.open(ChangeAuditTypeDialogComponent, {
      width: "500px",
      minHeight: "300px",
      maxHeight: "700px",
      data: {
        inspection: this.inspection,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getInspection();
    });
  }

  generateBlueprint() {
    this.inspectionService
      .generateBlueprint(this.inspection._id)
      .then(() => {
        this.ns.success(
          `Successfully generated blueprint for inspection ${this.inspection.internalReference}`,
          2000
        );
      })
      .catch(() => { });
  }

  openDeleteInspectionDialog() {
    const dialogRef = this.dialog.open(ConfirmInspectionDeleteDialogComponent, {
      width: "500px",
      minHeight: "200px",
      maxHeight: "700px",
      data: {
        inspection: this.inspection,
      },
    });
  }

}

@Component({
  templateUrl: './dialogs/edit-inspectionDetails-dialog.html'
})
export class ManageEditDetailsDialogComponent implements OnInit {
  @Output() updated = new EventEmitter();

  public section = this.data.section;
  public inspection = this.data.inspection;

  public inspectionDetailsForm = this.fb.group({
    auditor: [this.inspection.auditor, Validators.required],
    inspectionDate: [this.inspection.date, Validators.required],
    internalReference: [this.inspection.internalReference, Validators.required]
  });

  public pricingForm = this.fb.group({
    loggingRate: [this.inspection.loggingRate, Validators.required],
    loadTestRate: [this.inspection.loadTestRate, Validators.required],
    gliderCountRate: [this.inspection.gliderCountRate, Validators.required],
    additionalLabourRate: [this.inspection.additionalLabourRate, Validators.required],
    fixedFee: [this.inspection.fixedFee, Validators.required],
  });

  public additionalParametersForm = this.fb.group({
    additionalLabourHours: [this.inspection.additionalLabourTime, Validators.required],
    additionalPartsPrice: [this.inspection.additionalPartPrice, Validators.required],
  })

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { section: string, inspection: SafetyInspection },
    public dialogRef: MatDialogRef<ManageEditDetailsDialogComponent>,
    private fb: UntypedFormBuilder,
    private inspectionService: InspectionService,
    private ns: NotificationsService,
    private route: ActivatedRoute,
  ) { }


  ngOnInit(): void {
    return;
  }

  async editInspectionDetails(changes: Object) {
    this.inspectionService
      .editInspection(this.inspection._id, changes)
      .then(() => {
        this.ns.success("Updated Inspection Details", 3000);
        this.updated.emit();
      })
      .catch((err) => {
        this.ns.error(err.error.display, 3000);
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async submit() {
    console.log('pressed enter')
    var changes: Object;
    var inputValid: boolean = false;

    switch (this.section) {
      case "inspection":
        if (this.inspectionDetailsForm.get('auditor').valid &&
          this.inspectionDetailsForm.get('inspectionDate').valid &&
          this.inspectionDetailsForm.get('internalReference').valid
        ) {
          changes = {
            auditor: this.inspectionDetailsForm.get('auditor').value,
            date: this.inspectionDetailsForm.get('inspectionDate').value,
            internalReference: this.inspectionDetailsForm.get('internalReference').value,
          }
          inputValid = true;
        }

        break;
      case "pricing":
        if (this.pricingForm.get('loggingRate').valid &&
          this.pricingForm.get('loadTestRate').valid &&
          this.pricingForm.get('additionalLabourRate').valid &&
          this.pricingForm.get('gliderCountRate').valid &&
          this.pricingForm.get('fixedFee').valid
        ) {
          changes = {
            loggingRate: this.pricingForm.get('loggingRate').value,
            loadTestRate: this.pricingForm.get('loadTestRate').value,
            additionalLabourRate: this.pricingForm.get('additionalLabourRate').value,
            gliderCountRate: this.pricingForm.get('gliderCountRate').value,
            fixedFee: this.pricingForm.get('fixedFee').value
          };
          inputValid = true;
        }
        break;
      case "additionalParameters":
        if (this.additionalParametersForm.get('additionalLabourHours').valid &&
          this.additionalParametersForm.get('additionalPartsPrice').valid
        ) {
          changes = {
            additionalLabourTime: this.additionalParametersForm.get('additionalLabourHours').value,
            additionalPartPrice: this.additionalParametersForm.get('additionalPartsPrice').value,
          };
          inputValid = true;
        }
        break;
    }

    if (inputValid) {
      this.editInspectionDetails(changes);
      this.dialogRef.close(true);
    }
    else {
      this.ns.error('All forms have to be filled in.', 5000)
    }
  }


}

@Component({
  templateUrl: './dialogs/new-note-dialog.component.html'
})
export class NewNoteDialogComponent implements OnInit {
  @ViewChild('noteTextarea') noteTextarea: ElementRef;
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (document.activeElement !== this.noteTextarea.nativeElement) {
      event.preventDefault();
      this.addNote();
    }
  }

  public loading = false;

  public updateEvent = new EventEmitter();
  public noteForm = this.fb.group({
    note: ["", Validators.required]
  })
  private note = this.noteForm.get('note');

  public section = this.data.section;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<NewNoteDialogComponent>,
    private inspectionService: InspectionService,
    private ns: NotificationsService,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    return;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addNote() {
    this.loading = true;
    if (!this.note.valid) {
      return this.ns.error('The note cannot be empty.', 3000);
    }
    if (this.section === 'internal') {

      this.inspectionService.addInternalNote(this.data.inspectionId, this.note.value)
        .then(result => {
          this.ns.success('Successfully added note.', 5000);
          this.updateEvent.emit(this.note.value);
          this.loading = false;
          this.note.reset();
          this.dialogRef.close();
        })
        .catch(err => {
          this.loading = false;
          this.ns.error(err.error.display, 4000);
        });
    }
    else if (this.section === 'report') {
      this.inspectionService
        .addReportNote(this.data.inspectionId, this.note.value)
        .then((result) => {
          this.ns.success("Successfully added note.", 3000);
          this.updateEvent.emit(this.note.value);
          this.loading = false;
          this.note.reset();
          this.dialogRef.close();
        })
        .catch((err) => {
          this.loading = false;
          this.ns.error(err.error.display, 4000);
        });
    }



  }
}

@Component({
  templateUrl: './dialogs/add-genericNote-toReport-dialog.html'
})
export class AddGenericNoteToReportDialogComponent implements OnInit {

  public notes: IReportNote[] = [];
  public updateEvent = new EventEmitter();
  public targetGenericNoteForm = this.fb.group({
    targetGenericNote: ["", Validators.required],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private reportNoteService: ReportNotesService,
    private inspectionService: InspectionService,
    private ns: NotificationsService,
    private dialogRef: MatDialogRef<AddGenericNoteToReportDialogComponent>,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.getReportNotes();
  }

  getReportNotes() {
    try {
      this.reportNoteService.getReportNotes().then((notes: any) => {
        this.notes = notes;
      });
    } catch (error) {
      this.dialogRef.close(false);
      this.ns.error("Something went wrong. Please try again later.", 3000);
    }
  }

  submit() {
    if (this.targetGenericNoteForm.get('targetGenericNote').valid) {
      let note = this.targetGenericNoteForm.get("targetGenericNote").value;
      this.inspectionService.addReportNote(this.data.inspection._id, note)
        .then(() => {
          this.ns.success('Successfully added note.', 3000);
          this.updateEvent.emit(note);
          this.dialogRef.close();
        })
        .catch((err) => {
          this.ns.error(err.error.display, 3000);
        });
    }
    else {
      this.ns.error('Please select a generic note', 3000)
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  // templateUrl: './partials/inspection-documents/dialog/sendConfirmationDialog.html'
  templateUrl: './dialogs/send-confirmation-dialog.html'
})
export class SendConfirmationDialogComponent implements OnInit, OnDestroy {
  @ViewChild("fruitInput") fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;

  filteredFruits;
  fruitCtrl = new UntypedFormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];

  public confirmation = new UntypedFormControl();

  inspection: SafetyInspection;
  contacts: any[];
  contactEmails: string[] = [];

  recipients: any[] = [];
  additionalRecipients: any[] = [];
  public additionalRecipient = new UntypedFormControl("", Validators.email);

  // V2 Implementation variables
  sendToPrimaryContact = new FormControl(false);

  private subs = new Subscription();

  recipientsForm = {};

  constructor(
    public dialogRef: MatDialogRef<SendConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { report: IReport; inspectionId: string },
    private inspectionService: InspectionService,
    private notifications: NotificationsService,
    private contactsService: ContactsService
  ) { }

  ngOnInit() {
    this.getInspection();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getInspection() {
    this.inspectionService
      .getInspection(this.data.inspectionId)
      .then((inspection: SafetyInspection) => {
        this.inspection = inspection;
        this.getContacts(this.inspection.contract.customer._id);
      });
  }

  getContacts(id: string) {
    this.contactsService
      .getAllContactsForCustomer(id)
      .then((contacts: any[]) => {
        this.contacts = contacts;
        this.contactEmails = this.contacts.map((contact) => contact.email);

        // Create dynamic form controls
        this.contacts.map((contact) => {
          this.recipientsForm[contact.email] = new FormControl(false);
          this.recipientsForm[contact.email].contact_name =
            contact.name_first + " " + contact.name_last;

          this.recipientsForm[contact.email].valueChanges.subscribe((value) => {
            if (value) {
              this.recipients.push(contact.email);
            } else {
              this.recipients = this.recipients.filter(
                (email) => email !== contact.email
              );
            }
          });
        });
      })
      .catch((err) => { });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    let allRecipients = [...new Set([...this.recipients, ...this.additionalRecipients])]

    this.inspectionService
      .sendReportToCustomer(
        this.data.inspectionId,
        this.data.report._id,
        allRecipients,
        []
      )
      .then((result) => {
        this.dialogRef.close(true);

        this.notifications.success("Successfully sent!", 3000);
      })
      .catch((err) => {
        this.dialogRef.close(false);
        this.notifications.error(err.error.display, 4000);
      });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || "").trim()) {
      this.recipients.push(value.trim());
      this.additionalRecipients.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.fruitCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.recipients.indexOf(fruit);

    if (index >= 0) {
      this.recipients.splice(index, 1);
    }

    const additionalIndex = this.additionalRecipients.indexOf(fruit);

    if (additionalIndex >= 0) {
      this.additionalRecipients.splice(additionalIndex, 1);
    }
  }

  addRecipient() {
    console.log(this.additionalRecipient.valid, !this.additionalRecipient.errors?.['email'])
    if (this.additionalRecipient.valid && !this.additionalRecipient.errors?.['email']) {
      let recipient = this.additionalRecipient.value;
      this.additionalRecipients.push(recipient.trim());
      this.additionalRecipient.setValue('');
    }
  }

  removeRecipient(recipient) {
    const additionalIndex = this.additionalRecipients.indexOf(recipient);
    if (additionalIndex >= 0) {
      this.additionalRecipients.splice(additionalIndex, 1);
    }
  }
}


@Component({
  templateUrl: './dialogs/delete-inspection-dialog.html',
})
export class ConfirmInspectionDeleteDialogComponent implements OnInit {
  loading = false;
  confirmation = new UntypedFormControl(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { inspection: SafetyInspection },
    private inspectionService: InspectionService,
    private ns: NotificationsService,
    private router: Router,
    private dialogRef: MatDialogRef<ConfirmInspectionDeleteDialogComponent>
  ) { }

  ngOnInit() { }

  deleteInspection() {
    if (!this.confirmation.value) {
      this.ns.error('Please click the box to confirm your action.', 5000);
      return;
    }
    this.loading = true;
    this.inspectionService
      .deleteInspection(this.data.inspection._id)
      .then(() => {
        this.loading = false;
        this.ns.success(
          `Deleted Contract ${this.data.inspection.reference}`,
          4000
        );
        this.dialogRef.close();
        this.router.navigate([
          `/contract/${this.data.inspection.contract._id}`,
        ]);
      })
      .catch((err) => {
        this.loading = false;
        this.ns.error(err.error.display, 2000);
      });
  }
}

@Component({
  templateUrl: './dialogs/change-auditType-dialog.html',
  styleUrls: ["./inspection.component.scss"],
})
export class ChangeAuditTypeDialogComponent {
  auditTypeForm = this.fb.group({
    options: [this.data.inspection.auditType, Validators.required]
  })

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { inspection: SafetyInspection },
    private inspectionService: InspectionService,
    private ns: NotificationsService,
    private dialogRef: MatDialogRef<ChangeAuditTypeDialogComponent>,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() { }

  changeType() {
    if (this.auditTypeForm.invalid) {
      this.ns.error('An option has to be selected.', 5000)
      return;
    }

    this.inspectionService
      .changeInspectionType(this.data.inspection._id, this.auditTypeForm.get('options').value)
      .then(() => {
        this.dialogRef.close();
        this.ns.success(
          `Changed Audit Type for ${this.data.inspection.internalReference
          } to ${this.parseInspectionType(this.auditTypeForm.get('options').value)}`,
          2000
        );
        this.dialogRef.close();
      })
      .catch((err) => {
        this.ns.error(err.error.display, 2000);
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  parseInspectionType(type: String) {
    switch (type) {
      case "AUDIT":
        return "Audit";
      case "INSTALLATION":
        return "Installation";
      case "PRODUCT_TESTING":
        return "Product Testing";
    }
  }
}
