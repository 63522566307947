import { SysErrorDialogComponent } from './../sysDialogs/sys-error-dialog/sys-error-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from './../services/notifications/notifications.service';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationsService, private dialog: MatDialog) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
         return;
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          try {
            if (err.status === 0) {
              // tslint:disable-next-line: max-line-length
              // this.notificationService.error('Unable to connect. Please refresh. If this problem persists please contact jackm@custom-group.com', 30000);
              this.dialog.open(SysErrorDialogComponent, {
                width: '700px',
                //height: '300px'
              });
            }

            this.notificationService.error(err.error.message, 3000);

            return throwError(err);

          } catch (e) {

          }
          //log error
        }
        return of(err);
      }));

  }
}
