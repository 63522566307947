import { NotificationsService } from './../../../../services/notifications/notifications.service';
import { InspectionService } from 'src/app/services/inspection/inspection.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, Subscription } from 'rxjs';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-requests-table',
  templateUrl: './requests-table.component.html',
  styleUrls: ['./requests-table.component.scss']
})
export class RequestsTableComponent implements OnInit, OnDestroy {

  @Input() requests: Subject<any>;
  @Output() refresh = new EventEmitter();

  tableData = new MatTableDataSource<any>();

  subs = new Subscription();

  columnsToDisplay = ['customer', 'contract', 'requestedBy', 'requestedOn', 'actions'];

  constructor(private dialog: MatDialog, private router: Router) { }

  ngOnInit() {

    const requests$ = this.requests.subscribe((requests) => {
      this.tableData.data = requests;
    });

    this.subs.add(requests$);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  confirmRequest(request: any) {
    this.router.navigate([`/contract/${request.contract._id}`])
  }

}

