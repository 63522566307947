<div class="p-10" (keydown.enter)="submit()">
    <h2 class="text-xl font-semibold mb-4">Edit Customer Details</h2>
    <form [formGroup]="editForm">
        <div class="mb-4">
            <label class="block text-sm font-medium leading-6 text-gray-900" for="organisationName">Organisation
                Name</label>
            <input type="text" id="organisationName" formControlName="organisationName" required
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-primary sm:text-sm sm:leading-6" />
        </div>
        <div class="mb-4">
            <label class="block text-sm font-medium leading-6 text-gray-900" for="address_line_1">Address Line 1</label>
            <input type="text" id="address_line_1" formControlName="address_line_1" required
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-primary sm:text-sm sm:leading-6" />
        </div>
        <div class="mb-4">
            <label class="block text-sm font-medium leading-6 text-gray-900" for="address_line_2">Address Line 2</label>
            <input type="text" id="address_line_2" formControlName="address_line_2"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-primary sm:text-sm sm:leading-6" />
        </div>
        <div class="mb-4">
            <label class="block text-sm font-medium leading-6 text-gray-900" for="town">Town</label>
            <input type="text" id="town" formControlName="town" required
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-primary sm:text-sm sm:leading-6" />
        </div>
        <div class="mb-4">
            <label class="block text-sm font-medium leading-6 text-gray-900" for="county">County</label>
            <input type="text" id="county" formControlName="county"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-primary sm:text-sm sm:leading-6" />
        </div>
        <div class="mb-4">
            <label class="block text-sm font-medium leading-6 text-gray-900" for="postcode">Postcode</label>
            <input type="text" id="postcode" formControlName="postcode" required
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-primary sm:text-sm sm:leading-6" />
        </div>
        <div class="flex justify-end mt-4 space-x-3">
            <button type="button" (click)="onNoClick()"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                Cancel
            </button>
            <button type="submit" [disabled]="editForm.invalid" (click)="submit()"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-cg-primary hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cg-primary">
                Save
            </button>
        </div>
    </form>
</div>