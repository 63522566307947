import { NotificationsService } from './../../services/notifications/notifications.service';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SettingsService } from './../../services/settings/settings.service';
import { Component, OnInit, AfterViewInit, ViewChild, Inject, EventEmitter, Output } from '@angular/core';
import anime from 'animejs';
import { MatMenuTrigger } from '@angular/material/menu';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

interface TrackUsage {
  _id: string;
  value: string;
  active: boolean;
}


@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss']
})
export class ParametersComponent implements OnInit, AfterViewInit {

  public maxChildWeight: number;
  public maxChildWeightLoading = false;

  public trackUsagesLoading = false;
  public trackUsagesLoadingErr = false;
  public trackUsages: TrackUsage[] = null;

  constructor(
    private settingsService: SettingsService,
    private ns: NotificationsService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getSettings();
    this.getTrackUsages();
  }

  getSettings() {
    this.maxChildWeightLoading = true;
    this.settingsService.getLowWeightParameter()
      .then((parameter: { name: string, value: number }) => {
        this.maxChildWeightLoading = false;
        this.maxChildWeight = parameter.value;
      })
      .catch((err) => {
        this.maxChildWeightLoading = false;
        this.ns.error(err.error.display, 4000);
      });
  }

  getTrackUsages() {
    this.trackUsagesLoading = true;
    this.settingsService.getTrackUsages()
      .then((usages: TrackUsage[]) => {
        this.trackUsagesLoading = false;
        this.trackUsages = usages;
      })
      .catch((err) => {
        this.trackUsagesLoading = false;
        this.trackUsagesLoadingErr = true;
      })

  }

  openEditParametersDialog() {
    const dialogRef = this.dialog.open(ManageEditParametersDialogComponent, {
      width: "500px",
      minHeight: "200px",
      maxHeight: "700px",
      data: {
        maxChildWeight: this.maxChildWeight
      }
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.maxChildWeight = result;
      }
      console.log(result)
    });
  }

  openAddUsageDialog() {
    const dialogRef = this.dialog.open(ManageAddUsageDialogComponent, {
      width: "500px",
      minHeight: "200px",
      maxHeight: "700px",
      data: {
        maxChildWeight: this.maxChildWeight
      }
    })

    dialogRef.afterClosed().subscribe(async (result) => {
      let previousLength = this.trackUsages.length;
      let fetchedLength = previousLength

      while (fetchedLength == previousLength) {

        this.getTrackUsages();
        fetchedLength = this.trackUsages.length;
        await new Promise(resolve => setTimeout(resolve, 1000));
      }

    });
  }

  deleteUsage(id: string) {
    this.settingsService.deleteTrackUsage(id)
      .then(() => {
        this.ns.success('Usage deleted', 5000);
        this.trackUsages = this.trackUsages.filter(usage => usage._id !== id);
      })
      .catch(() => {
        this.ns.error('Unable to delete.', 5000);
      });
  }

  ngAfterViewInit() {
    anime({
      targets: ['.container-card', '.productName'],
      opacity: [0, 1],
      duration: 1500,
      delay: (el, i, l) => {
        return i * 150;
      },
    }, 2000);
  }


}

@Component({
  templateUrl: './dialogs/edit-parameter-dialog.html'
})
export class ManageEditParametersDialogComponent implements OnInit {
  public parametersForm: FormGroup;

  ngOnInit(): void {
    this.parametersForm = this.fb.group({
      maximumWeight: [this.data.maxChildWeight, Validators.required]
    });

    // this.parametersForm.get('maximumWeight').setValue(this.data.maxChildWeight);
    return;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { maxChildWeight: string },
    public dialogRef: MatDialogRef<ManageEditParametersDialogComponent>,
    private settingsService: SettingsService,
    private fb: UntypedFormBuilder,
    private ns: NotificationsService,

  ) { }


  submit() {
    if (this.parametersForm.invalid) {
      this.ns.error('All form has to be filled in.', 5000);
      return;
    }

    this.settingsService.setLowWeightParameter(this.parametersForm.get('maximumWeight').value)
      .then(() => {
        this.ns.success('Updated weight parameter', 5000);
        this.dialogRef.close(this.parametersForm.get('maximumWeight').value);
      })
      .catch((err) => {
        this.ns.error(err.error.display, 5000);
      })
  }

  onNoClick() {
    this.dialogRef.close();
  }
}

@Component({
  templateUrl: './dialogs/add-usage-dialog.html'
})
export class ManageAddUsageDialogComponent {

  newUsageForm = this.fb.group({
    newUsageName: ['', Validators.required]
  })

  constructor(
    private fb: UntypedFormBuilder,
    private ns: NotificationsService,
    private settingsService: SettingsService,
    private dialogRef: MatDialogRef<ManageAddUsageDialogComponent>,
  ) { }

  onNoClick() {
    this.dialogRef.close();
  }

  submit() {
    if (!this.newUsageForm.valid) {
      this.ns.error('All form has to be filled in.', 5000);
      return;
    }

    this.settingsService.createNewTrackUsage(this.newUsageForm.get('newUsageName').value)
      .then(() => {
        this.ns.success('Created new track usage.', 5000);
        this.dialogRef.close();
      })
      .catch((err) => {
        this.ns.error(err.error.display, 5000);
      });
  }
}