<div class="p-10 rounded-xl overflow-y-scroll">
    <!-- Dialog title -->
    <h5 class="text-lg font-semibold mb-4 text-gray-900" *ngIf="section == 'internal'">Add a new internal note</h5>
    <h5 class="text-lg font-semibold mb-4 text-gray-900" *ngIf="section == 'report'">Add a new report note</h5>
    <div>
        <!-- Form Dialog -->
        <form [formGroup]="noteForm" class="space-y-4">
            <div class="w-full mt-1">
                <div class="mb-4">
                    <textarea #noteTextarea type="text" id="note" formControlName="note" required
                        style="min-height: 11rem;"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
                    </textarea>
                </div>
            </div>
        </form>

        <!-- Dialog Actions -->
        <div class="flex justify-end space-x-2 mt-4">
            <button type="button"
                class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                (click)="onNoClick()">Cancel</button>
            <button type="button"
                class="bg-blue-600 text-white rounded-md px-4 py-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                (click)="addNote()">Add Note</button>
        </div>
    </div>
</div>