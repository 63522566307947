import { OnInit, OnDestroy, ViewChild, Directive, AfterViewInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { Subscription } from "rxjs";
import { FormControl } from "@angular/forms";
import { startWith, map } from "rxjs/operators";

@Directive()
export abstract class BaseTableComponent<T> implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataSource = new MatTableDataSource<T>();
  displayedColumns: string[] = [];
  filterControl = new FormControl();
  filterString = "";

  paginationLength = 0;
  pageIndex = 0;
  pageSize = 20;

  subscriptions = new Subscription();

  ngOnInit(): void {
    this.initializeFilter();
    this.loadData();
  }

  // ngAfterViewInit(): void {
  //   this.dataSource.paginator = this.paginator;
  //   console.log('Page length: ', this.dataSource.paginator.length)
  // }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeFilter(): void {
    this.subscriptions.add(
      this.filterControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          this.applyFilter(value);
          return value;
        })
      ).subscribe()
    );
  }

  applyFilter(filterValue: string): void {
    this.filterString = filterValue.trim().toLowerCase();
    this.loadData();
  }

  protected abstract loadData(): void;

  getRequestParams(): any {
    return {
      skip: this.pageIndex * this.pageSize,
      limit: this.pageSize
    };
  }

  buildFilterQuery(fields: string[]): any {
    const filter = {};
    if (this.filterString) {
      filter['_filterStart'] = this.filterString;
      fields.forEach(field => {
        filter[`${field}_like`] = this.filterString;
      });
      filter['_filterEnd'] = this.filterString;
    }
    return filter;
  }

  handlePageEvent(event: any): void {
    console.log('switch page');
    console.log(event);
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.loadData();
  }

  sortData(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      return;
    }

    const data = this.dataSource.data.slice();
    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a[sort.active], b[sort.active], isAsc);
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
