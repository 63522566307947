import { Component, OnInit, AfterViewInit, Input, AfterViewChecked, AfterContentInit } from '@angular/core';
import anime from 'animejs';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-metric-container',
  templateUrl: './metric-container.component.html',
  styleUrls: ['./metric-container.component.scss']
})
export class MetricContainerComponent implements OnInit, AfterViewInit, AfterContentInit {

  @Input() value: Subject<number>;
  @Input() metricTitle: string;
  @Input() multiVal: boolean;
  @Input() values: Subject<{ value: number, title: string}[]>;

  properties = {
    value: 0,
  };

  private subs = new Subscription();

  constructor() { }

  ngOnInit() {

    if (this.value) {
      this.value.subscribe((val) => {
        anime({
          targets: this.properties,
          value: val,
          easing: 'easeInOutExpo',
          round: 1,
          duration: 2000,
        });
      });
  
    }

  }
  ngAfterViewInit() {

  }

  ngAfterContentInit() {

  }

}
