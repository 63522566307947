<!-- Loading Spinner Card -->
<div class="p-3 rounded-md bg-white flex justify-center items-center" *ngIf="loading">
  <!-- Custom Spinner -->
  <div class="spinner w-5 h-5 border-4 border-gray-300 border-t-blue-500 rounded-full animate-spin"></div>
</div>

<!-- Content Card -->
<div class="p-3 rounded-md bg-white" [class.invisible]="loading">
  <div class="font-bold text-lg mb-4">
    {{title}}
  </div>

  <!-- ngx-charts component -->
  <div class="h-full">
    <ngx-charts-line-chart
      [results]="chartData"
      [legend]="showLegend"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [autoScale]="autoScale"
      [scheme]="colorScheme"
      [roundDomains]="true"
      [showYAxisLabel]="false"
      [showXAxisLabel]="false"
      [showGridLines]="true"
    >
    </ngx-charts-line-chart>
  </div>
</div>
