export const changeDate = "2021-21-11T00:00:00Z";

export interface IChange {
  title: string;
  message: string;
  release: string;
}

export const changes: IChange[] = [
  // Feb 2022
  {
    title: "Audit Specifications",
    message: `Create and manage customer audit specifications.`,
    release: "2.0.0-alpha.0",
  },
  {
    title: "Improved Contact Management",
    message: `Contact management has been improved to allow for easier use.`,
    release: "2.0.0-alpha.0",
  },
  // 10/03/2022
  {
    title: "Comments are back!",
    message: `Comments can now be managed!`,
    release: "2.0.0-alpha.2",
  },
  {
    title: "Bug Fixed",
    message: `Bug fixes have been made.`,
    release: "2.0.0-alpha.2",
  },
  // 10/04/2022
  {
    title: "Restoration of Settings Page",
    message: `Settings page has been restored.`,
    release: "2.0.0-alpha.3",
  },

  // 30/04/2022
  {
    title: 'Moving Audits',
    message: `Audits can now be moved to different contracts.`,
    release: "2.0.1",
  },
  {
    title: 'Updated dashboard routes',
    message: `Loading data to the dashboard was slowing down the service, fetching time series data has been off
    handed to a new service for improved application performance`,
    release: '2.0.1',
  },
  // 17/07/2022
  {
    title: 'New Contact Management UI',
    message: `The contact management UI has been updated to be more user friendly.`,
    release: '2.1.0',
  },
  // 07/01/2022
  // 3.0.0
  {
    title: "Large scale application restructure",
    message: "Updated to the latest version of Angular, and restructured the application to remove unnecessary libraries.",
    release: "3.0.0",
  },
  {
    title: "Updated UI",
    message: "The UI has been updated to look better, be more user friendly, and more consistent.",
    release: "3.0.0",
  },
  {
    title: "New Feature: Archive Contracts",
    message: "Contracts can now be archived, and unarchived allowing the user to clean up unused contracts while maintaining data.",
    release: "3.0.0",
  },
  // 27/03/2023
  // 3.0.1
  {
    title: "User Interface Fixes",
    message: "Fixed a number of UI issues, including note overflow and refactoring delete confirmations.",
    release: "3.0.1",
  },
  {
    title: "Delete Contacts",
    message: "Contacts can now be deleted.",
    release: "3.0.1",
  },

  // 26/03/2023
  // 3.0.2
  {
    title: "Generic report notes",
    message: "Standarised report notes can now be created and added to a report",
    release: "3.0.2",
  },

  // 26/8/2024
  // 3.2.6
  {
    title: "Test for Final Design",
    message: "Restyling everything",
    release: "3.2.6"
  },
  {
    title: "Test for Final Design2",
    message: "Restyling everything2",
    release: "3.2.6"
  }
];
