import { GraphService } from './../../services/graph/graph.service';
import { MatDialogRef } from '@angular/material/dialog';
import { changes } from './../../../../changes';
import { Component, OnInit, EventEmitter } from '@angular/core';
import anime from 'animejs';
import { Config } from 'src/app/config/config';
import package1 from 'package.json';

@Component({
  selector: 'app-whats-new-box',
  templateUrl: './whats-new-box.component.html',
  styleUrls: ['./whats-new-box.component.scss']
})
export class WhatsNewBoxComponent implements OnInit {

  releasedChanges = changes.filter((change) => change.release === package1.version);
  name: string;

  version = package1.version;

  constructor(private dialogRef: MatDialogRef<WhatsNewBoxComponent>, private graph: GraphService) { }

  ngOnInit() {
    this.graph.getUser()
      .then((response: any) => {
        this.name = response.givenName;
      });
  }

  close() {
    localStorage.setItem('wn-closed', new Date().toISOString());
    this.dialogRef.close();
  }

}
