import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface DialogData {
  title: string;
  message: string;
}

@Component({
  selector: "app-delete-confirmation",
  templateUrl: "./delete-confirmation.component.html",
  styleUrls: ["./delete-confirmation.component.scss"],
})
export class DeleteConfirmationComponent {
  @Output() delete = new EventEmitter();
  @Output() cancel = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
    this.cancel.emit();
  }

  onDeleteClick(): void {
    this.dialogRef.close(true);
    this.delete.emit();
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
    this.cancel.emit();
  }
}
