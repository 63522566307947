<div class="p-10 rounded-xl overflow-y-auto" (keydown.enter)="moveAudit()">
    <!-- Dialog Title -->
    <h5 class="text-lg font-semibold mb-4 text-gray-900">Move Audit</h5>

    <!-- Dialog Content -->
    <form [formGroup]="moveAuditForm" class="space-y-4">

        <!-- Move Location -->
        <div class="mb-4 w-full">
            <label for="moveLocation" class="block text-sm font-medium leading-6 text-gray-900">To</label>
            <select id="moveLocation" formControlName="moveLocation"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6"
                required>
                <option value="1">A contract owned by this customer</option>
                <option value="0">A contract owned by another customer</option>
            </select>
        </div>

        <!-- Target Organisation -->
        <div class="mb-4 w-full" *ngIf="moveLocation.value == 0">
            <label for="targetOrg" class="block text-sm font-medium leading-6 text-gray-900">Select Customer</label>
            <select id="targetOrg" formControlName="targetOrg"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6 overflow-y-auto"
                required>
                <option *ngFor="let customer of customers" [value]="customer._id">{{ customer.organisationName }}
                </option>
            </select>
        </div>

        <!-- Target Contract -->
        <div class="mb-4 w-full" *ngIf="targetOrg.value != null || moveLocation.value == 1">
            <label for="targetContract" class="block text-sm font-medium leading-6 text-gray-900">Select
                Contract</label>
            <select id="targetContract" formControlName="targetContract"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6 overflow-y-auto"
                required>
                <option *ngFor="let contract of contracts" [value]="contract._id">{{ contract.name }}</option>
            </select>
        </div>
    </form>

    <!-- Dialog Actions -->
    <div class="flex justify-end space-x-2 mt-4">
        <button type="button"
            class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            (click)="onNoClick()">Cancel</button>
        <button type="button"
            class="bg-blue-600 text-white rounded-md px-4 py-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            (click)="moveAudit()">Move</button>
    </div>
</div>