<div>
  <div class="box">
    <span class="section-title">Contract details</span>
    <span class="spacer"></span>
    <button mat-icon-button (click)="editing = !editing" class="button-small"><ng-icon name="heroPencil" size="18"
        class="text-gray-500"></ng-icon></button>
  </div>
  <table class="info-table">
    <tr>
      <th>Name</th>
      <td>

        <div *ngIf="!editing">
          {{ contract.name }}
        </div>

        <mat-form-field class="w-100" appearance="outline" *ngIf="editing">

          <input matInput [formControl]="contractName">

          <div class="controls">
            <mat-icon class="select" (click)="updateContractName()">check</mat-icon>
            <mat-icon class="cancel" (click)="cancel()">clear</mat-icon>
          </div>
        </mat-form-field>
      </td>

    </tr>
    <tr>
      <th>Customer</th>
      <td class="align-right">{{ contract.customer.organisationName }}</td>
    </tr>
    <tr>
      <th>Reference</th>
      <td class="align-right">{{ contract.reference }}</td>
    </tr>
    <tr>
      <th>Inspection Due</th>
      <td class="align-right">{{ daysToExpiry(contract.lastInspection)}}</td>
    </tr>
    <tr>
      <th>Last Reminder Sent</th>
      <td class="align-right">{{ convertToDaysAgo(contract.lastReminderSent)}}</td>
    </tr>
  </table>
</div>