<div class="p-10 rounded-xl overflow-y-scroll" (keydown.enter)="submit()">
    <!-- Dialog title -->
    <h5 class="text-lg font-semibold mb-4 text-gray-900">Add a generic note to this report</h5>
    <div>

        <!-- Dialog Form -->
        <form [formGroup]='targetGenericNoteForm'>
            <div class="w-full mt-1">

                <!-- Generic Note -->
                <div class="mb-4 w-full">
                    <label for="targetGenericNote" class="block text-sm font-medium leading-6 text-gray-900">Select
                        Generic Note</label>
                    <select id="targetGenericNote" formControlName="targetGenericNote"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6 overflow-y-auto"
                        required>
                        <option *ngFor="let note of notes" [value]="note.note">{{ note.title }}
                        </option>
                    </select>
                </div>

            </div>
        </form>
    </div>

    <!-- Dialog Actions -->
    <div class="flex justify-end space-x-2 mt-8">
        <button type="button"
            class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            (click)="onNoClick()">Cancel</button>
        <button type="button"
            class="bg-blue-600 text-white rounded-md px-4 py-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            (click)="submit()">Add Note</button>
    </div>

</div>