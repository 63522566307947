import { MatSort } from "@angular/material/sort";
import { Contact } from "./../../models/contact.model";
import { ContactsService } from "./../../services/contacts/contacts.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { NotificationsService } from "./../../services/notifications/notifications.service";
import { ContractsService } from "./../../services/contracts/contracts.service";
import { MatTableDataSource } from "@angular/material/table";
import {
    Component,
    OnInit,
    Inject,
    ViewChild,
    OnDestroy,
    AfterViewInit,
    EventEmitter,
    Output,
    ElementRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Contract } from "src/app/models/contract";
import {
    MatDialogRef,
    MatDialog,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
    UntypedFormBuilder,
    Validators,
    UntypedFormControl,
} from "@angular/forms";
import { Subscription, Observable, startWith, map } from "rxjs";
import { MatCheckbox } from "@angular/material/checkbox";
import { Customer } from "@app/models/customer.model";
import { CustomersService } from "@app/services/customers/customers.service";
import { DialogRef } from "@angular/cdk/dialog";
import { DeleteConfirmationComponent } from "../../sysDialogs/delete-confirmation/delete-confirmation.component";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit, OnDestroy {
    @ViewChild("menuTrigger", { static: true }) menuTrigger: MatMenuTrigger;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild("automaticReports") automaticReports: MatCheckbox;
    @ViewChild("annualContract") annualContract: MatCheckbox;
    @ViewChild("enforceAssetTracking") enforceAssetTracking: MatCheckbox;
    @ViewChild("addressMenuTrigger")
    addressMenu: MatMenuTrigger;
    @ViewChild("orgNameMenuTrigger", { static: false })
    orgNameTrigger: MatMenuTrigger;

    public err: string;
    public contact: Contact;

    public assignedCustomerDataSource = new MatTableDataSource<Customer>();
    public displayedColumns = [
        "organisationName",
        "isPrimary",
        "actions"
    ];

    public page = 1;
    public pageSize = 10;
    public paginationLength;

    private subs = new Subscription();
    public filterInput = new UntypedFormControl();
    public contactEmail = new UntypedFormControl();
    public searchFormControl = new UntypedFormControl("");
    public sending = false;
    public sent = false;
    private contactId: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private fb: UntypedFormBuilder,
        private ns: NotificationsService,
        private contactsService: ContactsService,
    ) { }

    ngOnInit() {
        this.getContact();
        this.paginationLength = this.contact.customers.length;
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    getContact() {
        const params$ = this.route.params.subscribe((params) => {
            this.contactsService
                .getContact(params.id)
                .then((contact: any) => {
                    console.log(contact)
                    this.contact = contact;
                    this.contactId = params.id;
                    this.assignedCustomerDataSource.data = contact.customers;
                })
                .catch((err) => {
                    this.ns.error(err.error.display, 5000);
                })
        })
        this.subs.add(params$);
    }

    inviteToCustomerUser() {
        this.contactsService
            .createCustomerUser(this.contact._id)
            .then((res) => {
                this.ns.success("Successfully sent invite.", 5000);
                this.contact.accountActive = true;
            })
            .catch((err) => {
                this.ns.error(
                    err.error.message || "An error occurred sending the invitation.",
                    4000
                );
                return;
            });
    }

    addOrganisation() {
        const dialogRef = this.dialog.open(AddOrganisationToContact, {
            width: "500px",
            minHeight: "200px",
            maxHeight: "700px",
            data: {
                contactId: this.contactId,
            },
        });

        const d$ = dialogRef.afterClosed().subscribe((val) => {
            if (val) {
                this.getContact();
            }
        });

        this.subs.add(d$);
    }

    removeOrganisation(customerId: string) {
        this.contactsService
            .removeContactFromCustomer(this.contact._id, customerId)
            .then(() => {
                this.getContact();
            })
            .catch(() => {
                this.ns.error("Something went wrong.", 4000);
            });
    }

    deleteContact() {
        const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
            data: {
                title: "Delete Contact",
                message: "Are you sure you want to delete this contact?",
            },
        });

        const $d = dialogRef.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.contactsService
                    .deleteContact(this.contact._id)
                    .then(() => {
                        this.ns.success("Deleted Contact", 4000);
                    })
                    .catch((err) => {
                        this.ns.error(err.error.message, 4000);
                    });
            }
        });

        this.subs.add($d);
    }

    resendInvite() {
        this.sending = true;
        this.contactsService
            .resendInvite(this.contactId)
            .then(() => {
                this.sending = false;
                this.sent = true;
                setTimeout(() => {
                    this.sent = false;
                }, 4000);
                this.ns.success("Invite resent.", 4000);
            })
            .catch((err) => {
                this.sending = false;
                this.ns.error("Something went wrong.", 4000);
            });
    }


}

@Component({
    templateUrl: './dialogs/AddOrganisationToContact.component.html'
})
export class AddOrganisationToContact implements OnInit {
    public addOrganisationForm = this.fb.group({
        customerSelection: ["", Validators.required]
    })

    public customers: Customer[];
    public err: string;
    private customerSelection = this.addOrganisationForm.get("customerSelection")
    public filteredOptions: Observable<Customer[]>;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<AddOrganisationToContact>,
        private fb: UntypedFormBuilder,
        private customerService: CustomersService,
        private contactsService: ContactsService,
        private ns: NotificationsService
    ) { }

    ngOnInit() {
        this.getAllCustomers().then(() => {
            this.filteredOptions = this.customerSelection.valueChanges.pipe(
                startWith(""),
                map((value) => {
                    console.log(value);
                    return this._filter(value);
                })
            ) as any;
        });
    }

    private _filter(value: string): Customer[] {
        const filterValue = value.toLowerCase();

        return this.customers.filter(
            (option) =>
                option.organisationName.toLowerCase().includes(filterValue) as any
        );
    }

    displayFn(customer: Customer): string {
        return customer && customer.organisationName
            ? customer.organisationName
            : "";
    }

    getAllCustomers(page = 1, limit = 20, skip = 0) {
        return new Promise(async (resolve, reject) => {
            const pagination = { page, limit, skip };
            const filter = {};
            const sort = {};
            this.err = null;

            try {
                while (true) {
                    const customers: any = await this.customerService.getAllCustomers(pagination, filter, sort);
                    console.log(customers, this.customers);

                    if (this.customers == undefined) {
                        this.customers = customers.data
                    }
                    else {

                        this.customers = [...new Set([...this.customers, ...customers.data])];
                    }

                    console.log(this.customers);

                    if (this.customers.length >= customers.total) {
                        break;
                    }

                    pagination.page += 1; // Move to the next page
                    pagination.skip += 20
                }
                resolve(true);
            } catch (err) {
                this.err = "Unable to load.";
                reject(err);
            }
        });
    }




    onNoClick(): void {
        this.dialogRef.close();
    }

    submit() {
        if (this.customerSelection.value === "") {
            this.ns.error('A customer has to be selected.', 5000)
            return;
        }
        console.log('value', this.customerSelection.value)
        this.contactsService
            .addCustomerToContact(this.data.contactId, this.customerSelection.value)
            .then((result) => {
                this.ns.success("Added customer to contact.", 4000);
                this.dialogRef.close(true);
            })
            .catch((err) => {
                this.ns.error("Something went wrong.", 4000);
            });
    }
}

