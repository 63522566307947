<div class="p-10 rounded-xl" (keydown.enter)="createContact()">
  <!-- Dialog Title -->
  <h5 class="text-lg font-semibold mb-4 text-gray-900">Add a new Customer</h5>

  <!-- Dialog Content -->
  <div>
    <!-- Progress Spinner -->
    <!-- <div class="flex justify-center items-center my-4" *ngIf="loading">
      <svg class="animate-spin h-10 w-10 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none"
        viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C6.48 0 0 6.48 0 12h4z"></path>
      </svg>
    </div> -->

    <!-- Error Alert -->
    <!-- <div *ngIf="err" class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4">
      {{ err }}
    </div> -->

    <!-- Form -->
    <form [formGroup]="newContact" class="space-y-4">
      <div class="w-full mt-1">
        <!-- Organisation Name Field -->
        <div class="mb-4">
          <label class="block text-sm font-medium leading-6 text-gray-900">First
            name</label>
          <input type="text" id="first_name" formControlName="first_name" required
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
        </div>

        <!-- Address Line 1 Field -->
        <div class="mb-4">
          <label class="block text-sm font-medium leading-6 text-gray-900">Last name</label>
          <input type="text" id="last_name" formControlName="last_name" required
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
        </div>

        <!-- Address Line 2 Field -->
        <div class="mb-4">
          <label class="block text-sm font-medium leading-6 text-gray-900">Phone</label>
          <input type="text" id="phone" formControlName="phone"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
        </div>

        <!-- City / Town Field -->
        <div class="mb-4">
          <label class="block text-sm font-medium leading-6 text-gray-900" for="city">Email</label>
          <input type="text" id="email" formControlName="email" required
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
        </div>
      </div>
    </form>
  </div>

  <!-- Dialog Actions -->
  <div class="flex justify-end space-x-2 mt-4">
    <button type="button"
      class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
      (click)="onNoClick()">Cancel</button>
    <button type="button"
      class="bg-blue-600 text-white rounded-md px-4 py-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      (click)="createContact()" [disabled]="newContact.invalid">Create</button>
  </div>

</div>