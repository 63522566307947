<div class="p-10 rounded-xl overflow-y-scroll">
    <!-- Dialog title -->
    <h5 class="text-lg font-semibold mb-4 text-gray-900">Send report to clients</h5>

    <!-- Dialog Info -->
    <div class="mb-4">
        <!-- Report Name -->
        <dl class="divide-y divide-gray-100">
            <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
                <dt class="text-sm text-left font-medium leading-6 text-gray-800">Report Name</dt>
                <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    <p>{{ data.report.name }}</p>
                </dd>
            </div>
        </dl>

        <!-- Report Version -->
        <dl class="divide-y divide-gray-100">
            <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
                <dt class="text-sm text-left font-medium leading-6 text-gray-800">Report Version</dt>
                <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    <p>{{ data.report.version }}</p>
                </dd>
            </div>
        </dl>

        <!-- Author Email -->
        <dl class="divide-y divide-gray-100">
            <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
                <dt class="text-sm text-left font-medium leading-6 text-gray-800">Author Email</dt>
                <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    <p>{{ data.report.authorEmail }}</p>
                </dd>
            </div>
        </dl>
    </div>

    <hr>

    <!-- Send to Contact -->
    <dl class="divide-y divide-gray-100 mt-4">
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
            <dt class="text-sm text-left font-medium leading-6 text-gray-800">Contacts</dt>
            <dd class="mt-1 text-xs text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <div *ngFor="let control of recipientsForm | keyvalue" class="mb-2 flex items-center justify-center">
                    <mat-checkbox color="primary" [formControl]="control.value">
                        Send to {{control.value.contact_name}} ({{control.key}})
                    </mat-checkbox>
                </div>
            </dd>
        </div>
    </dl>

    <!-- Dialog Form -->
    <div class="w-full">
        <!-- Additional Recipients -->
        <div class="mb-4">
            <label class="block text-sm font-medium leading-6 text-gray-900">Additional Recipients</label>
            <input type="email" [formControl]="additionalRecipient" required
                placeholder="Press Enter to add new recipient" (keydown.enter)="addRecipient()"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
        </div>

    </div>

    <!-- Recipients -->
    <dl class="divide-y divide-gray-100 mb-4">
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
            <dt class="text-sm text-left font-medium leading-6 text-gray-800">Recipients</dt>
            <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <p *ngFor="let recip of additionalRecipients" class="flex items-start">
                    <span>{{ recip }}</span>
                    <span class="self-end ml-auto">
                        <button (click)="removeRecipient(recip)"
                            class="hover:bg-gray-200 rounded-full p-2 h-8 w-8 flex items-center justify-center">
                            <ng-icon name="heroTrash" size="18" class="text-gray-500"></ng-icon>
                        </button>
                    </span>
                </p>
                <p *ngIf="additionalRecipients.length == 0">No recipients selected</p>


            </dd>
        </div>
    </dl>


    <hr>

    <h5 class="text-base font-medium my-4 text-gray-700">Proceeding with this action will send this report
        to the contact specficied, are you sure that you want to proceed?</h5>
    <mat-checkbox class="example-margin" [formControl]="confirmation" color="primary"> <span class="body-1">I want to
            send this report.</span></mat-checkbox>

    <!-- Dialog Actions -->
    <div class="flex justify-end space-x-2 mt-4">
        <button type="button"
            class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            (click)="onNoClick()">Cancel</button>
        <div class="rounded-md"
            [ngClass]="{'bg-blue-400 hover:bg-blue-400': !confirmation.value, 'bg-blue-600 hover:bg-indigo-500': confirmation.value}">
            <button type="button"
                class="text-white px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                [disabled]="!confirmation.value" (click)="submit()">
                Send this report</button>
        </div>
    </div>

</div>