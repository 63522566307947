// menu-animations.ts
import { trigger, style, animate, transition } from '@angular/animations';

export const menuAnimations = [
  trigger('menuAnimation', [
    transition(':enter', [
      style({ opacity: 0, transform: 'scale(0.8)' }),
      animate('100ms ease-in', style({ opacity: 1, transform: 'scale(1)' }))
    ]),
    transition(':leave', [
      animate('100ms ease-out', style({ opacity: 0, transform: 'scale(0.8)' }))
    ])
  ])
];
