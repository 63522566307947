import { changeDate, changes } from "./../../changes";
import { WhatsNewBoxComponent } from "./partials/whats-new-box/whats-new-box.component";
import { SessionService } from "./services/session/session.service";
import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from "@azure/msal-angular";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { filter, take, map, shareReplay, takeUntil } from "rxjs/operators";
import { Observable, Subject, Subscription } from "rxjs";

import { MatDialog } from "@angular/material/dialog";
import moment from "moment";
import anime from "animejs";
import { NavigationService } from "./services/navigation/navigation.service";
import { Config } from "./config/config";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
} from "@azure/msal-browser";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "CGAdmin";
  isIframe = false;

  noOfChanges = 0;
  private readonly _destroying$ = new Subject<void>();

  private previousPath = "";

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  public userScopes = [];
  public user: any;

  loginDisplay = false;

  public subscriptions = new Subscription();
  loading = false;


  navigationOptions = [
    {
      name: "Dashboard",
      icon: "heroTableCells",
      link: "/dashboard",
    },
    {
      name: "Customers",
      icon: "heroBuildingOffice2",
      link: "/customers",
    },
    {
      name: "Contacts",
      icon: "heroUserCircle",
      link: "/contacts",
    },
    {
      name: "Products",
      icon: "heroCube",
      link: "/products",
    },
    {
      name: "Audit Specifications",
      icon: "heroShieldCheck",
      link: "/audit-manager",
    },
    {
      name: "App Management",
      icon: "heroDeviceTablet",
      link: "/app-management",
    },
    {
      name: "Audit Management",
      icon: "heroWrench",
      link: "/audit-management",
    },
    {
      name: "Settings",
      icon: "heroCog8Tooth",
      link: "/settings",
    }


  ]

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    public sessionService: SessionService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    public navigation: NavigationService,
  ) { }


  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });


    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.noOfChanges = changes.filter(
      (change) => change.release == Config.version
    ).length;

    const lastChangeDisplay = new Date(localStorage.getItem("wn-closed"));

    if (moment(lastChangeDisplay).isBefore(moment(changeDate))) {
      const dialogRef = this.dialog.open(WhatsNewBoxComponent, {
        minWidth: "200px",
      });
    }
  }

  showChangesModal() {
    const dialogRef = this.dialog.open(WhatsNewBoxComponent, {
      minWidth: "200px",
    });
  }

  navigateBack() {
    this.navigation.back();
  }

  userInScope(scopeName: string) {
    return true;

    if (this.userScopes.includes("SuperAdmin")) {
      return true;
    }
    if (this.userScopes.includes(scopeName)) {
      return true;
    } else {
      return false;
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    this.sessionService.setLoggedInStatus(true);
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService
        .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService
        .loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }

  routeToAudit(url) {
    const newWindow = window.open(url, '_blank');
    newWindow.focus();
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/",
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.subscriptions.unsubscribe();
  }
}
