<!-- Will probably be expanded to tabs at some point, but for now, report notes are all that is necessary. -->
<div class="bg-white">
  <nav class="flex pb-8 sm:px-6 lg:px-8 nav-bar" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-4">
      <li>
        <div>
          <a routerLink="/" class="navIcon text-gray-400 hover:text-gray-500">
            <svg class="h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Home</span>
          </a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a routerLink="/audit-management"
            class="navIcon-last ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Audit
            Management</a>
        </div>
      </li>
    </ol>
  </nav>

  <div class="flex justify-between">
    <div class="flex flex-wrap items-center gap-6 px-4 py-3 sm:flex-nowrap sm:px-6 lg:px-8">
      <h1 class="font-semibold text-xl leading-7 text-gray-900">Audit Management</h1>
    </div>

    <div class="p-1 flex justify-between px-4 py-2 sm:flex-nowrap sm:px-6 lg:px-8">
      <button (click)="openNewReportNoteModal()"
        class="ml-auto flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        <ng-icon name="heroPlus" size="18" class="text-white"></ng-icon>
        New Generic Comment
      </button>
    </div>
  </div>

  <div class="p-3 sm:px-6 lg:px-8">
    <!-- Report notes will be listed here. -->
    <ul>
      <li *ngFor="let note of reportNotes" class="flex py-5">

        <div class="mr-5">
          <ng-icon name="heroChatBubbleBottomCenterText" size="18" class="text-gray-500"></ng-icon>
        </div>

        <div>
          <h6 class="text-sm font-semibold text-gray-900">{{ note.title }}</h6>
          <span class="text-sm font-medium text-gray-700 leading-normal">{{ note.note }}</span>
        </div>


        <span class="self-end ml-auto">
          <button (click)="deleteReportNote(note._id)"
            class="hover:bg-gray-200 rounded-full p-2 h-8 w-8 flex items-center justify-center">
            <ng-icon name="heroTrash" size="18" class="text-gray-500"></ng-icon>
          </button>
        </span>
      </li>
    </ul>
  </div>

</div>