import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "../../config/config";

@Injectable({
  providedIn: "root",
})
export class SearchService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = Config.api.getBaseUrlV2();
  }

  public searchContracts(searchTerm: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.baseUrl + "/search?q=" + searchTerm)
        .subscribe(
          (res: { data: { hits: any[] } }) => {
            resolve(res.data);
          },
          (err) => reject(err)
        );
    });
  }
}
