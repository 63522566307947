import { Component, OnInit, Inject, Output, EventEmitter } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CustomersService } from "../../../../services/customers/customers.service";
import { NotificationsService } from "../../../../services/notifications/notifications.service";

@Component({
  template: `
    <div class="p-6 bg-white rounded-lg shadow-lg">
      <div class="mb-4">
        <h3 class="text-xl font-semibold text-gray-800">Delete Customer</h3>
        <p class="text-gray-600">
          This will irrevocably delete this customer, its constituent contracts
          and inspections.
        </p>
      </div>
      <div class="mb-6">
        <label class="inline-flex items-center">
          <input type="checkbox" class="form-checkbox text-red-600" [formControl]="confirmed">
          <span class="ml-2 text-gray-800">I want to delete this customer.</span>
        </label>
      </div>
      <div class="flex justify-end space-x-4">
        <button class="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300" (click)="dialogRef.close()">Cancel</button>
        <button
          class="px-4 py-2 bg-cg-primary text-white rounded disabled:opacity-50"
          [disabled]="!confirmed.value"
          (click)="delete()"
        >
          Delete
        </button>
      </div>
    </div>
  `,
})
export class ConfirmCustomerDeleteDialogComponent implements OnInit {
  confirmed = new UntypedFormControl();

  constructor(
    private customerService: CustomersService,
    private ns: NotificationsService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<ConfirmCustomerDeleteDialogComponent>
  ) {}

  ngOnInit() {}

  delete() {
    if (this.confirmed.value === false) {
      return;
    }
    this.customerService
      .deleteCustomer(this.data.id)
      .then(() => {
        this.dialogRef.close(true);
        this.ns.success("Successfully deleted customer.", 4000);
      })
      .catch((err) => {
        this.ns.error(err.error.display, 4000);
      });
  }
}
