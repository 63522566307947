<div class="container-fluid mt-0">
  <div class="row mat-elevation-z1 mt-0 p-0 bg-white container-card">
    <nav class="flex pb-8 sm:px-6 lg:px-8 nav-bar" aria-label="Breadcrumb">
      <ol role="list" class="flex items-center space-x-4">
        <li>
          <div>
            <a routerLink="/" class="navIcon text-gray-400 hover:text-gray-500">
              <svg class="h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                  clip-rule="evenodd" />
              </svg>
              <span class="sr-only">Home</span>
            </a>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clip-rule="evenodd" />
            </svg>
            <a routerLink="/audit-manager"
              class=" navIcon-last ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Audit
              Specifications</a>
          </div>
        </li>
      </ol>
    </nav>

    <!-- <div class="p-1 flex justify-between items-center px-4 py-2 sm:flex-nowrap sm:px-6 lg:px-8">
      <div class="gap-6 py-3 sm:flex-nowrap">
        <h1 class="font-semibold text-xl leading-7 text-gray-900 mb-2">Audit Specifications</h1>
        <p>Customers with customized audit specifications.</p>
      </div>
      <button (click)="openCreateAuditSpecDialog()"
        class="h-9 ml-auto flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-1 text-base font-semibold text-white shadow-sm hover:bg-sky-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        <svg class="-ml-0.5 h-4 w-4" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path
            d="M10.75 6.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" />
        </svg>
        New audit
      </button>
    </div> -->

    <div class="flex justify-between items-start px-4 py-3 sm:px-6 lg:px-8">
      <!-- First Child Div: Content -->
      <div class="flex flex-col gap-1">
        <h1 class="font-semibold text-xl leading-7 text-gray-900">Audit Specifications</h1>
        <p>Customers with customized audit specifications.</p>
      </div>

      <!-- Second Child Div: Button -->
      <!-- <div class="flex items-start"> -->

      <!-- </div> -->
    </div>

    <button (click)="openCreateAuditSpecDialog()"
      class="ml-auto mr-6 -mt-4 flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
      <ng-icon name='heroPlus' size='18' class='text-white'></ng-icon>
      New Audit Specification
    </button>

    <div class="p-4 w-100">
      <table mat-table [dataSource]="dataSource" class="w-100">

        <!-- Customer name -->
        <ng-container matColumnDef="customer">
          <th mat-header-cell *matHeaderCellDef
            class="py-3.5 text-left text-sm font-bold text-gray-900 gpl-4 sm:pl-6 lg:px-8 w-30">Customer</th>
          <td mat-cell *matCellDef="let element"
            class="align-center py-3 -pl-2 whitespace-nowrap text-sm text-gray-700 font-medium leading-normal">
            <a routerLink="/audit-manager/{{ element._id }}">{{ element.customer.organisationName }}</a>
          </td>
        </ng-container>


        <!-- Variations -->
        <ng-container matColumnDef="variations">
          <th mat-header-cell *matHeaderCellDef
            class="py-3.5 text-left text-sm font-bold text-gray-900 gpl-4 sm:pl-6 lg:px-8 w-30">No. Variations</th>
          <td mat-cell *matCellDef="let element"
            class="align-center py-3 -pl-2 whitespace-nowrap text-sm text-gray-700 font-medium leading-normal">
            {{element.products.length}}
          </td>
        </ng-container>


        <!-- Active -->
        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef
            class="py-3.5 text-left text-sm font-bold text-gray-900 gpl-4 sm:pl-6 lg:px-8 w-30">Active</th>
          <td mat-cell *matCellDef="let element"
            class="align-center py-3 -pl-2 gap-1 whitespace-nowrap text-sm text-gray-700 font-medium leading-normal">
            <div class="with-icon action-button" [matMenuTriggerFor]="activeMenu">
              <div *ngIf="element.active" class="flex items-center text-green-400 font-medium">
                <ng-icon class="mr-2" size="20" name="heroCheckCircle"></ng-icon>
              </div>
              <div *ngIf="!element.active" class="flex items-center text-red-400 font-medium">
                <ng-icon class="mr-2" name="heroXCircle" size="20"></ng-icon>
              </div>
              <span>{{element.active ? 'Active' : 'Inactive'}}</span>


            </div>

            <mat-menu #activeMenu="matMenu">
              <button mat-menu-item (click)="toggleActive(element._id, element.active)" class="flex-nowrap with-icon ">
                <div *ngIf="!element.active" class="flex items-center text-green-400 font-medium">
                  <ng-icon class="mr-2" size="20" name="heroCheckCircle"></ng-icon>
                  <span class="action-button whitespace-nowrap text-sm text-gray-700 font-medium leading-normal">
                    {{!element.active ? 'Activate' : 'De-activate'}}</span>
                </div>
                <div *ngIf="element.active" class="flex items-center text-red-400 font-medium">
                  <ng-icon class="mr-2" name="heroXCircle" size="20"></ng-icon>
                  <span class="action-button whitespace-nowrap text-sm text-gray-700 font-medium leading-normal">
                    {{!element.active ? 'Activate' : 'De-activate'}}</span>
                </div>

              </button>
            </mat-menu>

          </td>
        </ng-container>


        <!-- Actions -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef
            class="py-3.5 text-left text-sm font-bold text-gray-900 gpl-4 sm:pl-6 lg:px-8 w-30"></th>
          <td mat-cell *matCellDef="let element">
            <button [matMenuTriggerFor]="menu"
              class="action-button hover:bg-gray-200 rounded-full p-2 h-8 w-8 flex items-center justify-center">
              <span class="sr-only">Open options</span>
              <ng-icon name="heroEllipsisVertical" size="18"></ng-icon>
            </button>

            <mat-menu #menu="matMenu" class="inline-flex flex-col min-w-40 bg-white rounded-md">
              <button cdk-menu-item (click)="removeAuditSpec(element._id)"
                class="action-button px-3 py-3 gap-2 text-sm leading-6 text-gray-900 hover:bg-gray-100 flex flex-row items-center justify-center">
                <ng-icon name="heroMinusCircle" size="18"></ng-icon>
                <span>Remove Specification</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


      </table>

    </div>
  </div>
</div>