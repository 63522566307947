<div class="bg-white">
  <mat-toolbar color="primary">
    <span class="subtitle-1">Expiring Contracts</span>
    <span class="spacer"></span>
    <mat-spinner color="accent" [diameter]="30" *ngIf="loading" mode="indeterminate"></mat-spinner>
    <button mat-flat-button (click)="downloadExcelReport()">Download Report</button>
    <mat-spinner [diameter]="30" class="ml-3" mode="indeterminate" color="accent" *ngIf="downloading"></mat-spinner>
  </mat-toolbar>

  <div class="p-2 w-100 scroll-table">
    <table mat-table [dataSource]="tableData" matSort class="w-100">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let item"><a routerLink="/contract/{{item._id}}">{{item.name}}</a></td>
      </ng-container>

      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef> Customer Name </th>
        <td mat-cell *matCellDef="let item">{{item.customer.organisationName}}</td>
      </ng-container>

      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef> ST Code </th>
        <td mat-cell *matCellDef="let item"> {{item.reference}} </td>
      </ng-container>

      <ng-container matColumnDef="daysToExpiry">
        <th mat-header-cell *matHeaderCellDef> Days To Expiry </th>
        <td mat-cell *matCellDef="let item"> {{ daysToExpiry(item.lastInspection)}} </td>
      </ng-container>

      <ng-container matColumnDef="lastReminderSent">
        <th mat-header-cell *matHeaderCellDef> Last Reminder </th>
        <td mat-cell *matCellDef="let item">  {{lastReminderSent(item.lastReminderSent)}} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item">
          <div [matTooltip]=" dateChecker(item.lastReminderSent) ? 'Reminders can only be sent once per week' : '' ">
            <button mat-flat-button class="float-right" (click)="sendManualReminder(item._id)" color="primary" [disabled]="dateChecker(item.lastReminderSent)"
            >Send Reminder</button>
          </div>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['name', 'customerName', 'reference', 'daysToExpiry', 'lastReminderSent', 'actions']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['name', 'customerName', 'reference', 'daysToExpiry',  'lastReminderSent', 'actions']"></tr>
    </table>
    <mat-paginator [length]="100"
    [pageSize]="5"
    [pageSizeOptions]="[5]"></mat-paginator>
  </div>


</div>
