<div class="bg-white overflow-y-auto">
  <!-- Breadcrumbs -->
  <nav class="flex pb-8 sm:px-6 lg:px-8" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-4">
      <li>
        <div>
          <a routerLink="/" class="navIcon text-gray-400 hover:text-gray-500">
            <svg class="h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Home</span>
          </a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a routerLink="/products"
            class="navIcon-last ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Products</a>
        </div>
      </li>
    </ol>
  </nav>

  <div class="flex flex-wrap items-center gap-6 px-4 py-3 sm:flex-nowrap sm:px-6 lg:px-8">
    <h1 class="font-semibold text-xl leading-7 text-gray-900">Products</h1>
  </div>

  <div>
    <div>
      <div class="p-1 flex justify-between px-4 py-2 sm:flex-nowrap sm:px-6 lg:px-8">
        <div class="relative max-w-3xl">
          <div class="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
          <input type="search" id="default-search" [formControl]="filterControl"
            class="block w-full p-2 ps-8 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-cg-primary focus:border-sky-950 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Filter..." required />
        </div>
        <button (click)="openCreateProduct()"
          class="ml-auto flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          <ng-icon name='heroPlus' size='18' class='text-white'></ng-icon>
          New product
        </button>
      </div>

      <!-- Table goes here -->
      <table cdk-table [dataSource]="dataSource" cdkSort (cdkSortChange)="sortData($event)"
        class="min-w-full divide-y divide-gray-300 bg-white mt-4">

        <!-- Model / Name -->
        <ng-container matColumnDef="product">
          <th mat-header-cell *matHeaderCellDef
            class="py-3.5 text-left text-sm font-semibold text-gray-900 gpl-4 sm:pl-6 lg:px-8"> Model </th>
          <td mat-cell *matCellDef="let product" class="align-top py-3 pl-4 sm:pl-6 lg:px-8 border-b border-gray-200">
            <a routerLink="/products/product/{{ product._id }}"
              class="text-sm font-medium text-gray-700 leading-normal">{{ product.product }}</a>
          </td>
        </ng-container>

        <!-- Manufacturer -->
        <ng-container matColumnDef="manufacturer">
          <th mat-header-cell *matHeaderCellDef class="py-3.5 -pl-2 text-left text-sm font-semibold text-gray-900">
            Manufacturer </th>
          <td mat-cell *matCellDef="let product"
            class="align-center py-3 -pl-2 whitespace-nowrap text-sm text-gray-700 font-medium leading-normal border-b border-gray-200">
            {{ product.manufacturer }}
          </td>
        </ng-container>

        <!-- Safety Feature -->
        <ng-container matColumnDef="safetyFeature">
          <th mat-header-cell *matHeaderCellDef class="py-3.5 -pl-2 text-left text-sm font-semibold text-gray-900">
            Safety Feature</th>
          <td mat-cell *matCellDef="let product"
            class="align-center py-3 -pl-2 whitespace-nowrap text-sm text-gray-700 font-medium leading-normal border-b border-gray-200">
            {{ product.safetyFeature }}
          </td>
        </ng-container>

        <!-- Risk Rating -->
        <ng-container matColumnDef="riskRating">
          <th mat-header-cell *matHeaderCellDef class="py-3.5 -pl-2 text-left text-sm font-semibold text-gray-900"> Risk
            Rating </th>
          <td mat-cell *matCellDef="let product"
            class="align-center py-3 -pl-2 whitespace-nowrap text-sm text-gray-700 font-medium leading-normal border-b border-gray-200">

            <div class="with-icon" [matMenuTriggerFor]="ragMenu">

              <div class="flex items-center justify-start">
                <span class="bg-gray-100 h-5 w-5 rounded-full mr-2 text-red-500 flex items-center justify-center"
                  *ngIf="product.ragRiskRating === 'FAIL'">
                  <ng-icon name="heroXCircle" size="18"></ng-icon>
                </span>
                <span class="bg-red-500 h-5 w-5 rounded-full mr-2" *ngIf="product.ragRiskRating === 'RED'"></span>
                <span class="bg-orange-500 h-5 w-5 rounded-full mr-2" *ngIf="product.ragRiskRating === 'AMBER'"></span>
                <span class="bg-green-500 h-5 w-5 rounded-full mr-2" *ngIf="product.ragRiskRating === 'GREEN'"></span>


                <span class="action-button text-sm text-gray-700 font-medium leading-normal">
                  {{ parseRiskRating(product.ragRiskRating) }}
                </span>
              </div>

              <mat-menu #ragMenu="matMenu">
                <button mat-menu-item (click)="changeRagRisk('FAIL', product._id)">
                  <div class="flex items-center">
                    <ng-icon name="heroXCircle" class="mr-2"></ng-icon>
                    <span class="action-button">Fail</span>
                  </div>
                </button>
                <button mat-menu-item (click)="changeRagRisk('RED', product._id)">
                  <div class="flex items-center">
                    <span class="bg-red-500 h-3 w-3 rounded-full mr-2"></span>
                    <span class="action-button">Red</span>
                  </div>
                </button>
                <button mat-menu-item (click)="changeRagRisk('AMBER', product._id)">
                  <div class="flex items-center">
                    <span class="bg-orange-500 h-3 w-3 rounded-full mr-2"></span>
                    <span class="action-button">Amber</span>
                  </div>
                </button>
                <button mat-menu-item (click)="changeRagRisk('GREEN', product._id)">
                  <div class="flex items-center">
                    <span class="bg-green-500 h-3 w-3 rounded-full mr-2"></span>
                    <span class="action-button">Green</span>
                  </div>
                </button>
              </mat-menu>


              <mat-icon matTooltip="This product does not have a risk rating. It will be unusable in audits."
                *ngIf="!product.ragRiskRating" style="color: red" class="align-middle warning-icon">warning</mat-icon>
            </div>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [length]="paginationLength" [pageSize]="20" [pageIndex]="pageIndex"
        (page)="handlePageEvent($event)" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>

</div>