import { InspectionService } from "src/app/services/inspection/inspection.service";
import { PageParams } from "src/app/typings/PageParams";
import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import anime from "animejs";
@Component({
  selector: "app-inspection-requests",
  templateUrl: "./inspection-requests.component.html",
  styleUrls: ["./inspection-requests.component.scss"],
})
export class InspectionRequestsComponent implements OnInit {
  pageParams: PageParams = {
    title: "Inspection Requests",
    description: "",
    docsLink: "",
    actions: [],
  };

  requests = new Subject();

  constructor(private inspectionService: InspectionService) {}

  ngOnInit() {
    this.getRequests();
  }

  getRequests() {
    this.inspectionService
      .getOpenRequests()
      .then((requests) => {
        this.requests.next(requests);
        anime(
          {
            targets: [".container-card", ".productName"],
            opacity: [0, 1],
            duration: 1500,
            delay: (el, i, l) => {
              return i * 150;
            },
          },
          2000
        );
      })
      .catch();
  }
}
