<!-- <h2 class="mat-h2">
    Delete Inspection {{ data.inspection.reference }}
</h2>
<p style="color: red">
    <b>This action is destructive and non-reversible.</b>
</p>
<p>Please confirm by clicking the checkbox below.</p>

<mat-checkbox color="warn" [formControl]="confirmed">I understand.</mat-checkbox>

<div mat-dialog-actions>
    <button mat-flat-button [mat-dialog-close]>Cancel</button>
    <div class="spacer"></div>
    <mat-spinner mode="indeterminate" [diameter]="30" class="mr-3" *ngIf="loading"></mat-spinner>
    <button mat-raised-button color="warn" (click)="deleteInspection()" [disabled]="!confirmed.value">
        <mat-icon>delete_forever</mat-icon>Delete Forever
    </button>
</div> -->


<div class="m-5" (keydown.enter)="deleteInspection()">
    <h2 class="text-xl font-semibold mb-2">Delete Inspection {{ data.inspection.reference }}</h2>
    <p class="text-red-600 font-medium text-lg">
        This action is destructive and non-reversible.
    </p>
    <p class="text-gray-500 text-sm">
        Please confirm by clicking the checkbox below.
    </p>

    <div class="relative flex items-start my-8">
        <div class="flex h-6 items-center">
            <input type="checkbox" class="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-600"
                [formControl]="confirmation" />
        </div>
        <div class="ml-3 text-sm leading-6">
            <label for="candidates" class="font-medium text-gray-900">I understand what I'm doing.</label>
        </div>
    </div>

    <div>
        <div class="flex flex-row justify-between">
            <button [mat-dialog-close]
                class="px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-100 flex flex-row items-center justify-center rounded-md border border-gray-900">
                Cancel
            </button>
            <div class="spacer"></div>
            <mat-spinner mode="indeterminate" [diameter]="30" class="mr-3" *ngIf="loading"></mat-spinner>
            <div class="rounded-md"
                [ngClass]="{'bg-red-400 hover:bg-red-400': !confirmation.value, 'bg-red-600 hover:bg-red-800': confirmation.value}">
                <button cdk-menu-item (click)="deleteInspection()" [disabled]="!confirmation.value"
                    class="px-3 py-1 text-sm leading-6 text-gray-100 flex flex-row items-center justify-center">
                    <ng-icon name="heroTrash" class="text-gray-100 mr-2" size="18"></ng-icon>

                    Delete Contract
                </button>
            </div>
        </div>
    </div>
</div>