import { Config } from "./../../config/config";
import { ApiResponse } from "src/app/typings/response";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { BehaviorSubject, Subject } from "rxjs";
import { JwtHelperService } from "@auth0/angular-jwt";
import { NotificationsService } from "../notifications/notifications.service";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  public loggedInSubject = new BehaviorSubject<boolean>(false);
  public scopes = new BehaviorSubject<string[]>([]);
  public uniqueName: string;

  private baseUrl: string;
  private baseUrlV2: string;

  constructor(
    private authService: MsalService,
    private http: HttpClient,
    private ns: NotificationsService,
    private msalService: MsalService,
  ) {
    this.baseUrl = Config.api.getBaseUrl();
    this.baseUrlV2 = Config.api.getBaseUrlV2();
  }

  public logout() {
    this.authService.logout();
  }

  public login() {
    const isIE =
      window.navigator.userAgent.indexOf("MSIE ") > -1 ||
      window.navigator.userAgent.indexOf("Trident/") > -1;
      this.authService.loginRedirect();
  }

  getUserScopes(accessToken: string) {
    const helper = new JwtHelperService();
    const tokenData = helper.decodeToken(accessToken);
    this.uniqueName = tokenData.unique_name;
    this.http
      .get<ApiResponse>(this.baseUrlV2 + `/scopes/${tokenData.unique_name}`)
      .subscribe(
        (response) => {
          this.scopes.next(response.data);
          console.log(response);
          localStorage.setItem("scopes", JSON.stringify(response.data));
          localStorage.setItem(
            "scope_expiry",
            String(new Date().getTime() + 30000)
          );
        },
        (err) => {
          console.log(err);
          this.ns.error("An error occurred determining scopes.", 4000);
        }
      );
  }

  public setLoggedInStatus(status: boolean) {
    this.loggedInSubject.next(status);
  }

  public userIsInScopes(scopes: string[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (Number(localStorage.getItem("scope_expiry")) > new Date().getTime()) {
        const userScopes = JSON.parse(localStorage.getItem("scopes"));

        if (userScopes.includes("SuperAdmin")) {
          resolve(true);
        }

        if (scopes.some((el) => userScopes.includes(el))) {
          resolve(true);
        } else {
          resolve(false);
        }
      } else {
        const scopes$ = this.scopes.subscribe((userScopes) => {
          if (userScopes.includes("SuperAdmin")) {
            resolve(true);
          }

          if (scopes.some((el) => userScopes.includes(el))) {
            resolve(true);
          } else {
            resolve(false);
          }
        });

        // scopes$.unsubscribe();
      }
    });
  }

  userInScope(scopeName: string) {
    const scopes = localStorage.getItem("scopes");

    if (scopes.includes(scopeName) || scopes.includes("SuperAdmin")) {
      return true;
    } else {
      return false;
    }

    // scopes$.unsubscribe();
  }
}
