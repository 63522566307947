import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import anime from 'animejs';
import { Contract } from 'src/app/models/contract';
import { ContractsService } from 'src/app/services/contracts/contracts.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import moment from 'moment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contract-reminders',
  templateUrl: './contract-reminders.component.html',
  styleUrls: ['./contract-reminders.component.scss']
})
export class ContractRemindersComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  loading = false;
  downloading = false;

  contracts: Contract[] = [];

  tableData = new MatTableDataSource<Contract>()

  subs = new Subscription();

  constructor(private contractsService: ContractsService, private ns: NotificationsService, private dialog: MatDialog) { }

  ngAfterViewInit() {
    anime({
      targets: ['.container-card'],
      opacity: [0, 1],
      duration: 1500,
    }, 2000);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.loading = true;
    this.getExpiringReports();
    this.tableData.paginator = this.paginator;
  }

  getExpiringReports() {
    this.contractsService.getExpiringContracts()
      .then((contracts: any) => {
        this.tableData.data = contracts.data;
        this.loading = false;
      });
    }

  daysToExpiry(date: Date) {
    return moment(date).add(1, 'y').fromNow();
  }

  sendManualReminder(contractId: string) {
    const dialogRef = this.dialog.open(ManualReminderConfirmationDialogComponent, {
      width: '400px',
      height: '200px'
    });

    const sub$ = dialogRef.afterClosed().subscribe(val => {
      if (val) {
        return this.contractsService.sendManualReminder(contractId)
        .then(() => {
          this.ns.success('Reminder email sent!', 4000);
          this.getExpiringReports();
        })
        .catch(err => {
          this.ns.error(err.error.message, 4000);
        });
      } else {
        return;
      }
    })


  }

  dateChecker(date: Date) {
    if (!date) {
      date = moment().subtract(1, 'year').toDate();
    }
    if (moment(date).subtract(1, 'week').isBefore(moment().subtract(1, 'week'))) {
      return false;
    } else {
      return true;
    }
  }

  lastReminderSent(date: Date) {
    if (!date) {
      return 'Never';
    }

    return moment().to(moment(date));
  }

  downloadExcelReport() {
    this.downloading = true;
    this.contractsService.generateContractReport()
      .then(() => {
        this.downloading = false;
        this.ns.success("Downloading report.", 4000);
      })
      .catch(() => {
        this.downloading = false;
        this.ns.error("Something went wrong.", 4000);
      })
  }

}

@Component({
  template: `<h3>Send Reminder </h3>
  <p>Are you sure you wish to send a contract reminder to the client?</p>
  <mat-dialog-actions align="end">

  <button mat-flat-button [mat-dialog-close]>Cancel</button>
  <button mat-flat-button color="primary" (click)="confirm()">Yes</button>

  </mat-dialog-actions>`,
})
export class ManualReminderConfirmationDialogComponent {

  constructor(private dialogRef: MatDialogRef<ManualReminderConfirmationDialogComponent>) {}

  confirm() {
    this.dialogRef.close(true);
  }
}
