import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from "@angular/core";
import { DataService } from "src/app/services/data/data.service";
import { Subject } from "rxjs";

interface IDataSeries {
  x: any[];
  y: any[];
  name: string;
}

export interface IGraphData {
  type: string;
  mode: string;
  title: string;
  series: IDataSeries[];
}

@Component({
  selector: "app-graph-container",
  templateUrl: "./graph-container.component.html",
  styleUrls: ["./graph-container.component.scss"],
})
export class GraphContainerComponent implements OnInit {
  @Input() graphData: Subject<IGraphData>;
  @ViewChild("graphContainer", { static: false }) graphContainer: ElementRef;

  public loading = true;
  public title: string = "Plot Title";

  // Properties for ngx-charts
  public chartData: any[] = [];
  public showLegend = true;
  public showXAxis = true;
  public showYAxis = true;
  public xAxisLabel = 'Date';
  public yAxisLabel = 'Value';
  public autoScale = true;
  public timeline = true;
  public showGridLines = true;
  public colorScheme = {
    domain: ["#2980B9", "#DC5B28", "#1E9EB1", "#FFCE54"]
  };
  public yScaleMin: number = 0;
  public yScaleMax: number = 100; // Adjust this based on your data range

  constructor(private data: DataService) {}

  ngOnInit(): void {
    this.graphData.subscribe((data: IGraphData) => {
      this.title = data.title;
      this.chartData = data.series.map((series: IDataSeries, index: number) => ({
        name: series.name,
        series: series.x.map((x, i) => ({
          name: x,
          value: series.y[i]
        }))
      }));
      this.loading = false;
    });
  }
}
