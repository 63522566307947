<mat-tab-group class="mt-0 pt-0">

  <mat-tab label="Comments" class="h-5">
    <div class="mat-elevation-z1 mt-3 bg-white">

      <div class="p-1 flex items-center justify-between px-4 py-2 sm:flex-nowrap sm:px-6 lg:px-8">
        <div class="relative max-w-3xl">
          <h1 class="font-semibold text-xl leading-7 text-gray-900">Comments</h1>
        </div>

        <div class="p-1 flex justify-between py-2 sm:flex-nowrap">
          <button (click)="addNewComment('com')"
            class="ml-auto flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <ng-icon name="heroPlus" size="18" class="text-white"></ng-icon>
            New Comment
          </button>
        </div>
      </div>

      <div class="p-3 sm:px-6 lg:px-8 pt-0">
        <ul>
          <li *ngFor="let comment of comments" class="flex py-2 items-center">
            <div class="mr-5">
              <ng-icon name="heroChatBubbleBottomCenterText" size="18" class="text-gray-500"></ng-icon>
            </div>

            <div>
              <span class="text-sm font-medium text-gray-700 leading-normal">{{ comment.value }}</span>
            </div>

            <span class="self-end ml-auto">
              <button (click)="deleteComment(comment._id)"
                class="hover:bg-gray-200 rounded-full p-2 h-8 w-8 flex items-center justify-center">
                <ng-icon name="heroTrash" size="18" class="text-gray-500"></ng-icon>
              </button>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Recommendations">
    <div class="mat-elevation-z1 mt-3 bg-white">

      <div class="p-1 flex justify-between px-4 py-2 sm:flex-nowrap sm:px-6 lg:px-8">
        <div class="relative max-w-3xl">
          <h1 class="font-semibold text-xl leading-7 text-gray-900">Recommendations</h1>
        </div>

        <div class="p-1 flex justify-between py-2 sm:flex-nowrap">
          <button (click)="addNewComment('rec')"
            class="ml-auto flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <ng-icon name="heroPlus" size="18" class="text-white"></ng-icon>
            New Recommendation
          </button>
        </div>
      </div>

      <div class="p-3 sm:px-6 lg:px-8 pt-0">
        <ul>
          <li *ngFor="let comment of recommendations" class="flex py-2 items-center">
            <div class="mr-5">
              <ng-icon name="heroChatBubbleBottomCenterText" size="18" class="text-gray-500"></ng-icon>
            </div>

            <div>
              <span class="text-sm font-medium text-gray-700 leading-normal">{{ comment.value }}</span>
            </div>

            <span class="self-end ml-auto">
              <button (click)="deleteComment(comment._id)"
                class="hover:bg-gray-200 rounded-full p-2 h-8 w-8 flex items-center justify-center">
                <ng-icon name="heroTrash" size="18" class="text-gray-500"></ng-icon>
              </button>
            </span>
          </li>
        </ul>
      </div>
    </div>

  </mat-tab>

  <mat-tab label="WAC">
    <div class="mat-elevation-z1 mt-3 bg-white">

      <div class="p-1 flex justify-between px-4 py-2 sm:flex-nowrap sm:px-6 lg:px-8">
        <div class="relative max-w-3xl">
          <h1 class="font-semibold text-xl leading-7 text-gray-900">Work Auto Ccompleted</h1>
        </div>

        <div class="p-1 flex justify-between py-2 sm:flex-nowrap8">
          <button (click)="addNewComment('wac')"
            class="ml-auto flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <ng-icon name="heroPlus" size="18" class="text-white"></ng-icon>
            New Wac
          </button>
        </div>

      </div>

      <div class="p-3 sm:px-6 lg:px-8 pt-0">
        <ul>
          <li *ngFor="let comment of wac" class="flex py-2 items-center">
            <div class="mr-5">
              <ng-icon name="heroChatBubbleBottomCenterText" size="18" class="text-gray-500"></ng-icon>
            </div>

            <div>
              <span class="text-sm font-medium text-gray-700 leading-normal">{{ comment.value }}</span>
            </div>

            <span class="self-end ml-auto">
              <button (click)="deleteComment(comment._id)"
                class="hover:bg-gray-200 rounded-full p-2 h-8 w-8 flex items-center justify-center">
                <ng-icon name="heroTrash" size="18" class="text-gray-500"></ng-icon>
              </button>
            </span>
          </li>
        </ul>
      </div>
    </div>

  </mat-tab>



</mat-tab-group>