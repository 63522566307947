<div class="bg-white px-4 sm:flex-nowrap sm:px-6 lg:px-8">

  <!-- Breadcrumbs -->
  <nav class="flex pb-8" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-4">
      <li>
        <div>
          <a routerLink="/" class="navIcon text-gray-400 hover:text-gray-500">
            <svg class="h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Home</span>
          </a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a routerLink="/customers"
            class="navIcon ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Customers</a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a class="navIcon ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            [routerLink]="['/customers/customer', inspection.contract.customer._id]"
            [state]="{customer: inspection.contract.customer}"
            aria-current="page">{{inspection.contract.customer.organisationName}}</a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a class="navIcon ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            [routerLink]="['/contract', inspection.contract._id]" [state]="{contract: inspection.contract}"
            aria-current="page">{{inspection.contract.name}}</a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a class="navIcon ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            [routerLink]="['/inspections', inspection._id]" [state]="{inspection: inspection}"
            aria-current="page">{{inspection.internalReference}}</a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a class="navIcon-last ml-4 text-sm font-medium text-gray-500 hover:text-gray-700" aria-current="page">Invoice
            Manager</a>
        </div>
      </li>
    </ol>
  </nav>

  <!-- Title -->
  <div class="flex flex-wrap items-center gap-6 sm:flex-nowrap mt-3">
    <h1 class="font-semibold text-xl leading-7 text-gray-900">Invoices</h1>

    <button (click)="openAddInvoiceDialog()"
      class="ml-auto flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
      <ng-icon name='heroPlus' size='18' class='text-white'></ng-icon>
      Add New Line
    </button>
  </div>

  <table cdk-table [dataSource]="invoiceManagerDataSource" cdkSort
    class="table min-w-full divide-y divide-gray-300 bg-white mt-8">

    <!-- Name Column -->
    <ng-container cdkColumnDef="name">
      <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
        class="py-3.5 text-left text-sm font-semibold text-gray-900 w-96"> Name </th>
      <td cdk-cell *cdkCellDef="let element"
        class="align-center py-3 -pl-2 whitespace-nowrap text-sm text-gray-700 font-medium leading-normal border-b border-gray-200">
        <div *ngIf="editingRow == element.position">
          <div [formGroup]="formGroups[element._id]">
            <mat-form-field class="w-75" appearance>
              <mat-label></mat-label>
              <input matInput [placeholder]="element.name" formControlName="name" (keydown.enter)="submit()" class="">
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="!(editingRow == element.position)">
          {{element.name}}
        </div>
      </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container cdkColumnDef="description">
      <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
        class="py-3.5 text-left text-sm font-semibold text-gray-900 w-96">Description</th>
      <td cdk-cell *cdkCellDef="let element"
        class="align-center py-3 -pl-2 whitespace-nowrap text-sm text-gray-700 font-medium leading-normal border-b border-gray-200">
        <div *ngIf="editingRow == element.position">
          <div [formGroup]="formGroups[element._id]">
            <mat-form-field class="w-75" appearance>
              <mat-label></mat-label>
              <input matInput [placeholder]="element.description" formControlName="description"
                (keydown.enter)="submit()" class="">
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="!(editingRow == element.position)">
          {{element.description}}
        </div>
      </td>
    </ng-container>

    <!-- Unit Price Column -->
    <ng-container cdkColumnDef="unitPrice">
      <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
        class="py-3.5 text-left text-sm font-semibold text-gray-900 w-96">Unit Price</th>
      <td cdk-cell *cdkCellDef="let element"
        class="align-center py-3 -pl-2 whitespace-nowrap text-sm text-gray-700 font-medium leading-normal border-b border-gray-200">
        <div *ngIf="editingRow == element.position">
          <div [formGroup]="formGroups[element._id]">
            <mat-form-field class="w-75" appearance>
              <mat-label></mat-label>
              <input matInput placeholder="100" formControlName="unitPrice" (keydown.enter)="submit()"
                class="input-align-right">
            </mat-form-field>
          </div>

        </div>
        <div *ngIf="!(editingRow == element.position)">
          £{{element.unitPrice.toFixed(2)}}
        </div>
      </td>
    </ng-container>

    <!-- Quantity Column -->
    <ng-container cdkColumnDef="quantity">
      <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
        class="py-3.5 text-left text-sm font-semibold text-gray-900 w-96">Quantity</th>
      <td cdk-cell *cdkCellDef="let element"
        class="align-center py-3 -pl-2 whitespace-nowrap text-sm text-gray-700 font-medium leading-normal border-b border-gray-200">
        <div *ngIf="editingRow == element.position">
          <div [formGroup]="formGroups[element._id]">
            <mat-form-field class="w-75" appearance>
              <mat-label></mat-label>
              <input matInput [placeholder]="element.quantity" formControlName="quantity" (keydown.enter)="submit()"
                class="input-align-right">
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="!(editingRow == element.position)">
          {{element.quantity}}
        </div>
      </td>
    </ng-container>

    <!-- Total Price Column -->
    <ng-container cdkColumnDef="amount">
      <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
        class="py-3.5 text-left text-sm font-semibold text-gray-900 w-96">Total Price</th>
      <td cdk-cell *cdkCellDef="let element"
        class="align-center py-3 -pl-2 whitespace-nowrap text-sm text-gray-700 font-medium leading-normal border-b border-gray-200">
        <p> £{{element.amount.toFixed(2)}}</p>
      </td>
      <td cdk-footer-cell *cdkCellDef>
        <p>£{{(total | async).toFixed(2)}}</p>
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container cdkColumnDef="actions">
      <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
        class="py-3.5 text-left text-sm font-semibold text-gray-900 w-96 header-align-right"></th>
      <td cdk-cell *cdkCellDef="let element"
        class="align-center py-3 -pl-2 whitespace-nowrap text-sm text-gray-700 font-medium leading-normal border-b border-gray-200">
        <span class="self-end ml-auto flex">

          <button mat-icon-button (click)="openEditInvoiceDialog(element._id, element)"
            class="button-small hover:bg-gray-200 mt-1"><ng-icon name="heroPencil" size="18"
              class="text-gray-500"></ng-icon></button>

          <button (click)="deleteInvoiceItem(element)"
            class="hover:bg-gray-200 rounded-full p-2 h-8 w-8 flex items-center justify-center">
            <ng-icon name="heroTrash" size="18" class="text-gray-500"></ng-icon>
          </button>
        </span>
      </td>
    </ng-container>

    <!-- Rows -->
    <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"
      [ngClass]="{ 'complete': row.complete, 'incomplete': !row.complete }"></tr>

  </table>


</div>