import { Product } from "./../../models/product.model";
import { ApiResponse } from "src/app/typings/response";
import { Config } from "./../../config/config";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NewProduct } from "src/app/models/newProduct.model";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  public baseUrl: string;
  public baseUrlV2: string;

  constructor(private http: HttpClient) {
    this.baseUrl = Config.api.getBaseUrl();
    this.baseUrlV2 = Config.api.getBaseUrlV2();
  }

  getAllProducts(paginationParams, filterParams, sortParams) {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.baseUrlV2 + "/products", {
          params: { ...paginationParams, ...filterParams, ...sortParams },
        })
        .subscribe({
          next: (r) => resolve(r.data),
          error: (e) => reject(e)
        });
    });
  }

  getProduct(id: string) {
    return new Promise((resolve, reject) => {
      this.http.get<ApiResponse>(this.baseUrlV2 + `/products/${id}`).subscribe({
        next: (r) => resolve(r.data),
        error: (e) => reject(e)
      });
    });
  }

  /**
   * Edits the any parameters of any product, just pass the new values with the model parameter name.
   * @param id
   * @param changes
   * @returns
   */
  editProduct(id: string, changes: any) {
    return new Promise((resolve, reject) => {
      this.http
        .patch<ApiResponse>(this.baseUrlV2 + `/products/${id}`, changes)
        .subscribe({
          next: (r) => resolve(r.data),
          error: (e) => reject(e)
        });
    });
  }

  uploadImage(id: string, image: File) {
    return new Promise((resolve, reject) => {
      const payload = new FormData();

      payload.append("file", image, image.name);

      this.http
        .post<ApiResponse>(
          this.baseUrlV2 + `/products/${id}/upload-image`,
          payload
        )
        .subscribe({
          next: (r) => resolve(r.data),
          error: (e) => reject(e)
        });
    });
  }

  createNewProduct(data: NewProduct) {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.baseUrlV2 + "/products", data).subscribe({
        next: (r) => resolve(r.data),
        error: (e) => reject(e)
      });
    });
  }

  deleteProduct(id: string) {
    return new Promise((resolve, reject) => {
      this.http
        .delete<ApiResponse>(this.baseUrlV2 + `/products/${id}`)
        .subscribe({
          next: (r) => resolve(r.data),
          error: (e) => reject(e)
        });
    });
  }
}
