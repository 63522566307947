import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ErrorComponent } from "../../partials/snackbars/error/error.component";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  constructor(private snackBar: MatSnackBar) {}

  success(message: string, duration: number) {
    this.snackBar.open(message, "Dismiss", {
      duration,
      panelClass: ["success-snackbar"],
      verticalPosition: "top",
    });
  }

  error(message: string, duration: number) {
    this.snackBar.open(message, "Dismiss", {
      duration,
      panelClass: ["error-snackbar"],
      verticalPosition: "top",
    });
  }

}
