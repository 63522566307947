import { I } from "@angular/cdk/keycodes";
import { Location } from "@angular/common";
import { Component, OnInit, Inject } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { SafetyInspection } from "src/app/models/safetyInspection";
import { InspectionService } from "src/app/services/inspection/inspection.service";
import { InvoiceService } from "@app/services/invoice/invoice.service";
import { NotificationsService } from "src/app/services/notifications/notifications.service";
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from "@angular/forms";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";


interface TreeNode {
  name: string;
  passed?: boolean;
  children?: any[];
}

export interface IInvoiceLineItem {
  _id: string;
  name: string;
  description: string;
  unitPrice: number;
  quantity: number;
  amount: number;
  created_at?: Date;
  updated_at?: Date;
  position?: number;
}

@Component({
  selector: "app-invoice-manager",
  templateUrl: "./invoice-manager.component.html",
  styleUrls: ["./invoice-manager.component.scss"],
})
export class InvoiceManagerComponent implements OnInit {
  public inspection: SafetyInspection;
  public reports = new BehaviorSubject([]);

  public data: any = new BehaviorSubject<any>([]);

  public invoiceManagerDataSource;
  public displayedColumns: string[] = [
    "name",
    "description",
    "unitPrice",
    "quantity",
    "amount",
    "actions",
  ];
  footer = ["amount"];

  public formGroups: {};

  public editingRow = null;
  public editingCol = null;

  public total = new BehaviorSubject(0);

  constructor(
    private inspectionService: InspectionService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private notifications: NotificationsService,
    private location: Location,
    private invoiceService: InvoiceService
  ) { }

  ngOnInit(): void {
    this.getInspection();

    this.invoiceManagerDataSource = new MatTableDataSource<IInvoiceLineItem[]>();

    this.data.subscribe((data) => {
      const dataWithPosition = data.map((item, index) => {
        item.position = index + 1;
        return item;
      });

      dataWithPosition.forEach((item: IInvoiceLineItem) => {
        const newGroup = new UntypedFormGroup({
          name: new UntypedFormControl(item.name),
          description: new UntypedFormControl(item.description),
          unitPrice: new UntypedFormControl(item.unitPrice),
          quantity: new UntypedFormControl(item.quantity),
        });

        this.formGroups = { ...this.formGroups, [item._id]: newGroup };
      });

      this.total.next(
        dataWithPosition.reduce((acc, curr) => {
          return acc + curr.amount;
        }, 0)
      );

      this.invoiceManagerDataSource.data = dataWithPosition;
      console.log(this.invoiceManagerDataSource.data);
    });
  }

  getInspection() {
    this.route.params.subscribe((params) => {
      this.inspectionService
        .getInspection(params.inspectionId)
        .then((inspection: SafetyInspection) => {
          this.inspection = inspection;
          this.reports.next(inspection.reports);
          this.data.next(inspection.invoice);
        })
        .catch((err) => {
          this.notifications.error(err.error.display, 5000);
          this.location.back();
        });
    });
  }

  openAddInvoiceDialog() {
    const dialogRef = this.dialog.open(AddInvoiceDialog, {
      width: "500px",
      minHeight: "500px",
      maxHeight: "700px",
      data: this.inspection
    });

    const d$ = dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        this.getInspection();
      }
    });
  }

  openEditInvoiceDialog(invoiceId, invoice) {
    const dialog = this.dialog.open(EditInvoiceDialog, {
      width: "500px",
      minHeight: "500px",
      maxHeight: "700px",
      data: {
        invoiceId: invoiceId,
        invoice: invoice,
      }
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.getInspection();
      }
    });
  }

  addLine() {
    this.invoiceService
      .createInvoiceLineItem(this.inspection._id, {
        name: " ",
        description: " ",
        unitPrice: 1,
        quantity: 1,
        amount: 1,
      })
      .then((res: IInvoiceLineItem) => {
        res.position = this.data.value.length + 1;
        this.data.next([...this.data.value, res]);
        this.editingRow = res.position;
      });
  }

  async editRow(element: any) {
    if (this.editingRow === element.position) {
      return;
    }

    if (this.editingRow != null) {
      await this.submit();
    }

    this.editingRow = element.position;
  }

  submit() {
    return new Promise((resolve, reject) => {
      const idOfLineItem = this.invoiceManagerDataSource.data[this.editingRow - 1]._id;

      // Get changed item
      const lineItem: IInvoiceLineItem = this.formGroups[idOfLineItem].value;

      lineItem._id = idOfLineItem;

      this.invoiceService
        .updateInvoiceLineItem(this.inspection._id, lineItem)
        .then((res: IInvoiceLineItem) => {
          const updatedData = this.data.value;
          updatedData[this.editingRow - 1] = res;

          this.data.next(updatedData);

          this.editingRow = null;
        })
        .catch((err) => {
          this.notifications.error(err.error.display, 5000);
        });
    });
  }

  deleteInvoiceItem(item) {
    this.invoiceService
      .deleteInvoiceLineItem(this.inspection._id, item._id)
      .then(() => {
        this.data.next(this.data.value.filter((i) => i._id !== item._id));
      })
      .catch((err) => {
        this.notifications.error(err.error.display, 5000);
      });
  }
}


@Component({
  templateUrl: './dialog/add-invoice-dialog.html'
})
export class AddInvoiceDialog {
  public invoiceForm = this.fb.group({
    name: ["", Validators.required],
    description: ["", Validators.required],
    unitPrice: [1],
    quantity: [1],
  });

  constructor(
    public dialogRef: MatDialogRef<AddInvoiceDialog>,
    private fb: UntypedFormBuilder,
    private ns: NotificationsService,
    private invoiceService: InvoiceService,
    @Inject(MAT_DIALOG_DATA) private inspection: any
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  checkValidInput() {
    let nameValid = this.invoiceForm.get('name').valid
    let descriptionValid = this.invoiceForm.get('description').valid
    let unitPriceValid = this.invoiceForm.get('unitPrice').valid
    let quantityValid = this.invoiceForm.get('quantity').valid

    return nameValid && descriptionValid && unitPriceValid && quantityValid
  }

  submit() {

    if (this.checkValidInput()) {
      let newName = this.invoiceForm.get('name').value;
      let newDescription = this.invoiceForm.get('description').value;
      let newUnitPrice = this.invoiceForm.get('unitPrice').value;
      let newQuantity = this.invoiceForm.get('quantity').value;

      const newItem = {
        name: newName,
        description: newDescription,
        unitPrice: newUnitPrice,
        quantity: newQuantity,
        amount: 1
      }

      this.invoiceService
        .createInvoiceLineItem(this.inspection._id, newItem)
        .then((res: IInvoiceLineItem) => {
          this.dialogRef.close(true);
          this.ns.success('Added new invoice', 4000)
        })
        .catch((err) => {
          this.ns.error(err.error.display, 5000);
        });
    }
    else {
      this.ns.error('All forms have to be filled in.', 5000);
    }
  }
}

@Component({
  templateUrl: './dialog/edit-invoice-dialog.html'
})
export class EditInvoiceDialog {
  public invoiceForm = this.fb.group({
    name: [this.data.invoice.name],
    description: [this.data.invoice.description],
    unitPrice: [this.data.invoice.unitPrice],
    quantity: [this.data.invoice.quantity],
  });


  constructor(
    public dialogRef: MatDialogRef<AddInvoiceDialog>,
    private fb: UntypedFormBuilder,
    private ns: NotificationsService,
    private invoiceService: InvoiceService,
    @Inject(MAT_DIALOG_DATA) private data: { invoiceId, invoice }
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    if (this.invoiceForm.invalid) {
      this.ns.error('All form has to be filled in.', 5000);
      return;
    }
    return new Promise((resolve, reject) => {
      let newName = this.invoiceForm.get('name').value;
      let newDescription = this.invoiceForm.get('description').value;
      let newUnitPrice = this.invoiceForm.get('unitPrice').value;
      let newQuantity = this.invoiceForm.get('quantity').value;

      const newItem = {
        _id: this.data.invoiceId,
        name: newName,
        description: newDescription,
        unitPrice: newUnitPrice,
        quantity: newQuantity,
        amount: 1
      }

      this.invoiceService
        .updateInvoiceLineItem(this.data.invoiceId, newItem)
        .then((res: IInvoiceLineItem) => {
          this.dialogRef.close(true);
          this.ns.success('Updated invoice', 4000)
        })
        .catch((err) => {
          this.ns.error(err.error.display, 5000);
        });
    })
  }
}