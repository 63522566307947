import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-edit-customer-dialog",
  templateUrl: "./edit-customer-dialog.html",
})
export class ManageEditCustomerDialogComponent {
  public editForm: FormGroup = this.fb.group({
    organisationName: [this.data.organisationName, Validators.required],
    address_line_1: [this.data.address.address_line_1, Validators.required],
    address_line_2: [this.data.address.address_line_2],
    town: [this.data.address.town, Validators.required],
    county: [this.data.address.county],
    postcode: [this.data.address.postcode, Validators.required],
  });

  constructor(
    public dialogRef: MatDialogRef<ManageEditCustomerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) { }

  submit(): void {
    if (this.editForm.valid) {
      this.dialogRef.close(this.editForm.value);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
