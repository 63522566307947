<div class="bg-white px-4 sm:flex-nowrap sm:px-6 lg:px-8" *ngIf="contract">

  <!-- Breadcrumbs -->
  <nav class="flex pb-8" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-4">
      <li>
        <div>
          <a routerLink="/" class="navIcon text-gray-400 hover:text-gray-500">
            <svg class="h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Home</span>
          </a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a routerLink="/customers"
            class="navIcon ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Customers</a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a class="navIcon ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            [routerLink]="['/customers/customer', contract.customer._id]" [state]="{customer: contract.customer}"
            aria-current="page">{{contract.customer.organisationName}}</a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a class="navIcon-last ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            aria-current="page">{{contract.name}}</a>
        </div>
      </li>
    </ol>
  </nav>


  <div class="items-center gap-6 mt-3">
    <h1 class="font-semibold text-xl leading-7 text-gray-900">{{contract.name}}</h1>
    <p class="text-sm text-gray-500 mt-2">Created on {{contract.createdAt | date}}, contains
      {{contract.safetyInspections.length}} audits. The most recent audit was on {{contract.lastInspection | date}}.</p>
  </div>

  <div class="grid grid-cols-12">

    <!-- Contract Info -->
    <div class="col-span-6 mt-6">
      <dl>
        <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3 min-h-10">
          <dt class="text-sm font-medium leading-6 text-gray-900">Contract name</dt>
          <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p>{{contract.name}}</p>
          </dd>
        </div>

        <!-- Contract name-->
        <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm font-medium leading-6 text-gray-900">Customer</dt>
          <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <a [routerLink]="['/customers/customer', contract.customer._id]" [state]="{customer: contract.customer}"
              class="font-medium text-blue-600 hover:text-blue-800">{{contract.customer.organisationName}}</a>
          </dd>
        </div>

        <!-- Inspection Due -->
        <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm font-medium leading-6 text-gray-900">Inspection due</dt>
          <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p>{{ daysToExpiry(contract.lastInspection)}}</p>
          </dd>
        </div>

        <!-- Last reminder sent -->
        <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm font-medium leading-6 text-gray-900">Last reminder sent</dt>
          <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p>{{ convertToDaysAgo(contract.lastReminderSent)}}</p>
          </dd>
        </div>

        <!-- Contact -->
        <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm font-medium leading-6 text-gray-900">Contact</dt>
          <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <div class="flex">
              <div>
                <p>{{contract.primaryContact.name_first}} {{contract.primaryContact.name_last}}</p>
                <p class="text-xs">{{contract.primaryContact.email}}</p>
              </div>
            </div>
          </dd>
        </div>

        <!-- Edit -->
        <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-3">
          <dt class="text-sm font-medium leading-6 text-gray-900">Edit</dt>
          <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex flex-col">
            <button type="button" (click)="openManageContractContact()"
              class="action-button inline-flex items-center  border border-transparent text-sm leading-4 font-medium rounded-md text-cg-primary bg-cg-primary-light hover:bg-cg-primary-dark focus:outline-none focus:shadow-outline-cg-primary active:bg-cg-primary-dark transition ease-in-out duration-150">
              <ng-icon name="heroUserCircle" class="mr-1"></ng-icon>
              <span>Manage contacts</span>
            </button>
            <button type="button" (click)="openManageEditDialog()"
              class="action-button inline-flex items-center  border border-transparent mt-1 text-sm leading-4 font-medium rounded-md text-cg-primary bg-cg-primary-light hover:bg-cg-primary-dark focus:outline-none focus:shadow-outline-cg-primary active:bg-cg-primary-dark transition ease-in-out duration-150">
              <ng-icon name="heroPencil" class="mr-1"></ng-icon>
              <span>Edit contract info</span>
            </button>
          </dd>
        </div>

      </dl>
    </div>

  </div>

  <div class="relative mt-8">
    <div class="absolute inset-0 flex items-center" aria-hidden="true">
      <div class="w-full border-t border-gray-300"></div>
    </div>
  </div>

  <!-- Audit -->
  <div>
    <div>
      <div class="flex items-center justify-between py-8 sm:flex-nowrap">
        <h3 class="text-lg font-semibold leading-7 text-gray-900">Audits</h3>
        <button (click)="openRaiseInspectionDialog()"
          class="ml-auto flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          <ng-icon name='heroPlus' size='18' class='text-white'></ng-icon>
          New Audit
        </button>
      </div>

      <table cdk-table [dataSource]="auditTableDataSource" cdkSort
        class="table min-w-full divide-y divide-gray-300 bg-white">
        <!-- Sale Order Column -->
        <ng-container cdkColumnDef="salesOrder">
          <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
            class="py-3.5 text-left text-sm font-semibold text-gray-900 w-96">Sales Order</th>
          <td cdk-cell *cdkCellDef="let audit" class="align-top py-3 text-sm font-medium text-gray-700 leading-normal">
            <a routerLink="/inspections/{{audit._id}}" class="text-sm font-medium text-gray-700 leading-normal">{{
              audit.internalReference }}</a>
          </td>
        </ng-container>

        <!-- Reference Column -->
        <ng-container cdkColumnDef="reference">
          <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
            class="py-3.5 text-left text-sm font-semibold text-gray-900">Reference</th>
          <td cdk-cell *cdkCellDef="let audit" class="align-top py-3 text-sm font-medium text-gray-700 leading-normal">
            <p> {{trimLongReference(audit.reference)}}</p>
          </td>
        </ng-container>

        <!-- Auditor Column -->
        <ng-container cdkColumnDef="auditor">
          <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
            class="py-3.5 text-left text-sm font-semibold text-gray-900">Auditor</th>
          <td cdk-cell *cdkCellDef="let audit" class="align-top py-3 text-sm font-medium text-gray-700 leading-normal">
            <p> {{audit.auditor || "Not set"}} </p>
          </td>
        </ng-container>

        <!-- Inspection Date Column -->
        <ng-container cdkColumnDef="date">
          <th cdk-header-cell *cdkHeaderCellDef class="py-3.5 text-left text-sm font-semibold text-gray-900">
            Inspection Date</th>
          <td cdk-cell *cdkCellDef="let audit" class="align-top py-3 text-sm font-medium text-gray-700 leading-normal">
            <p>{{audit.date| date}}</p>
          </td>
        </ng-container>

        <!-- Type Column -->
        <ng-container cdkColumnDef="abhoc">
          <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
            class="py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
          <td cdk-cell *cdkCellDef="let audit" class="align-top py-3 text-sm font-medium text-gray-700 leading-normal">
            <div class="flex items-center justify-start">
              <span class="bg-gray-500 h-5 w-5 rounded-full mr-2"
                *ngIf="(audit.adhoc && !audit.auditType) || audit.auditType === 'AUDIT'"></span>
              <span class="bg-orange-500 h-5 w-5 rounded-full mr-2"
                *ngIf="(!audit.adhoc && !audit.auditType) || audit.auditType === 'INSTALLATION'"></span>
              <span class="bg-red-500 h-5 w-5 rounded-full mr-2" *ngIf="audit.auditType === 'PRODUCT_TESTING'">
              </span>

              <span class="text-sm text-gray-700 font-medium leading-normal"
                *ngIf="(audit.adhoc && !audit.auditType) || audit.auditType === 'AUDIT'">Audit</span>
              <span class="text-sm text-gray-700 font-medium leading-normal"
                *ngIf="(!audit.adhoc && !audit.auditType) || audit.auditType === 'INSTALLATION'">Installation</span>
              <span class="text-sm text-gray-700 font-medium leading-normal"
                *ngIf="audit.auditType === 'PRODUCT_TESTING'">Product Testing
              </span>
            </div>
          </td>
        </ng-container>

        <!-- Created At Column -->
        <ng-container cdkColumnDef="createdAt">
          <th cdk-header-cell *cdkHeaderCellDef class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Created Date</th>
          <td cdk-cell *cdkCellDef="let audit" class="align-top py-3 text-sm font-medium text-gray-700 leading-normal">
            <span class="ml-3">{{audit.createdAt | date}}</span>
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container cdkColumnDef="actions">
          <th cdk-header-cell *cdkHeaderCellDef
            class="py-3.5 text-left text-sm font-bold text-gray-900 gpl-4 sm:pl-6 lg:px-8 w-30">Actions</th>
          <td cdk-cell *cdkCellDef="let audit" class="gpl-4 sm:pl-6 lg:px-8 w-30">
            <button [matMenuTriggerFor]="menu"
              class="hover:bg-gray-200 rounded-full p-2 h-8 w-8 flex items-center justify-center ml-2 mt-2">
              <span class="sr-only">Open options</span>
              <ng-icon name="heroEllipsisVertical" size="18" class="text-gray-500"></ng-icon>
              <!-- <ng-icon name="heroEllipsisVertical" size="18" class="text-gray-500"></ng-icon> -->
            </button>

            <mat-menu #menu="matMenu" class="inline-flex flex-col min-w-40 bg-white rounded-md">
              <button cdk-menu-item (click)="openMoveAuditDialog(audit._id)"
                class="action-button px-3 py-3 gap-2 text-sm leading-6 text-gray-900 hover:bg-gray-100 flex flex-row items-center justify-center">
                <ng-icon name="heroArrowRightEndOnRectangle" size="18"></ng-icon>
                <span>Move Inspection</span>
              </button>

            </mat-menu>
          </td>
        </ng-container>

        <!-- Rows -->
        <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
        <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"
          [ngClass]="{ 'bg-green-50': row.complete, 'bg-red-50': !row.complete }"></tr>

      </table>
    </div>
  </div>
</div>