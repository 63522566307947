import { ApiResponse } from "src/app/typings/response";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "src/app/config/config";
import { UntypedFormGroup } from "@angular/forms";
import { stringify } from "querystring";
import { rword } from "rword";
import * as bcrypt from "bcrypt";

@Injectable({
  providedIn: "root",
})
export class CustomersService {
  public baseUrl: string;
  public baseUrlV2: string;

  constructor(private http: HttpClient) {
    this.baseUrl = Config.api.getBaseUrl();
    this.baseUrlV2 = Config.api.getBaseUrlV2();
  }

  /**
   * get
   */
  getAllCustomers(paginationParams, filterParams, sortParams) {
    return new Promise((resolve, reject) => {
      this.http.get<ApiResponse>(this.baseUrlV2 + "/customers", {params: {...paginationParams, ...filterParams, ...sortParams}}).subscribe(
        (res) => resolve(res.data),
        (err) => reject(err)
      );
    });
  }

  getCustomer(id: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.baseUrlV2 + "/customers/" + id)
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  createCustomer(form: UntypedFormGroup) {
    return new Promise((resolve, reject) => {
      const payload = {
        organisationName: form.get("organisationName").value,
        address_line_1: form.get("address_line_1").value,
        address_line_2: form.get("address_line_2").value,
        town: form.get("city").value,
        postcode: form.get("postcode").value,
        enforceAssetIDOnTracks: form.get("enforceAssetIDOnTracks").value,
      };

      this.http
        .post<ApiResponse>(this.baseUrlV2 + "/customers", payload)
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }


  editCustomer(customerId: string, changes: any) {
    return new Promise((resolve, reject) => {
      this.http
        .patch<ApiResponse>(
          this.baseUrlV2 + `/customers/${customerId}`,
          { ...changes }
        )
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  updateAddress(customerId: string, address: any) {
    return new Promise((resolve, reject) => {
      this.http
        .patch<ApiResponse>(
          this.baseUrlV2 + `/customers/${customerId}/address`,
          address
        )
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  getCustomerPortalUsers(customerId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(
          this.baseUrl + `/customers/customer/${customerId}/portal-users`
        )
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  getCustomerCount() {
    return new Promise((resolve, reject) => {
      this.http.get<ApiResponse>(this.baseUrlV2 + `/customers/count`).subscribe(
        (res) => resolve(res.data),
        (err) => reject(err)
      );
    });
  }

  deleteCustomer(id: string) {
    return new Promise((resolve, reject) => {
      this.http
        .delete<ApiResponse>(this.baseUrlV2 + `/customers/${id}`)
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }
}
