import { ApiResponse } from "src/app/typings/response";
import { Config } from "src/app/config/config";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = Config.api.getBaseUrlV2();
  }

  /**
   * Fetches the child weight maximum value.
   * @returns
   */
  getLowWeightParameter() {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.baseUrl + `/settings/MaxChildWeight`)
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  /**
   * Sets the low weight parameter
   * @param value The new weight parameter.
   * @returns
   */
  setLowWeightParameter(value: number) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(this.baseUrl + `/settings/MaxChildWeight`, { value })
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  /**
   * Get list of track usages
   */
  getTrackUsages() {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.baseUrl + `/settings/track-usages`)
        .subscribe(
          (res) => {
            console.log(res);
            resolve(res.data);
          },
          (err) => reject(err)
        );
    });
  }

  createNewTrackUsage(value: string) {
    return new Promise((resolve, reject) => {
      const payload = {
        value,
        active: true,
      };

      this.http
        .post<ApiResponse>(this.baseUrl + `/settings/track-usages`, payload)
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  deleteTrackUsage(id: string) {
    return new Promise((resolve, reject) => {
      this.http
        .delete<ApiResponse>(this.baseUrl + `/settings/track-usages/${id}`)
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }
}
