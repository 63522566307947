<div class="p-10 rounded-xl overflow-y-auto" (keydown.enter)="addProduct()">
    <!-- Dialog Title -->
    <h5 class="text-lg font-semibold mb-4 text-gray-900">Add a Product to Specification</h5>

    <!-- Dialog Content -->
    <form [formGroup]="addProductToSpecForm" class="space-y-4">
        <div class="w-full mt-1">
            <!-- Product= -->
            <div class="mb-4">
                <label class="block text-sm font-medium leading-6 text-gray-900">Select a Product</label>
                <select id="productId" formControlName="productId"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6 overflow-y-auto"
                    required>
                    <option *ngFor="let product of products" [value]="product._id">{{ product.product }}
                    </option>
                </select>
            </div>

            <div class="mb-4 w-full">
                <label for="listbox-label" class="block text-sm font-medium leading-6 text-gray-900">Assigned to</label>
                <div class="relative mt-2">
                    <button type="button"
                        class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label"
                        (click)="toggleDropdown()">

                        <div *ngIf="selectedRagRiskRating == 'Fail'" class="flex">
                            <span class="h-5 w-5 rounded-full mr-2 flex items-center justify-center">
                                <ng-icon name="heroXCircle" size="18"></ng-icon>
                            </span>
                            <span class="text-sm text-gray-700 font-medium leading-normal">
                                Fail
                            </span>
                        </div>

                        <div *ngIf="selectedRagRiskRating == 'Red'" class="flex">
                            <span class="bg-red-500 h-5 w-5 rounded-full mr-2 flex items-center justify-center">
                            </span>
                            <span class="text-sm text-red-500 font-medium leading-normal">
                                Red
                            </span>
                        </div>

                        <div *ngIf="selectedRagRiskRating == 'Amber'" class="flex">
                            <span class="bg-orange-500 h-5 w-5 rounded-full mr-2 flex items-center justify-center">
                            </span>
                            <span class="text-sm text-orange-500 font-medium leading-normal">
                                Amber
                            </span>
                        </div>

                        <div *ngIf="selectedRagRiskRating == 'Green'" class="flex">
                            <span class="bg-green-500 h-5 w-5 rounded-full mr-2 flex items-center justify-center">
                            </span>
                            <span class="text-sm text-green-500 font-medium leading-normal">
                                Green
                            </span>
                        </div>

                        <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                            <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor"
                                aria-hidden="true">
                                <path fill-rule="evenodd"
                                    d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                                    clip-rule="evenodd"></path>
                            </svg>
                        </span>
                    </button>

                    <ul *ngIf="dropdownOpen"
                        class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                        tabindex="-1" role="listbox" aria-labelledby="listbox-label">
                        <li *ngFor="let ragRiskRating of ragRiskRatingList" (click)="setSelected(ragRiskRating)"
                            class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900">
                            <div class="flex items-center">
                                <div *ngIf="ragRiskRating == 'Fail'" class="flex">
                                    <span
                                        class="bg-gray-100 h-5 w-5 rounded-full mr-2 flex items-center justify-center">
                                        <ng-icon name="heroXCircle" size="18"></ng-icon>
                                    </span>
                                    <span class="text-sm text-gray-700 font-medium leading-normal">
                                        Fail
                                    </span>
                                </div>

                                <div *ngIf="ragRiskRating == 'Red'" class="flex">
                                    <span class="bg-red-500 h-5 w-5 rounded-full mr-2 flex items-center justify-center">
                                    </span>
                                    <span class="text-sm text-red-500 font-medium leading-normal">
                                        Red
                                    </span>
                                </div>

                                <div *ngIf="ragRiskRating == 'Amber'" class="flex">
                                    <span
                                        class="bg-orange-500 h-5 w-5 rounded-full mr-2 flex items-center justify-center">
                                    </span>
                                    <span class="text-sm text-orange-500 font-medium leading-normal">
                                        Amber
                                    </span>
                                </div>

                                <div *ngIf="ragRiskRating == 'Green'" class="flex">
                                    <span
                                        class="bg-green-500 h-5 w-5 rounded-full mr-2 flex items-center justify-center text-sm text-green-500 font-medium leading-normal">
                                    </span>
                                    <span class="">
                                        Green
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>




        </div>
    </form>

    <!-- Dialog Actions -->
    <div class="flex justify-end space-x-2 mt-8">
        <button type="button"
            class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            (click)="onNoClick()">Cancel</button>
        <button type="button"
            class="bg-blue-600 text-white rounded-md px-4 py-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            (click)="addProduct()">Add</button>
    </div>
</div>