<div class="bg-white px-4 py-0 sm:flex-nowrap sm:px-6 lg:px-8">

  <!-- Breadcrumbs -->
  <nav class="flex pb-8" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-4">
      <li>
        <div>
          <a routerLink="/" class="text-gray-400 hover:text-gray-500 navIcon">
            <svg class="h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Home</span>
          </a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a routerLink="/audit-manager"
            class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 navIcon">Audit Specifications</a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 navIcon-last"
            aria-current="page">{{auditSpec.customer.organisationName}}</a>
        </div>
      </li>
    </ol>
  </nav>

  <div class="mt-3">
    <div class="flex justify-between sm:flex-nowrap">
      <div class="flex flex-wrap items-center gap-6 sm:flex-nowrap">
        <h1 class="font-semibold text-xl leading-7 text-gray-900">Audit Specification Editor</h1>
      </div>
    </div>

    <div class="mt-4 grid grid-cols-12">

      <!-- Non compliant Cords & Battens -->
      <div class="col-span-12 mt-6 border-t">
        <dl>
          <div class="bg-white grid grid-cols-3 items-center gap-8 min-h-24">
            <dt class="text-sm font-bold leading-6 text-gray-900">
              <p>Non-Compliant Cords</p>
              <p class="text-gray-700 font-medium">Non comliant cords maximum allowed area.</p>
            </dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <div class="with-icon" [matMenuTriggerFor]="cordsMenu">

                <div class="flex items-center justify-start">
                  <span class="h-5 w-5 rounded-full mr-2 flex items-center justify-center"
                    *ngIf="auditSpec.nonCompCordsMaxArea === 'FAIL'">
                    <ng-icon name="heroXCircle" size="18"></ng-icon>
                  </span>
                  <span class="bg-red-500 h-5 w-5 rounded-full mr-2"
                    *ngIf="auditSpec.nonCompCordsMaxArea === 'RED'"></span>
                  <span class="bg-orange-500 h-5 w-5 rounded-full mr-2"
                    *ngIf="auditSpec.nonCompCordsMaxArea === 'AMBER'"></span>
                  <span class="bg-green-500 h-5 w-5 rounded-full mr-2"
                    *ngIf="auditSpec.nonCompCordsMaxArea === 'GREEN'"></span>


                  <span class="action-button text-sm text-gray-700 font-medium leading-normal">
                    {{ parseRiskRating(auditSpec.nonCompCordsMaxArea) }}
                  </span>
                </div>

                <mat-menu #cordsMenu="matMenu">
                  <button mat-menu-item (click)="updateNonCompCordsMaxArea('FAIL')">
                    <div class="flex items-center">
                      <ng-icon name="heroXCircle" class="mr-2"></ng-icon>
                      <span class="action-button">Fail</span>
                    </div>
                  </button>
                  <button mat-menu-item (click)="updateNonCompCordsMaxArea('RED')">
                    <div class="flex items-center">
                      <span class="bg-red-500 h-3 w-3 rounded-full mr-2"></span>
                      <span class="action-button">Red</span>
                    </div>
                  </button>
                  <button mat-menu-item (click)="updateNonCompCordsMaxArea('AMBER')">
                    <div class="flex items-center">
                      <span class="bg-orange-500 h-3 w-3 rounded-full mr-2"></span>
                      <span class="action-button">Amber</span>
                    </div>
                  </button>
                  <button mat-menu-item (click)="updateNonCompCordsMaxArea('GREEN')">
                    <div class="flex items-center">
                      <span class="bg-green-500 h-3 w-3 rounded-full mr-2"></span>
                      <span class="action-button">Green</span>
                    </div>
                  </button>
                </mat-menu>
              </div>
            </dd>
          </div>

          <div class="bg-white grid grid-cols-3 items-center gap-8 min-h-24 -mt-4">
            <dt class="text-sm font-bold leading-6 text-gray-900">
              <p>Non-Compliant Battens</p>
              <p class="text-gray-700 font-medium">Non comliant battens maximum allowed area.</p>
            </dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <div class="with-icon" [matMenuTriggerFor]="battensMenu">

                <div class="flex items-center justify-start">
                  <span class="h-5 w-5 rounded-full mr-2 flex items-center justify-center"
                    *ngIf="auditSpec.nonCompBattenMaxArea === 'FAIL'">
                    <ng-icon name="heroXCircle" size="18"></ng-icon>
                  </span>
                  <span class="bg-red-500 h-5 w-5 rounded-full mr-2"
                    *ngIf="auditSpec.nonCompBattenMaxArea === 'RED'"></span>
                  <span class="bg-orange-500 h-5 w-5 rounded-full mr-2"
                    *ngIf="auditSpec.nonCompBattenMaxArea === 'AMBER'"></span>
                  <span class="bg-green-500 h-5 w-5 rounded-full mr-2"
                    *ngIf="auditSpec.nonCompBattenMaxArea === 'GREEN'"></span>


                  <span class="text-sm text-gray-700 font-medium leading-normal">
                    {{ parseRiskRating(auditSpec.nonCompBattenMaxArea) }}
                  </span>
                </div>

                <mat-menu #battensMenu="matMenu">
                  <button mat-menu-item (click)="updateNonCompBattensMaxArea('FAIL')">
                    <div class="flex items-center">
                      <ng-icon name="heroXCircle" class="mr-2"></ng-icon>
                      <span class="action-button">Fail</span>
                    </div>
                  </button>
                  <button mat-menu-item (click)="updateNonCompBattensMaxArea('RED')">
                    <div class="flex items-center">
                      <span class="bg-red-500 h-3 w-3 rounded-full mr-2"></span>
                      <span class="action-button">Red</span>
                    </div>
                  </button>
                  <button mat-menu-item (click)="updateNonCompBattensMaxArea('AMBER')">
                    <div class="flex items-center">
                      <span class="bg-orange-500 h-3 w-3 rounded-full mr-2"></span>
                      <span class="action-button">Amber</span>
                    </div>
                  </button>
                  <button mat-menu-item (click)="updateNonCompBattensMaxArea('GREEN')">
                    <div class="flex items-center">
                      <span class="bg-green-500 h-3 w-3 rounded-full mr-2"></span>
                      <span class="action-button">Green</span>
                    </div>
                  </button>
                </mat-menu>
              </div>
            </dd>
          </div>
        </dl>
      </div>
    </div>

    <hr>

    <div class="mt-6">

      <div class="flex justify-between sm:flex-nowrap">
        <div class="flex flex-wrap items-center gap-6 sm:flex-nowrap">
          <h1 class="font-semibold text-base leading-7 text-gray-900">Products</h1>
        </div>

        <button (click)="openAddProductDialog()"
          class="ml-auto flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          <ng-icon name='heroPlus' size='18' class='text-white'></ng-icon>
          Add Product
        </button>
      </div>

      <table cdk-table [dataSource]="productDataSource" cdkSort
        class="min-w-full divide-y divide-gray-300 bg-white mt-4 display justify-between">

        <!-- Warning Tolerance -->
        <!-- <ng-container matColumnDef="warning">
          <th mat-header-cell *matHeaderCellDef
            class="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:px-8 w-0"></th>
          <td mat-cell *matCellDef="let element" class="align-top py-3 pl-4 sm:pl-6 lg:px-8 border-b border-gray-200 w-0">
            <div class="with-icon" *ngIf="calcRiskDifferential(element)">
              <mat-icon class="warning"
                matTooltip="The risk tolerance for this client is higher than recommended by Custom Group. This is not advised and will be reflected in the customer report.">
                error</mat-icon>
            </div>
          </td>
        </ng-container> -->

        <!-- Model / Name -->
        <ng-container matColumnDef="productName">
          <th mat-header-cell *matHeaderCellDef class="py-3.5 text-left text-sm font-semibold text-gray-900"> Product
            Name
          </th>
          <td mat-cell *matCellDef="let element" class="align-top py-3 pl-4 border-b border-gray-200">
            <a routerLink="/products/product/{{ element.product._id }}"
              class="text-sm font-medium text-gray-700 leading-normal">{{ element.product.product }}</a>
          </td>
        </ng-container>

        <!-- Custom Risk Rating -->
        <ng-container matColumnDef="ragRiskRating">
          <th mat-header-cell *matHeaderCellDef class="py-3.5 -pl-2 text-left text-sm font-semibold text-gray-900">
            Custom RAG Risk Rating </th>
          <td mat-cell *matCellDef="let element" [ngClass]="calcRiskDifferential(element) ? 'warning-bg': ''"
            class="align-top py-3.5 -pl-2 whitespace-nowrap text-sm text-gray-700 font-medium leading-normal border-b border-gray-200">

            <div class="with-icon" [matMenuTriggerFor]="customRagMenu"
              [matTooltip]="calcRiskDifferential(element) ? 'The risk tolerance for this client is higher than recommended by Custom Group. This is not advised and will be reflected in the customer report.' : ''">

              <div class="flex items-center justify-start">
                <span class="h-5 w-5 rounded-full mr-2 flex items-center justify-center"
                  *ngIf="element.ragRiskRating === 'FAIL'">
                  <ng-icon name="heroXCircle" size="18"></ng-icon>
                </span>
                <span class="bg-red-500 h-5 w-5 rounded-full mr-2" *ngIf="element.ragRiskRating === 'RED'"></span>
                <span class="bg-orange-500 h-5 w-5 rounded-full mr-2" *ngIf="element.ragRiskRating === 'AMBER'"></span>
                <span class="bg-green-500 h-5 w-5 rounded-full mr-2" *ngIf="element.ragRiskRating === 'GREEN'"></span>


                <span class="action-button text-sm text-gray-700 font-medium leading-normal">
                  {{ parseRiskRating(element.ragRiskRating) }}
                </span>
              </div>

              <mat-menu #customRagMenu="matMenu">
                <button mat-menu-item (click)="changeRagRisk('FAIL', element._id)">
                  <div class="flex items-center">
                    <ng-icon name="heroXCircle" class="mr-2"></ng-icon>
                    <span class="action-button">Fail</span>
                  </div>
                </button>
                <button mat-menu-item (click)="changeRagRisk('RED', element._id)">
                  <div class="flex items-center">
                    <span class="bg-red-500 h-3 w-3 rounded-full mr-2"></span>
                    <span class="action-button">Red</span>
                  </div>
                </button>
                <button mat-menu-item (click)="changeRagRisk('AMBER', element._id)">
                  <div class="flex items-center">
                    <span class="bg-orange-500 h-3 w-3 rounded-full mr-2"></span>
                    <span class="action-button">Amber</span>
                  </div>
                </button>
                <button mat-menu-item (click)="changeRagRisk('GREEN', element._id)">
                  <div class="flex items-center">
                    <span class="bg-green-500 h-3 w-3 rounded-full mr-2"></span>
                    <span class="action-button">Green</span>
                  </div>
                </button>
              </mat-menu>


              <mat-icon matTooltip="This product does not have a risk rating. It will be unusable in audits."
                *ngIf="!element.ragRiskRating" style="color: red" class="align-middle warning-icon">warning</mat-icon>
            </div>
          </td>
        </ng-container>

        <!-- Default Risk Rating -->
        <ng-container matColumnDef="cgdRagRiskRating">
          <th mat-header-cell *matHeaderCellDef class="py-3.5 -pl-2 text-left text-sm font-semibold text-gray-900"> Risk
            Rating </th>
          <td mat-cell *matCellDef="let element"
            class="align-top py-3.5 -pl-2 whitespace-nowrap text-sm text-gray-700 font-medium leading-normal border-b border-gray-200">

            <div class="flex items-center justify-start">
              <span class="h-5 w-5 rounded-full mr-2 flex items-center justify-center"
                *ngIf="element.product.ragRiskRating === 'FAIL'">
                <ng-icon name="heroXCircle" size="18"></ng-icon>
              </span>
              <span class="bg-red-500 h-5 w-5 rounded-full mr-2" *ngIf="element.product.ragRiskRating === 'RED'"></span>
              <span class="bg-orange-500 h-5 w-5 rounded-full mr-2"
                *ngIf="element.product.ragRiskRating === 'AMBER'"></span>
              <span class="bg-green-500 h-5 w-5 rounded-full mr-2"
                *ngIf="element.product.ragRiskRating === 'GREEN'"></span>


              <span class="text-sm text-gray-700 font-medium leading-normal">
                {{ parseRiskRating(element.product.ragRiskRating) }}
              </span>
            </div>

            <mat-icon matTooltip="This product does not have a risk rating. It will be unusable in audits."
              *ngIf="!element.product.ragRiskRating" style="color: red"
              class="align-middle warning-icon">warning</mat-icon>
          </td>
        </ng-container>

        <!-- Actions -->
        <ng-container cdkColumnDef="actions">
          <th cdk-header-cell *cdkHeaderCellDef class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"></th>
          <td cdk-cell *cdkCellDef="let element"
            class="py-2 align-top flex justify-center items-center border-b border-gray-200">
            <button [matMenuTriggerFor]="menu"
              class="action-button hover:bg-gray-200 rounded-full p-2 h-8 w-8 flex items-center justify-center">
              <span class="sr-only">Open options</span>
              <ng-icon name="heroEllipsisVertical" size="18"></ng-icon>
            </button>

            <mat-menu #menu="matMenu" class="inline-flex flex-col min-w-40 bg-white rounded-md">
              <button cdk-menu-item (click)="removeProduct(element._id)"
                class="action-button px-3 py-3 gap-2 text-xs leading-6 text-gray-900 hover:bg-gray-100 flex flex-row items-center justify-center">
                <ng-icon name="heroTrash" size="18"></ng-icon>
                <span>Remove Product From Specification</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>


      <p class="text-sm font-medium text-gray-700 leading-normal" *ngIf="productDataSource.data.length == 0">
        There are no products in this audit specification. </p>
    </div>
  </div>
</div>