import { NotificationsService } from './../../../../services/notifications/notifications.service';
import { ContractsService } from './../../../../services/contracts/contracts.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { Contract } from 'src/app/models/contract';
import anime from 'animejs';
import moment from 'moment';

@Component({
  selector: 'app-contract-details-box',
  templateUrl: './contract-details-box.component.html',
  styleUrls: ['./contract-details-box.component.scss']
})
export class ContractDetailsBoxComponent implements OnInit {

  @Input() contract: Contract;

  /**
   * Marks whether the contract is currently being edited.
   */
  public editing = false;

  /**
   * Marks whether the contract is currently processing a request.
   */
  public loading = false;


  /**
   * Form Control to manage editing of the contract name.
   */
  public contractName = new UntypedFormControl('', Validators.required);

  constructor(private contractsService: ContractsService, private ns: NotificationsService) { }

  ngOnInit() {
    this.contractName.setValue(this.contract.name);

    anime({
      targets: ['.container-card'],
      opacity: [0, 1],
      duration: 1500,
      delay: (el, i, l) => {
        return i * 150;
      },
    }, 2000);

  }

  updateContractName() {

    this.contractsService.updateContractName(this.contract._id, this.contractName.value)
      .then(() => {
        this.contract.name = this.contractName.value;
        this.editing = false;
        this.ns.success('Updated Contract Name.', 2000);
      })
      .catch((err) => this.ns.error(err.error.display, 2000));
  }

  cancel() {
    this.editing = false;
    this.contractName.setValue(this.contract.name);
  }

  convertToDaysAgo(date: any) {
    if (!date) {
      return 'Never';
    }
    return moment().to(moment(date));
  }

  daysToExpiry(date: any) {
    return moment().to(moment(date).add(1, 'year'));
  }

}
