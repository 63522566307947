<div class="p-10 rounded-xl overflow-y-auto" (keydown.enter)="submit()">
  <!-- Dialog Title -->
  <h5 class="text-lg font-semibold mb-2 text-gray-900">Create Audit Specification for Customer</h5>
  <p class="text-sm font-medium mb-6 text-gray-500">Choose customer to create custom audit specification. </p>

  <!-- Dialog Content -->
  <form [formGroup]="newAuditSpecForm" class="space-y-4">

    <!-- Target Organisation -->
    <div class="mb-4 w-full">
      <label for="customer" class="block text-sm font-medium leading-6 text-gray-900">Select Customer</label>
      <select id="customer" formControlName="customer"
        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6 overflow-y-auto"
        required>
        <option *ngFor="let customer of customers" [value]="customer._id">{{ customer.organisationName }}
        </option>
      </select>
    </div>

  </form>

  <!-- Dialog Actions -->
  <div class="flex justify-end space-x-2 mt-8">
    <button type="button"
      class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
      (click)="onNoClick()">Cancel</button>
    <button type="button" [disabled]="newAuditSpecForm.invalid"
      class="bg-blue-600 text-white rounded-md px-4 py-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      (click)="submit()">Create</button>
  </div>

</div>