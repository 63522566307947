import { ApiResponse } from "./../../typings/response";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "src/app/config/config";
import { UntypedFormGroup } from "@angular/forms";
import { saveAs } from "file-saver";

@Injectable({
  providedIn: "root",
})
export class ContractsService {
  public baseUrl: string;
  private baseUrlV2;

  constructor(private http: HttpClient) {
    this.baseUrl = Config.api.getBaseUrl();
    this.baseUrlV2 = Config.api.getBaseUrlV2();
  }

  /**
   * Creates a new contract
   * @param form The form unit containing the new contract details.
   */
  createContract(form: UntypedFormGroup) {
    return new Promise((resolve, reject) => {
      const payload = {
        name: form.get("contractName").value,
        customer: form.get("customerId").value,
        primaryContact: form.get("contactId").value,
        lastInspectionDate: form.get("lastInspectionDate").value,
      };
      this.http
        .post<ApiResponse>(this.baseUrlV2 + "/contracts", payload)
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  /**
   * Gets contracts for customer
   * @param customerId The customer ID to fetch contracts from.
   */
  getContractsForCustomer(customerId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(
          this.baseUrlV2 + `/contracts/forCustomer/${customerId}`
        )
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  /**
   * Gets contract
   * @param id The ID of the contract
   * @returns any
   */
  getContract(id: string) {
    return new Promise((resolve, reject) => {
      this.http.get<ApiResponse>(this.baseUrlV2 + "/contracts/" + id).subscribe(
        (res) => resolve(res.data),
        (err) => reject(err)
      );
    });
  }

  /**
   * Updates contract name
   * @param contractId The ID of the target contract.
   * @param name The new name of the contract.
   * @returns Promise
   */
  updateContractName(contractId: string, name: string) {
    return new Promise((resolve, reject) => {
      this.http
        .patch<ApiResponse>(this.baseUrlV2 + `/contracts/${contractId}`, {
          name,
        })
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  /**
   * Update parameters on a contract
   * @param contractId
   * @param changes
   */
  update(contractId: string, changes: any) {
    return new Promise((resolve, reject) => {
      this.http
        .patch<ApiResponse>(
          this.baseUrlV2 + `/contracts/${contractId}`,
          changes
        )
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  /**
   * Gets count of contracts
   * (Mainly used on the dashboard page).
   * @returns Promise
   */
  getContractsCount() {
    return new Promise((resolve, reject) => {
      this.http.get<ApiResponse>(this.baseUrlV2 + "/contracts/count").subscribe(
        (res) => resolve(res.data),
        (err) => reject(err)
      );
    });
  }

  /**
   * Delete contract
   * @param contractId The ID of the contract being deleted.
   * @returns Promise
   */
  deleteContract(contractId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .delete<ApiResponse>(this.baseUrlV2 + `/contracts/${contractId}`)
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  /**
   * Download excel report of expiring contracts.
   */
  generateContractReport() {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.baseUrlV2 + `/reports/contracts`, {
          responseType: "blob",
        })
        .subscribe(
          (res: any) => {
            function s2ab(s) {
              var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
              var view = new Uint8Array(buf); //create uint8array as viewer
              for (var i = 0; i < s.length; i++)
                view[i] = s.charCodeAt(i) & 0xff; //convert to octet
              return buf;
            }

            saveAs(res, `Contract report - ${new Date()}.xlsx`);
            resolve(true);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /**
   * Get table of expiring contracts.
   */
  getExpiringContracts() {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.baseUrlV2 + `/contracts/expiringContracts`)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /**
   * Send manual reminder...
   */
  sendManualReminder(id) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(this.baseUrlV2 + `/contracts/${id}/reminder`, {})
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getAllContracts() {
    return new Promise((resolve, reject) => {
      this.http.get<ApiResponse>(this.baseUrlV2 + `/contracts`).subscribe(
        (res) => resolve(res.data),
        (err) => reject(err)
      );
    });
  }

  getAssociatedContracts(contractId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(
          this.baseUrlV2 +
            `/contracts/contract/${contractId}/getAssociatedContracts`
        )
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  moveContractToNewCustomer(contractId: string, targetCustomerId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(
          this.baseUrlV2 + `/contracts/${contractId}/move/${targetCustomerId}`
        )
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  archiveContract(contractId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(this.baseUrlV2 + `/contracts/${contractId}/archive`, {})
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  unarchiveContract(contractId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(this.baseUrlV2 + `/contracts/${contractId}/unarchive`, {})
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }
}
