import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "../../config/config";
import { ApiResponse } from "../../typings/response";

export interface IInvoiceLineItem {
  _id?: string;
  name: string;
  description: string;
  unitPrice: number;
  quantity: number;
  amount: number;
  created_at?: Date;
  updated_at?: Date;
  position?: number;
}

@Injectable({
  providedIn: "root",
})
export class InvoiceService {
  public baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = Config.api.getBaseUrlV2();
  }

  /**
   * Add an invoice line item.
   * @param invoiceId
   * @param newInvoiceLineItem
   */
  createInvoiceLineItem(
    inspectionId: string,
    newInvoiceLineItem: IInvoiceLineItem
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(
          this.baseUrl + "/invoices/" + inspectionId + "/line-items",
          newInvoiceLineItem
        )
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  /**
   * @param inspectionId
   * @param invoiceLineItem
   * @returns
   */
  updateInvoiceLineItem(
    inspectionId: string,
    invoiceLineItem: IInvoiceLineItem
  ) {
    console.log(inspectionId);
    return new Promise((resolve, reject) => {
      this.http
        .put<ApiResponse>(
          this.baseUrl +
          "/invoices/" +
          inspectionId +
          "/line-items/" +
          invoiceLineItem._id,
          invoiceLineItem
        )
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  /**
   * @param inspectionId
   * @param invoiceLineItemId
   * @returns
   * @memberof InvoiceService
   * @description
   * Delete an invoice line item.
   */
  deleteInvoiceLineItem(inspectionId: string, invoiceLineItemId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .delete<ApiResponse>(
          this.baseUrl +
          "/invoices/" +
          inspectionId +
          "/line-items/" +
          invoiceLineItemId
        )
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }
}
