import { ParametersComponent } from "./pages/parameters/parameters.component";
import { CommentsComponent } from "./pages/comments/comments.component";
import { ScopesComponent } from "./pages/scopes/scopes.component";
import { ScopeGuard } from "./guards/scope.guard";
import { ProductComponent } from "./pages/product/product.component";
import { InspectionComponent } from "./pages/inspection/inspection.component";
import { CustomerComponent } from "./pages/customer/customer.component";
import { CustomersComponent } from "./pages/customers/customers.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { LoggedInGuard } from "./guards/logged-in.guard";
import { NotLoggedInGuard } from "./guards/not-logged-in.guard";
import { SigninComponent } from "./pages/auth/signin/signin.component";
import { ProductsComponent } from "./pages/products/products.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { ContractComponent } from "./pages/contract/contract.component";
import { InspectionRequestsComponent } from "./pages/inspection-requests/inspection-requests.component";
import { ContactsComponent } from "./pages/contacts/contacts.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { InvoiceManagerComponent } from "./pages/invoice-manager/invoice-manager.component";
import { AuditSpecManagerComponent } from "./pages/audit-spec-manager/audit-spec-manager.component";
import { AuditSpecEditorComponent } from "./pages/audit-spec-editor/audit-spec-editor.component";
import { BrowserUtils } from "@azure/msal-browser";
import { AuditManagementComponent } from "./pages/audit-management/audit-management.component";

const routes: Routes = [
  {
    path: "",
    canActivate: [MsalGuard],
    children: [
      {
        path: "",
        canActivate: [MsalGuard],
        children: [
          { path: "dashboard", component: DashboardComponent },

          {
            path: "customers",
            component: CustomersComponent,
          },
          {
            path: "customers/customer/:id",
            component: CustomerComponent,
          },

          {
            path: "contract/:id",
            component: ContractComponent,
          },

          {
            path: "inspections/requests",
            component: InspectionRequestsComponent,
          },
          {
            path: "inspections/:id",
            component: InspectionComponent,
          },

          {
            path: "products",
            component: ProductsComponent,
          },
          {
            path: "products/product/:id",
            component: ProductComponent,
          },

          { path: "app-management", component: CommentsComponent },

          { path: "audit-management", component: AuditManagementComponent },

          { path: "settings", component: ParametersComponent },

          { path: "contacts", component: ContactsComponent },
          {
            path: "contacts/contact/:id",
            component: ContactComponent,
          },
          {
            path: "invoice-manager/:inspectionId",
            component: InvoiceManagerComponent,
          },

          { path: "audit-manager", component: AuditSpecManagerComponent },

          {
            path: "audit-manager/:specId",
            component: AuditSpecEditorComponent,
          },

          { path: "**", redirectTo: "dashboard" },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
