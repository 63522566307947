import { ApiResponse } from "src/app/typings/response";
import { Config } from "./../../config/config";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CommentsService {
  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = Config.api.getBaseUrlV2();
  }

  getComments() {
    return new Promise((resolve, reject) => {
      this.http.get<ApiResponse>(this.baseUrl + "/comments").subscribe(
        (r) => resolve(r.data),
        (e) => reject(e)
      );
    });
  }

  addComment(comment: string, type: string) {
    return new Promise((resolve, reject) => {
      const payload = {
        value: comment,
        type,
      };

      this.http
        .post<ApiResponse>(this.baseUrl + "/comments", payload)
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  deleteComment(commentId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .delete<ApiResponse>(this.baseUrl + `/comments/${commentId}`)
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }
}
