<div class="bg-white px-4 py-0 sm:flex-nowrap sm:px-6 lg:px-8" *ngIf="product">

  <!-- Breadcrumbs -->
  <nav class="flex pb-8" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-4">
      <li>
        <div>
          <a routerLink="/" class="text-gray-400 hover:text-gray-500 navIcon">
            <svg class="h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Home</span>
          </a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a routerLink="/products"
            class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 navIcon">Products</a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 navIcon-last"
            aria-current="page">{{product.product}}</a>
        </div>
      </li>
    </ol>
  </nav>

  <div class="flex justify-between items-center gap-6 mt-3">
    <h1 class="font-semibold text-xl leading-7 text-gray-900">{{product.product}}</h1>

    <button [matMenuTriggerFor]="moreMenu"
      class="hover:bg-gray-200 rounded-full p-2 h-8 w-8 flex items-center justify-center">
      <span class="sr-only">Open options</span>
      <ng-icon name="heroEllipsisVertical" size="18" class="text-gray-500"></ng-icon>
    </button>

    <mat-menu #moreMenu="matMenu" class="inline-flex flex-col min-w-40 bg-white rounded-md">
      <button cdk-menu-item (click)="openDeleteProductDialog()"
        class=" w-full px-3 py-3 gap-2 text-sm leading-6 text-gray-900 hover:bg-gray-100 flex flex-row items-center justify-start">
        <ng-icon name="heroTrash" class="text-red-500 mr-2" size="18"></ng-icon>
        <span>Delete Product</span>
      </button>
    </mat-menu>
  </div>

  <div *ngIf="isProductNotConfigured()" class="mt-6">
    <div class="rounded-md bg-yellow-50 p-4 mt-10">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-yellow-800">Attention needed - product not configured</h3>
          <div class="mt-2 text-sm text-yellow-700">
            <p>Please configure the safety feature parameters for this product below.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-6">
    <div class="grid grid-cols-12">

      <!-- Product Detail -->
      <div class="col-span-12">
        <dl class="w-full">
          <div class="bg-white py-2 sm:grid sm:grid-cols-5 sm:gap-0 min-h-10">
            <dt class="text-base text-left font-semibold leading-7 text-gray-900">Product Details</dt>
            <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-1 sm:mt-0 sm:-ml-1">
              <button (click)="openEditDetailsDialog()" class="button-small hover:bg-gray-200 rounded-full"><ng-icon
                  name="heroPencil" size="18" class="text-gray-500"></ng-icon></button>
            </dd>
          </div>
        </dl>

        <!-- Product Name & Manufacturer -->
        <dl class="w-full">
          <!-- Product Name -->
          <div class="bg-white py-2 sm:grid sm:grid-cols-5 sm:gap-2">
            <dt class="text-sm text-left font-medium leading-6 text-gray-800">Product Name</dt>
            <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-4 sm:mt-0">
              <p>{{ product.product }}</p>
            </dd>
          </div>
          <!-- Manufacturer -->
          <div class="bg-white py-2 sm:grid sm:grid-cols-5 sm:gap-2">
            <dt class="text-sm text-left font-medium leading-6 text-gray-800">Manufacturer</dt>
            <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-4 sm:mt-0">
              <p>{{ product.manufacturer }}</p>
            </dd>
          </div>
        </dl>

        <!-- Safety Feature & Description -->
        <dl class="w-full">
          <!-- Safety Feature -->
          <div class="bg-white py-2 sm:grid sm:grid-cols-5 sm:gap-2">
            <dt class="text-sm text-left font-medium leading-6 text-gray-800">Safety Feature</dt>
            <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-4 sm:mt-0">
              <p>{{ product.safetyFeature }}</p>
            </dd>
          </div>
          <!-- Description -->
          <div class="bg-white py-2 sm:grid sm:grid-cols-5 sm:gap-2">
            <dt class="text-sm text-left font-medium leading-6 text-gray-800">Description</dt>
            <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-4 sm:mt-0">
              <p *ngIf="product.description">{{ product.description }}</p>
              <p *ngIf="!product.description">No description</p>
            </dd>
          </div>
        </dl>

        <!-- <mat-slide-toggle class="mt-2" color="primary" [checked]="isCustom" (change)="onToggleChange()">Allow Pass
          Override
        </mat-slide-toggle> -->
      </div>

      <hr class="mt-6 col-span-12">

      <!-- Product Safety -->
      <div class="col-span-4 mt-4">
        <!-- Product Safety's Title -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-5 sm:gap-0 min-h-10">
          <dt class="text-base text-left font-semibold leading-7 text-gray-900 col-span-3">Product Safety</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-1 sm:mt-0 sm:-ml-1">
            <button [disabled]="!product.isLoadTest" (click)="openEditLoadTestDialog()"
              class="button-small rounded-full"><ng-icon name="heroPencil" size="18"
                [ngClass]="{ 'text-gray-500 hover:bg-gray-200': product.isLoadTest, 'text-gray-200': !product.isLoadTest }"></ng-icon></button>
          </dd>
        </div>

        <!-- Inspection Type -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-5 sm:gap-2">
          <dt class="text-sm text-left font-medium leading-6 text-gray-800 col-span-3">Inspection Type</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p>{{ inspectionType }}</p>
          </dd>
        </div>

        <!-- isLoadTest -->
        <div *ngIf="product && product.isLoadTest">
          <!-- Weight Separation Vertical Minimum (kg) -->
          <div class="bg-white py-2 sm:grid sm:grid-cols-5 sm:gap-2">
            <dt class="text-sm text-left font-medium leading-6 text-gray-800 col-span-3">Weight Separation Vertical
              Minimum (kg)
            </dt>
            <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex">
              <p>{{ product.weightSeparationVerticalMinimum }}Kg</p>

              <div *ngIf="!product.weightSeparationVerticalMinimum">
                <div class="flex-shrink-0">
                  <svg class="h-5 ml-2 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd" />
                  </svg>
                </div>
              </div>

            </dd>
          </div>

          <!-- Weight Separation Vertical Maximum (kg) -->
          <div class="bg-white py-2 sm:grid sm:grid-cols-5 sm:gap-2">
            <dt class="text-sm text-left font-medium leading-6 text-gray-800 col-span-3">Weight Separation Vertical
              Maximum (kg)
            </dt>
            <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex">
              <p>{{ product.weightSeparationVerticalMaximum }}Kg</p>

              <div *ngIf="!product.weightSeparationVerticalMaximum">
                <div class="flex-shrink-0">
                  <svg class="h-5 ml-2 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd" />
                  </svg>
                </div>
              </div>

            </dd>
          </div>

          <!-- Weight Separation Angled Minimum (kg) -->
          <div class="bg-white py-2 sm:grid sm:grid-cols-5 sm:gap-2">
            <dt class="text-sm text-left font-medium leading-6 text-gray-800 col-span-3">Weight Separation Angled
              Minimum (kg)</dt>
            <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex">
              <p>{{ product.weightSeparationAngleMinimum }}Kg</p>

              <div *ngIf="!product.weightSeparationAngleMinimum">
                <div class="flex-shrink-0">
                  <svg class="h-5 ml-2 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
            </dd>
          </div>

          <!-- Weight Separation Angled Minimum (kg) -->
          <div class="bg-white py-2 sm:grid sm:grid-cols-5 sm:gap-2">
            <dt class="text-sm text-left font-medium leading-6 text-gray-800 col-span-3">Weight Separation Angled
              Minimum (kg)</dt>
            <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex">
              <p>{{ product.weightSeparationAngleMaximum }}Kg</p>

              <div *ngIf="!product.weightSeparationAngleMaximum">
                <div class="flex-shrink-0">
                  <svg class="h-5 ml-2 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
            </dd>
          </div>
        </div>

        <!-- isGliderCount -->
        <div *ngIf="product && product.isGliderCount">
          <!-- Blue Gliders -->
          <div class="bg-white py-2 sm:grid sm:grid-cols-2 w-full">

            <button *ngIf="!product.isConfigured" (click)="openEditGliderCountDialog()"
              class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Configure product glider count
            </button>

            <div *ngIf="product.isConfigured" class="w-full">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col"
                      class="px-6 py-3 text-left text-xs font-medium tracking-wider">
                      Glider Type
                    </th>
                    <th scope="col"
                      class="px-6 py-3 text-left text-xs font-medium tracking-wider">
                      Maximum per Track
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <!-- Red Gliders -->
                  <tr *ngIf="product.hasRedGliders">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Red Gliders
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                      {{ product.maximumRedGlidersPerTrack }} per track
                    </td>
                  </tr>

                  <!-- Blue Gliders -->
                  <tr *ngIf="product.hasBlueGliders">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Blue Gliders
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                      {{ product.maximumBlueGlidersPerTrack }} per track
                    </td>
                  </tr>

                  <!-- Grey Gliders -->
                  <tr *ngIf="product.hasGreyGliders">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Grey Gliders
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                      {{ product.maximumGreyGlidersPerTrack }} per track
                    </td>
                  </tr>

                  <!-- Yellow Gliders -->
                  <tr *ngIf="product.hasYellowGliders">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Yellow Gliders
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                      {{ product.maximumYellowGlidersPerTrack }} per track
                    </td>
                  </tr>

                  <!-- White Gliders -->
                  <tr *ngIf="product.hasWhiteGliders">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      White Gliders
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                      {{ product.maximumWhiteGlidersPerTrack }} per track
                    </td>
                  </tr>
                </tbody>
              </table>


            </div>
          </div>
        </div>

        <!-- <hr class="mt-6 col-span-12"> -->
        <div class="col-span-2"></div>

        <!-- Product Pic -->
        <div class="row mt-3 col-span-6 flex items-center">
          <div>
            <mat-progress-bar mode="indeterminate" *ngIf="imageLoading"></mat-progress-bar>
            <div class="bg-white">
              <dt class="text-base text-left font-semibold leading-7 text-gray-900">Product Image</dt>
              <dd class="mt-1 text-sm text-left leading-6 text-gray-700">
                <img [src]="imageUrl | async" *ngIf="product && !!product.imageUrl"
                  class="img-fluid img-thumbnail min-w-50 mr-auto my-3">
                <button mat-stroked-button color="primary" class="btn-block mr-auto" (click)="openFileInput()">Change
                  Image</button>
                <input type="file" class="file" #fileInput (change)='handleFileInput($event.target.files)'>
              </dd>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
