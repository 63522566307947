import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "src/app/config/config";
import { ApiResponse } from "src/app/typings/response";

interface IDataResponse {
  data: any;
}

@Injectable({
  providedIn: "root",
})
export class DataService {
  baseUrlV2: string;

  constructor(private http: HttpClient) {
    this.baseUrlV2 = Config.api.getBaseUrlV2();
  }

  getTotalAudits() {
    return new Promise((resolve, reject) => {
      this.http
        .get<IDataResponse>(this.baseUrlV2 + "/data/totalAudits")
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  getTotalContracts() {
    return new Promise((resolve, reject) => {
      this.http
        .get<IDataResponse>(this.baseUrlV2 + "/data/totalContracts")
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  getOpenAudits(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get<IDataResponse>(this.baseUrlV2 + "/data/openAudits")
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  getCreatedAudits(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get<IDataResponse>(this.baseUrlV2 + "/data/createdAudits")
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  getTotalCustomers(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.baseUrlV2 + "/data/totalCustomers")
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }

  getContractStatus(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.baseUrlV2 + "/data/contractStatus")
        .subscribe(
          (r) => resolve(r.data),
          (e) => reject(e)
        );
    });
  }
}
