import { Component, OnInit } from "@angular/core";
import { FormControl, UntypedFormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { SearchService } from "../../services/search/search.service";
import { Router } from "@angular/router";

interface ISearchResults {
  customers: {
    hits: { id: string, name: string }[];
  };
  contracts: {
    hits: { id: string, title: string }[];
  };
}

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  public searchField = new UntypedFormControl();
  public isOpen = false;
  public searchResults: ISearchResults = {
    customers: { hits: [] },
    contracts: { hits: [] },
  };

  constructor(private searchService: SearchService, private router: Router) { }

  ngOnInit(): void {
    this.searchField.valueChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe((res) => {
        if (res && res.length > 0) {
          this.isOpen = true;
          this.searchService
            .searchContracts(res)
            .then((results: ISearchResults) => {
              this.searchResults = results;
            })
            .catch((err) => console.error(err));
        } else {
          this.isOpen = false;
        }
      });
  }

  onSearchInput() {
    if (this.searchField.value.length > 0) {
      this.isOpen = true;
    } else {
      this.isOpen = false;
    }
  }

  onFocusInput() {
    if (this.searchField.value.length > 0) {
      this.isOpen = true;
    } else {
      this.isOpen = false;
    }

  }

  goToCustomer(id: string) {
    this.router.navigate(["/customers/customer", id]);
    this.isOpen = false;
  }

  goToContract(id: string) {
    this.router.navigate(["/contract", id]);
    this.isOpen = false;
  }
}
