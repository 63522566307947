<div class="bg-white sm:flex-nowrap sm:px-6 lg:px-8">
  <!-- Breadcrumbs -->
  <nav class="flex pb-8" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-4">
      <li>
        <div>
          <a routerLink="/" class="navIcon text-gray-400 hover:text-gray-500">
            <svg class="h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Home</span>
          </a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a class="navIcon-last ml-4 text-sm font-medium text-gray-500" aria-current="page">Settings</a>
        </div>
      </li>
    </ol>
  </nav>

  <div class="grid grid-cols-12 mb-8">

    <!-- Safer Track Parameters -->
    <div class="col-span-12 mt-6">
      <dl>
        <div class="bg-white py-2 sm:grid sm:grid-cols-4 min-h-10">
          <dt class="text-base text-left font-semibold leading-7 text-gray-900">Safer Track Parameters</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <button (click)="openEditParametersDialog()" class="button-small hover:bg-gray-200 rounded-full"><ng-icon
                name="heroPencil" size="18" class="text-gray-500"></ng-icon></button>
          </dd>
        </div>
      </dl>
    </div>

    <div class="col-span-12">
      <dl>
        <!-- Maximum Weight In Child Room -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-4 sm:gap-2">
          <dt class="text-sm text-left font-medium leading-6 text-gray-800">Maximum Permissable Weight for Track in
            Child Room</dt>
          <dd class="mt-1 text-sm text-left leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p>{{ maxChildWeight }}kg</p>
          </dd>
        </div>
      </dl>
    </div>
  </div>

  <hr>

  <div class="flex justify-between mt-6">
    <div class="flex flex-wrap items-center gap-6 py-3 sm:flex-nowrap">
      <h1 class="font-semibold text-xl leading-7 text-gray-900">Track Usages</h1>
    </div>

    <div class="p-1 flex justify-between py-2 sm:flex-nowrap">
      <button (click)="openAddUsageDialog()"
        class="ml-auto flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        <ng-icon name="heroPlus" size="18" class="text-white"></ng-icon>
        New Track Usage
      </button>
    </div>
  </div>

  <div class="p-3">
    <!-- Track Usages will be listed here. -->
    <ul>
      <li *ngFor="let usage of trackUsages" class="flex py-1">

        <div class="mr-5">
          <ng-icon name="heroChatBubbleBottomCenterText" size="18" class="text-gray-500"></ng-icon>
        </div>

        <div>
          <span class="text-sm font-medium text-gray-700 leading-normal">{{ usage.value }}</span>
        </div>

        <span class="self-end ml-auto">
          <button (click)="deleteUsage(usage._id)"
            class="hover:bg-gray-200 rounded-full p-2 h-8 w-8 flex items-center justify-center">
            <ng-icon name="heroTrash" size="18" class="text-gray-500"></ng-icon>
          </button>
        </span>
      </li>
    </ul>
  </div>

</div>


<!-- <mat-grid-list cols="2">
  <mat-grid-tile>
    <mat-card appearance="outlined" class="bg-white w-100 h-100">
      <mat-toolbar color="primary">
        <h5>Safer Track Parameters</h5>
      </mat-toolbar> -->
<!-- <div class="p-3">
        <span class="mat-h6 pb-2">Maximum Permissable Weight for Track in Child Room</span>
        <mat-form-field appearance="outline" class="w-100 pt-3">
          <mat-label>Weight Limit (kg)</mat-label>
          <input matInput [formControl]='maxChildWeight'>
        </mat-form-field> -->
<!-- <button mat-flat-button color="primary" class="align-middle ml-3 align-content-center"
          *ngIf="maxChildWeight.touched || maxChildWeight.dirty" (click)="saveMaxChildWeight()">Update</button> -->
<!-- </div> -->
<!-- </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <app-track-usages-manager class="w-100 h-100"></app-track-usages-manager>
  </mat-grid-tile>
</mat-grid-list> -->