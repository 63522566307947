import { Router } from "@angular/router";
import { Customer } from "./../../models/customer.model";
import { CustomersService } from "./../../services/customers/customers.service";
import { PageParams } from "./../../typings/PageParams";
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { UntypedFormBuilder, Validators, FormControl } from "@angular/forms";
import { MatSort } from "@angular/material/sort";
import { Subscription } from "rxjs";
import { NotificationsService } from "src/app/services/notifications/notifications.service";
import { ContactsService } from "src/app/services/contacts/contacts.service";
import { Contact } from "src/app/models/contact.model";
import { MatPaginator } from "@angular/material/paginator";
import { ConfirmCustomerDeleteDialogComponent } from "./dialogs/delete-customer-confirmation/delete-customer-confirmation.component";
import { BaseTableComponent } from "../../directives/base-table.directive";

@Component({
  selector: "app-customers",
  templateUrl: "./customers.component.html",
  styleUrls: ["./customers.component.scss"],
})
export class CustomersComponent
  extends BaseTableComponent<Contact>
  implements OnInit, AfterViewInit {
  public customers: Customer[];
  public loading = false;
  public err: { message: string, status: number } = null

  public displayedColumns = [
    "organisationName",
    "contact",
    "address",
    "contracts",
    "view",
  ];

  /**
   * Subscriptions  of customers component
   */
  public subs = new Subscription();

  constructor(
    private customersService: CustomersService,
    public dialog: MatDialog
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator.length = this.paginationLength;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loadData();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  loadData() {
    this.loading = true;
    const params = this.getRequestParams();
    const filters = this.buildFilterQuery([
      "organisationName",
      "address",
      "contact",
    ]);

    this.customersService
      .getAllCustomers(params, filters, {})
      .then((customersData: any) => {
        const customers = customersData.data;
        const customersCount = customersData.total;
        this.paginationLength = customersCount;
        this.customers = customers;
        this.dataSource.data = customers;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        this.err = {
          message: err.error.message,
          status: err.status
        }
      });
  }

  newCustomer() {
    const dialogRef = this.dialog.open(NewCustomerDialogComponent, {
      width: "500px",
      minHeight: "500px",
      maxHeight: "700px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.loadData();
      }
    });
  }

  deleteCustomer(id: string): void {
    const dialogRef = this.dialog.open(ConfirmCustomerDeleteDialogComponent, {
      data: { id },
    });

    const d$ = dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.loadData();
      }
    });

    this.subs.add(d$);
  }
}
/**
 * New Customer Dialog.
 */

@Component({
  selector: "app-new-customer-dialog-component",
  templateUrl: "dialogs/new-customer-dialog.html",
})
export class NewCustomerDialogComponent implements OnInit {
  public loading = false;

  public err: number = null;

  public contacts: Contact[];

  public form = this.fb.group({
    organisationName: ["", Validators.required],
    address_line_1: ["", Validators.required],
    address_line_2: [""],
    city: ["", Validators.required],
    postcode: ["", Validators.required],
    enforceAssetIDOnTracks: ["", Validators.required],
  });

  page = 1;
  pageSize = 10;

  constructor(
    public dialogRef: MatDialogRef<NewCustomerDialogComponent>,
    public fb: UntypedFormBuilder,
    private customersService: CustomersService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ns: NotificationsService,
    private contactsService: ContactsService
  ) { }

  ngOnInit() {
    const params = this.getRequestParams(this.page, this.pageSize);
    const filters = { value: "", fields: [] };
    const sort = {};
    this.contactsService
      .getAllContacts(params, filters, sort)
      .then((contacts: Contact[]) => {
        this.contacts = contacts;
      });
  }

  getRequestParams(page, pageSize) {
    let params = {};

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  }

  submit() {
    this.form
      .get("enforceAssetIDOnTracks")
      .setValue(
        this.form.get("enforceAssetIDOnTracks").value === true ? true : false
      );

    if (this.form.invalid) {
      this.ns.error('All forms have to be filled in', 5000);
      return;
    }

    this.loading = true;
    this.customersService
      .createCustomer(this.form)
      .then((result) => {
        this.loading = false;
        this.dialogRef.close(true);
        this.ns.success("Created new customer.", 5000);
      })
      .catch((err) => {
        this.loading = false;
        this.err = err.code;
        this.ns.error(err.error.message, 5000);
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
