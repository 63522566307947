import { Comment } from "./../../models/comment.model";
import { NotificationsService } from "./../../services/notifications/notifications.service";
import { UntypedFormControl, Validators, UntypedFormBuilder } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { CommentsService } from "./../../services/comments/comments.service";
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { inject } from "@angular/core/testing";
import { Inject } from "@angular/core";
import anime from "animejs";

@Component({
  selector: "app-comments",
  templateUrl: "./comments.component.html",
  styleUrls: ["./comments.component.scss"],
})
export class CommentsComponent implements OnInit, OnDestroy {
  comments: Comment[];
  recommendations: Comment[];
  wac: Comment[];

  public subs = new Subscription();

  constructor(
    private commentsService: CommentsService,
    private dialog: MatDialog,
    private ns: NotificationsService
  ) { }

  ngOnInit() {
    this.getComments();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getComments() {
    this.commentsService.getComments().then((comments: Comment[]) => {
      this.wac = comments.filter((x) => x.type == "wac");
      this.recommendations = comments.filter((x) => x.type == "rec");
      this.comments = comments.filter((x) => x.type == "com");
    });
  }

  addNewComment(type: string) {
    const dialogRef = this.dialog.open(NewCommentDialogComponent, {
      width: "500px",
      minHeight: "500px",
      maxHeight: "700px",
      data: {
        type,
      },
    });

    const dialog$ = dialogRef.afterClosed().subscribe((val) => {
      this.getComments();
    });

    this.subs.add(dialog$);
  }

  deleteComment(commentId: string) {
    this.commentsService
      .deleteComment(commentId)
      .then(() => {
        this.comments = this.comments.filter(comment => comment._id !== commentId)
        this.ns.success('Comment deleted', 5000);
      })
      .catch((err) => {
        this.ns.error(err.error.display, 2000);
      });
    this.getComments();
  }
}

@Component({
  selector: "app-new-comment-dialog-component",
  templateUrl: "dialogs/new-comment-dialog.html"
})
export class NewCommentDialogComponent implements OnInit {
  /**
   * Loading  of new comment dialog component
   */
  @ViewChild('noteTextarea') noteTextarea: ElementRef;
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (document.activeElement !== this.noteTextarea.nativeElement) {
      event.preventDefault();
      this.submitComment();
    }
  }
  public loading = false;

  // public comment = new UntypedFormControl("", Validators.required);

  public form = this.fb.group({
    comment: ["", Validators.required]
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<NewCommentDialogComponent>,
    private commentsService: CommentsService,
    private ns: NotificationsService,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit() { }

  submitComment() {
    if (this.form.valid) {
      this.loading = true;
      this.commentsService
        .addComment(this.form.get('comment').value, this.data.type)
        .then(() => {
          this.loading = false;
          this.ns.success("Successfully added comment.", 2000);
          this.form.reset();
          this.dialogRef.close(true);
        })
        .catch((err) => {
          this.loading = false;
          this.ns.error(err.error.display, 2000);
        });
    } else {
      this.ns.error('Note cannot be empty.', 5000);
      return;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
