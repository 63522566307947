import { Component, ElementRef, HostListener, ViewChild } from "@angular/core";
import { FormControl, Validators, UntypedFormBuilder } from "@angular/forms";
import { ReportNotesService } from "../../../../services/report-notes/report-notes.service";
import { MatDialogRef } from "@angular/material/dialog";
import { NotificationsService } from "../../../../services/notifications/notifications.service";

@Component({
  selector: "app-create-report-note.modal",
  templateUrl: "./create-report-note.modal.component.html",
  styleUrls: ["./create-report-note.modal.component.scss"],
})
export class CreateReportNoteModalComponent {
  // public noteTitleInput: FormControl = new FormControl("", [
  //   Validators.required,
  // ]);
  // public noteTextInput: FormControl = new FormControl("", [
  //   Validators.required,
  // ]);
  @ViewChild('noteTextarea') noteTextarea: ElementRef;
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (document.activeElement !== this.noteTextarea.nativeElement) {
      event.preventDefault();
      this.submitNote();
    }
  }
  public form = this.fb.group({
    noteTitleInput: ['', Validators.required],
    noteTextInput: ['', Validators.required],
  })
  constructor(
    private dialogRef: MatDialogRef<CreateReportNoteModalComponent>,
    private reportNoteService: ReportNotesService,
    private ns: NotificationsService,
    private fb: UntypedFormBuilder,
  ) { }

  async submitNote() {
    if (this.form.invalid) {
      this.ns.error('All forms have to be filled in.', 5000)
      return;
    }


    try {
      const response = await this.reportNoteService.createReportNote({
        title: this.form.get('noteTitleInput').value,
        note: this.form.get('noteTextInput').value,
      });
      this.form.reset();
      this.ns.success("Report note created successfully.", 4000);
      return this.dialogRef.close(response);
    } catch (e) {
      this.ns.error("Something went wrong, please try again later.", 4000);
      return;
    }
  }

  onNoClick(): void {
    this.dialogRef.close()
  }
}
