<div class="bg-white overflow-y-auto">

  <!-- Breadcrumbs -->
  <nav class="flex pb-8 sm:px-6 lg:px-8" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-4">
      <li>
        <div>
          <a routerLink="/" class="navIcon text-gray-400 hover:text-gray-500">
            <svg class="h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Home</span>
          </a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a routerLink="/contacts"
            class="navIcon-last ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Contacts</a>
        </div>
      </li>
    </ol>
  </nav>


  <div class="flex flex-wrap items-center gap-6 px-4 py-3 sm:flex-nowrap sm:px-6 lg:px-8">
    <h1 class="font-semibold text-xl leading-7 text-gray-900">Contacts</h1>
  </div>

  <!-- Contacts table -->
  <div>
    <div>
      <div class="p-1 flex justify-between px-4 py-2 sm:flex-nowrap sm:px-6 lg:px-8">
        <div class="relative max-w-3xl">
          <div class="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
          <input type="search" id="default-search" [formControl]="filterControl"
            class="block w-full p-2 ps-8 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-cg-primary focus:border-sky-950 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Filter..." required />
        </div>
        <button (click)="openCreateContact()"
          class="ml-auto flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          <ng-icon name='heroPlus' size='18' class='text-white'></ng-icon>
          New contact
        </button>
      </div>

      <table cdk-table [dataSource]="dataSource" cdkSort (cdkSortChange)="sortData($event)"
        class="min-w-full divide-y divide-gray-300 bg-white mt-4">

        <!-- Name Column -->
        <ng-container cdkColumnDef="name">
          <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
            class="py-3.5 text-left text-sm font-semibold text-gray-900 gpl-4 sm:pl-6 lg:px-8 ">Name</th>
          <td cdk-cell *cdkCellDef="let contact" class="align-top py-3 pl-4 sm:pl-6 lg:px-8 border-b border-gray-200">
            <div>
              <a routerLink="/contacts/contact/{{contact._id}}"
                class="text-sm font-medium text-gray-700 leading-normal">{{
                contact.name }}</a>
              <br>

              <div *ngIf="contact.accountActive"
                class="whitespace-nowrap text-xs text-emerald-400 flex items-center pt-0.5 leading-normal">

                <ng-icon name="heroCheckCircle" class="mr-1" size="15"></ng-icon>
                <span>User created at: {{contact.loginCreatedAt | date:'dd/MM/yyyy'}}</span>
              </div>

              <div *ngIf="!contact.accountActive"
                class="whitespace-nowrap text-xs text-gray-500 flex items-center pt-0.5 leading-normal">

                <ng-icon name="heroXCircle" class="mr-1" size="15"></ng-icon>
                <span>No customer account</span>
              </div>
            </div>

          </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container cdkColumnDef="email">
          <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
            class="py-3.5 -pl-2 text-left text-sm font-semibold text-gray-900"> Email </th>
          <td cdk-cell *cdkCellDef="let contact"
            class="align-center py-3 -pl-2 whitespace-nowrap text-sm text-gray-700 font-medium leading-normal border-b border-gray-200">
            <span>{{ contact.email }}</span>
          </td>
        </ng-container>

        <!-- Phone Column -->
        <ng-container cdkColumnDef="phone">
          <th cdk-header-cell *cdkHeaderCellDef class="py-3.5 -pl-2 text-left text-sm font-semibold text-gray-900">
            Phone
          </th>
          <td cdk-cell *cdkCellDef="let contact"
            class="align-center py-3 -pl-2 whitespace-nowrap text-sm text-gray-700 font-medium leading-normal border-b border-gray-200">
            <span>{{ contact.phone }}</span>
          </td>
        </ng-container>

        <!-- Customers -->
        <ng-container cdkColumnDef="customers">
          <th cdk-header-cell *cdkHeaderCellDef class="py-3.5 -pl-2 text-left text-sm font-semibold text-gray-900">
            Customers </th>
          <td cdk-cell *cdkCellDef="let contact"
            class="align-center py-3 -pl-2 whitespace-nowrap text-sm text-gray-700 font-medium leading-normal border-b border-gray-200">
            {{ contact.customers.length }}
          </td>
        </ng-container>

        <!-- Last Login -->
        <ng-container cdkColumnDef="lastLogin">
          <th cdk-header-cell *cdkHeaderCellDef class="py-3.5 text-left text-sm font-semibold text-gray-900"> Last
            Login </th>
          <td cdk-cell *cdkCellDef="let contact"
            class="align-center py-3 whitespace-nowrap text-sm text-gray-700 font-medium leading-normal border-b border-gray-200">
            <div class="with-icon text-sm leading-normal">
              <div *ngIf="contact.lastLogin">
                <p>{{contact.lastLogin | date:'dd/MM/yyyy'}}</p>
              </div>
              <div *ngIf="!contact.lastLogin" class="false">Never</div>
            </div>

          </td>
        </ng-container>

        <!-- rows and columns -->
        <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
        <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [length]="paginationLength" [pageSize]="20" [pageIndex]="pageIndex"
        (page)="handlePageEvent($event)" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>