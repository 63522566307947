import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CreateReportNoteModalComponent } from "./dialogs/create-report-note.modal/create-report-note.modal.component";
import {
  IReportNote,
  ReportNotesService,
} from "../../services/report-notes/report-notes.service";
import { Subscription } from "rxjs";
import { NotificationsService } from "../../services/notifications/notifications.service";

@Component({
  selector: "app-audit-management",
  templateUrl: "./audit-management.component.html",
  styleUrls: ["./audit-management.component.scss"],
})
export class AuditManagementComponent implements OnInit, OnDestroy {
  private subs: Subscription = new Subscription();
  public reportNotes: IReportNote[] = [];

  constructor(
    private dialog: MatDialog,
    private reportNoteService: ReportNotesService,
    private ns: NotificationsService
  ) { }

  ngOnInit() {
    this.getReportNotes();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getReportNotes() {
    this.reportNoteService
      .getReportNotes()
      .then((notes: IReportNote[]) => {
        this.reportNotes = notes;
      })
      .catch((e) => { });
  }

  openNewReportNoteModal() {
    // Open modal to add new report note.
    const dialogRef = this.dialog.open(CreateReportNoteModalComponent, {
      width: "500px",
      minHeight: "500px",
      maxHeight: "700px",
    });

    const $d = dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reportNotes.push(result);
      }
    });

    this.subs.add($d);
  }

  async deleteReportNote(id: string) {
    try {
      await this.reportNoteService.deleteReportNote(id);
      this.reportNotes = this.reportNotes.filter(reportNote => reportNote._id !== id)
      this.ns.success("Report note deleted successfully.", 4000);
    } catch (error) {
      this.ns.error("Something went wrong. Please try again.", 4000);
    }
  }
}
