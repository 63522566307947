<div class="m-8" (keydown.enter)="submit()">
  <h5 class="text-lg font-semibold mb-4 text-gray-900">Create a Contract for Customer</h5>

  <form [formGroup]="newContract" class="space-y-4">
    <div class="mb-4">
      <label class="block text-sm font-medium leading-6 text-gray-900" for="organisationName">Contract Name</label>
      <input type="text" id="organisationName" formControlName="contractName" required
        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
    </div>

    <div class="mb-4">
      <div class="relative mt-2 rounded-md shadow-sm">
        <label class="block text-sm font-medium leading-6 text-gray-900">Last Inspection Date</label>
        <div class="relative mt-2 rounded-md shadow-sm">
          <input matInput [matDatepicker]="picker" formControlName="lastInspectionDate"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">

          <label class="absolute inset-y-0 right-0 flex items-center pr-3" [for]="picker" (click)="picker.open()">
            <ng-icon name="heroCalendar"></ng-icon>
          </label>
        </div>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>
  </form>

  <div class="flex justify-between mt-4">
    <button
      class="rounded bg-white px-2 py-2 text-sm font-medium text-gray-900 shadow-sm hover:ring-1 hover:required:ing-inset hover:ring-gray-300 hover:bg-gray-50 transition-all"
      [mat-dialog-close]>Cancel</button>


    <button type="button" (click)="submit()" class=" rounded bg-cg-primary px-2 py-2 text-sm font-medium text-white shadow-sm hover:bg-cg-primary
      focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
      focus-visible:outline-cg-primary">Create</button>

  </div>

</div>